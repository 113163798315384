<template>
  <div class="twm-device-query">
    <div class="guide1">
      <div class="guide-title">威联通x86/arm设备查询</div>
      <div class="guide-title">
        arm机型:<font class="title-tips">暂不支持arm32架构</font>
      </div>
      <div class="content">
        <div>TS-431P3</div>
        <div>TS-231P3</div>
        <div>TS-431K</div>
        <div>TS-231K</div>
        <div>TS-131K</div>
        <div>TS-433</div>
        <div>TS-233</div>
        <div>TS-133</div>
      </div>
      <div class="line"></div>
      <div class="guide-title">x86机型:</div>
      <div class="content">
        <div>TS-364</div>
        <div>TS-451D2</div>
        <div>TS-262</div>
        <div>TS-462</div>
        <div>TS-251D</div>
        <div>TS-451+</div>
        <div>TS-251+</div>
        <div>HS-264</div>
        <div>HS-453DX</div>
      </div>
    </div>
    <Foot></Foot>
  </div>
</template>

<script>
import Foot from "@/components/Foot.vue";

export default {
  name: "WltDeviceQuery",
  components: {
    Foot,
  },
};
</script>

<style scoped lang="less">
.twm-device-query {
  .guide1 {
    margin: 0 auto;
    padding: 20px 0;
    width: 50%;
    min-height: 800px;
    .guide-title {
      font-weight: 500;
      color: #222429;
      font-size: 24px;
      margin-bottom: 30px;
      .title-tips {
        font-size: 16px;
        line-height: 30px;
        margin-left: 20px;
      }
    }
    .content {
      font-size: 16px;
      div {
        line-height: 30px;
      }
    }
    .line {
      margin: 20px 0;
      width: 100%;
      height: 1px;
      background-color: #ccced4;
    }
  }
}
</style>