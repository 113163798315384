import Vue from 'vue'
import Vuex from 'vuex'
import Req from '../req/index'
Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        expandedKeys: [], //需要展开的节点id数据
        currentKey: null, //当前节点id
    },

    actions: {
        getWindowsVersion: function (store, [cb]) {
            Req.downloadPage.windowsVersion(cb);
        },
        getMacVersion: function (store, [cb]) {
            Req.downloadPage.macVersion(cb);
        },
        sendUserlog: function (store, [type, msg, cb]) {
            let data = {
                client: "iepose",
                type: type,
                msg: msg,
                href: document.location.href,
                referrer: document.referrer,
                useragent: navigator.userAgent
            }
            Req.user.log(data, cb);
        },
    }

})