<template>
  <div class="support5">
    <div class="content">
      <div class="head">已支持的群晖x86和arm机型判断</div>
      <div class="question">
        <div class="question-text">可使用Ctrl+F快速搜索机型</div>
      </div>
      <div class="head2">
        arm机型:<font class="head2-tips">暂不支持arm32架构</font>
      </div>
      <table class="texts">
        <tr>
          <td>DS420j</td>
          <td>DS220j</td>
          <td>RS819</td>
        </tr>
        <tr>
          <td>DS418</td>
          <td>DS418j</td>
          <td>DS218</td>
        </tr>
        <tr>
          <td>DS218play</td>
          <td>DS118</td>
          <td>DS120j</td>
        </tr>
        <tr>
          <td>DS119j</td>
          <td></td>
          <td></td>
        </tr>
      </table>

      <div class="head2">x86机型:</div>
      <table class="texts">
        <tr>
          <td>DS712+</td>
          <td>RS2211+/RS2211RP+</td>
          <td>DS2411+</td>
        </tr>
        <tr>
          <td>DS1511+</td>
          <td>DS411+II</td>
          <td>DS411+</td>
        </tr>
        <tr>
          <td>RS810+/RS810RP+</td>
          <td>DS1010+</td>
          <td>DS710+</td>
        </tr>
        <tr>
          <td>RS3617xs</td>
          <td>RS18016xs+</td>
          <td>RC18015xs+</td>
        </tr>
        <tr>
          <td>DS3615xs</td>
          <td>RS3614xs+</td>
          <td>RS3614xs/RS3614RPxs</td>
        </tr>
        <tr>
          <td>RS10613xs+</td>
          <td>RS3413xs+</td>
          <td>RS3412xs/RS3412RPxs</td>
        </tr>
        <tr>
          <td>DS3612xs</td>
          <td>RS3411xs/RS3411RPxs</td>
          <td>DS3611xs</td>
        </tr>
        <tr>
          <td>RS2414+/RS2414RP+</td>
          <td>RS814+/RS814RP+</td>
          <td>DS2413+</td>
        </tr>
        <tr>
          <td>DS1813+</td>
          <td>DS1513+</td>
          <td>DS713+</td>
        </tr>
        <tr>
          <td>RS2212+/RS2212RP+</td>
          <td>RS812+/RS812RP+</td>
          <td>DS1812+</td>
        </tr>
        <tr>
          <td>DS1512+</td>
          <td>DS412+</td>
          <td>RS1219+</td>
        </tr>
        <tr>
          <td>RS818+/RS818RP+</td>
          <td>DS1817+</td>
          <td>DS1517+</td>
        </tr>
        <tr>
          <td>RS2416+/RS2416RP+</td>
          <td>RS815+/RS815RP+</td>
          <td>DS2415+</td>
        </tr>
        <tr>
          <td>DS1815+</td>
          <td>DS1515+</td>
          <td>DS415+</td>
        </tr>
        <tr>
          <td>DS916+</td>
          <td>DS716+II</td>
          <td>DS716+</td>
        </tr>
        <tr>
          <td>DS416play</td>
          <td>DS216+II</td>
          <td>DS216+</td>
        </tr>
        <tr>
          <td>FS3400</td>
          <td>FS2017</td>
          <td>RS3618xs</td>
        </tr>
        <tr>
          <td>RS18017xs+</td>
          <td>RS4017xs+</td>
          <td>RS3617xs+</td>
        </tr>
        <tr>
          <td>RS3617RPxs</td>
          <td>DS3617xs</td>
          <td>FS3017</td>
        </tr>
        <tr>
          <td>RS820+/RS820RP+</td>
          <td>DS2419+</td>
          <td>DS1819+</td>
        </tr>
        <tr>
          <td>DVA3219</td>
          <td>RS2818RP+</td>
          <td>RS2418+/RS2418RP+</td>
        </tr>
        <tr>
          <td>DS1618+</td>
          <td>DS620slim</td>
          <td>DS1019+</td>
        </tr>
        <tr>
          <td>DS918+</td>
          <td>DS718+</td>
          <td>DS418play</td>
        </tr>
        <tr>
          <td>DS218+</td>
          <td>FS3600</td>
          <td>FS1018</td>
        </tr>
        <tr>
          <td>SA3600</td>
          <td>SA3400</td>
          <td>RS1619xs+</td>
        </tr>
        <tr>
          <td>DS3018xs</td>
          <td>DS220+</td>
          <td>DS920+</td>
        </tr>
        <tr>
          <td>DS423+</td>
        </tr>
      </table>
    </div>
    <Foot></Foot>
  </div>
</template>

<script>
import Foot from "@/components/Foot.vue";

export default {
  name: "Support6",
  components: {
    Foot,
  },
  data: function () {
    return {
      support_f1: require("../assets/support_f1.png"),
      support_f2: require("../assets/support_f2.png"),
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.bg {
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.support5 {
  height: 100%;
  overflow-y: scroll;
  background-color: #ffffff;
  color: #666666;
  font-size: 18px;

  .content {
    position: relative;
    padding-top: 36px;
    padding-bottom: 100px;
    margin: 0 auto;
    min-width: 210px;
    font-size: 16px;
    max-width: 790px;
    min-width: 470px;

    .head {
      margin-top: 30px;
      font-size: 30px;
      color: #1a1a1a;
      font-weight: 500;
    }
    .head2 {
      margin-bottom: 10px;
      margin-top: 30px;
      font-size: 24px;
      font-weight: 500;
      color: #1a1a1a;
      line-height: 30px;
      .head2-tips {
        font-size: 16px;
        line-height: 30px;
        margin-left: 20px;
      }
    }
    .text-head {
      font-size: 18px;
      color: #1a1a1a;
      font-weight: 700;
    }

    .texts {
      line-height: 32px;
      color: #1a1a1a;
    }

    .m1 {
      margin-top: 10px;
    }
    .m2 {
      margin-top: 15px;
    }

    .icon {
      margin-top: 20px;
      width: 100%;
    }

    .question {
      margin-top: 20px;
      padding: 16px 16px 16px 46px;
      background-color: #f5f5f5;
      border-radius: 4px;

      .question-text {
        position: relative;
      }
      .question-text::before {
        position: absolute;
        top: 50%;
        left: -30px;
        transform: translate(0, -50%);
        content: "";
        width: 24px;
        height: 24px;
        .bg();
        background-image: url("../assets/support14.svg");
      }
    }

    table {
      width: 600px;

      td {
        width: 200px;
      }
    }
  }
}
.support5::-webkit-scrollbar {
  display: none;
}
</style>
