<template>
  <div class="help-home">
    <div class="top">
      <div class="top-title">帮助中心</div>
    </div>
    <div class="content">
      <div class="content1">
        <div class="title">
          <div class="title-icon"></div>
          常见问题
        </div>
        <div class="items">
          <div class="item" @click="goClick(501)">
            <div class="item-icon"></div>
            手机号已被绑定
          </div>
          <div class="item" @click="goClick(505)">
            <div class="item-icon"></div>
            无法访问服务/访问白屏
          </div>
          <div class="item" @click="goClick(503)">
            <div class="item-icon"></div>
            如何获取绑定码
          </div>
          <div class="item" @click="goClick(507)">
            <div class="item-icon"></div>
            积分兑换问题
          </div>
        </div>
        <div class="items items2">
          <div class="item" @click="goClick(502)">
            <div class="item-icon"></div>
            传输速率如何
          </div>
          <div class="item" @click="goClick(506)">
            <div class="item-icon"></div>
            如何申请发票
          </div>
          <div class="item" @click="goClick(504)">
            <div class="item-icon"></div>
            支持的设备厂商/型号/平台
          </div>
        </div>
      </div>
      <div class="content2">
        <div class="items">
          <div class="title">
            <div class="title-icon"></div>
            安装与绑定
          </div>
          <div class="item" @click="goClick(101)">
            <div class="item-icon"></div>
            安装设备端（获取绑定码）
          </div>
          <div class="item" @click="goClick(102)">
            <div class="item-icon"></div>
            安装客户端
          </div>
          <div class="item" @click="goClick(103)">
            <div class="item-icon"></div>
            绑定设备端
          </div>
        </div>

        <div class="items">
          <div class="title">
            <div class="title-icon"></div>
            访问远程服务
          </div>
          <div class="item-parent">
            <div class="item item1" @click="goClick(201)">
              <div class="item-icon"></div>
              NAS桌面
            </div>
            <div class="item item2" @click="goClick(205)">
              <div class="item-icon"></div>
              NAS远程下载
            </div>
          </div>
          <div class="item-parent">
            <div class="item item1" @click="goClick(203)">
              <div class="item-icon"></div>
              远程桌面
            </div>
            <div class="item item2" @click="goClick(206)">
              <div class="item-icon"></div>
              Video Station
            </div>
          </div>
          <div class="item-parent">
            <div class="item item1" @click="goClick(202)">
              <div class="item-icon"></div>
              远程路由
            </div>
            <div class="item item2" @click="goClick(208)">
              <div class="item-icon"></div>
              File Station
            </div>
          </div>
          <div class="item-parent">
            <div class="item item1" @click="goClick(204)">
              <div class="item-icon"></div>
              远程开机
            </div>
            <div class="item item2" @click="goClick(207)">
              <div class="item-icon"></div>
              Synology Photos
            </div>
          </div>
        </div>

        <div class="items">
          <div class="title">
            <div class="title-icon"></div>
            工具箱
          </div>
          <div class="item" @click="goClick(401)">
            <div class="item-icon"></div>
            相册备份
          </div>
          <div class="item" @click="goClick(402)">
            <div class="item-icon"></div>
            宽带提速
          </div>
          <div class="item" @click="goClick(403)">
            <div class="item-icon"></div>
            小宝驿站
          </div>
          <div class="item" @click="goClick(404)">
            <div class="item-icon"></div>
            远程开机
          </div>
        </div>
      </div>
    </div>

    <Foot></Foot>
  </div>
</template>

<script>
import Foot from "@/components/Foot.vue";

export default {
  name: "Help",
  components: {
    Foot,
  },
  methods: {
    goClick: function (data) {
      let _that = this;
      _that.$router.push({
        name: "Answer",
        params: {
          type: data,
        },
      });
    },
  },
};
</script>

<style scoped lang="less">
.help-home {
  .top {
    padding-top: 80px;
    height: 220px;
    width: 100%;
    background-image: url("../assets/help/icon110.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    box-sizing: border-box;
    .top-title {
      text-align: center;
      font-weight: 600;
      font-size: 32px;
      line-height: 45px;
      color: #282938;
    }
  }
  .content {
    padding-top: 86px;
    .content1 {
      margin: 0 auto;
      padding-left: 24px;
      width: 1280px;
      padding-top: 24px;
      padding-bottom: 24px;
      background-color: #fafcff;
      border: 1px solid #e6edf8;
      border-radius: 8px;
      box-sizing: border-box;
      .title {
        display: flex;
        margin-bottom: 20px;
        align-items: center;
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
        color: #1a1a1a;
        .title-icon {
          margin-right: 8px;
          margin-top: -1px;
          width: 4px;
          height: 16px;
          background-color: #233974;
          border-radius: 12px;
        }
      }
      .items {
        display: flex;
        box-sizing: border-box;
        padding-left: 16px;
        font-size: 20px;
        line-height: 28px;
        color: #666666;

        .item {
          width: 25%;
          cursor: pointer;
          .item-icon {
            margin-top: 10px;
            margin-right: 10px;
            display: inline-block;
            vertical-align: top;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background-color: #666666;
          }
        }
      }
      .items2 {
        margin-top: 12px;
      }
    }
    .content2 {
      display: flex;
      justify-content: space-between;
      margin: 0 auto;
      padding-top: 42px;
      padding-bottom: 20px;
      width: 1280px;
      box-sizing: border-box;
      .title {
        display: flex;
        margin-top: -1px;
        margin-bottom: 20px;
        align-items: center;
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
        color: #1a1a1a;
        .title-icon {
          margin-right: 8px;
          width: 4px;
          height: 16px;
          background-color: #233974;
          border-radius: 12px;
        }
      }
      .items {
        padding-top: 24px;
        padding-left: 16px;
        padding-bottom: 52px;
        background-color: #fafcff;
        border: 1px solid #e6edf8;
        border-radius: 8px;
        box-sizing: border-box;
        font-size: 20px;
        color: #666666;
        width: 400px;

        .item {
          padding-left: 24px;
          margin-bottom: 12px;
          cursor: pointer;
          .item-icon {
            margin-top: 10px;
            margin-right: 10px;
            display: inline-block;
            vertical-align: top;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background-color: #666666;
          }
        }
        .item-parent {
          display: flex;

          .item2 {
            padding-left: 48px;
          }
        }
      }
    }
  }
}
</style>