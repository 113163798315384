<template>
  <div class="help">
    <div class="title" v-if="false">快速找到问题解决办法</div>
    <div class="keyword-search" v-if="false">
      <el-autocomplete
        v-model="state"
        :fetch-suggestions="querySearchAsync"
        placeholder="请输入内容"
        @select="handleSelect"
      ></el-autocomplete>
    </div>

    <div class="content-wrap">
      <div class="left">
        <div class="l-title">帮助中心</div>
        <div class="l-list">
          <CascaderItem
            :tree="list"
            :treeIndex="1"
            @nodeClick="nClick"
          ></CascaderItem>
        </div>
      </div>
      <div class="right">
        <div class="position-wrap">
          <div class="position-text1">
            {{ positionText.name ? positionText.name : "" }}
          </div>
          <div
            class="position-text2-wrap"
            v-if="positionText && positionText.children"
          >
            <div
              class="position-text2"
              v-for="(item, index) in positionText.children"
              :key="index"
              @click="nClick(item)"
            >
              {{ item.name }}
            </div>
          </div>
        </div>

        <InstallControled
          v-if="
            positionText &&
            positionText.parentid &&
            positionText.parentid == 101
          "
          :currentid="positionText.id"
        ></InstallControled>
        <InstallControl
          v-if="
            positionText &&
            positionText.parentid &&
            positionText.parentid == 102
          "
          :currentid="positionText.id"
        ></InstallControl>
        <BindControled
          v-if="positionText && positionText.id && positionText.id == 103"
        ></BindControled>
        <NasDesk
          @vipGuideClick="vipGuideClick"
          v-if="positionText && positionText.id && positionText.id == 201"
          :currentid="positionText.id"
        ></NasDesk>
        <RemoteDownload
          v-if="positionText && positionText.id && positionText.id == 205"
          :currentid="positionText.id"
        ></RemoteDownload>
        <RemoteVideo
          v-if="positionText && positionText.id && positionText.id == 206"
          :currentid="positionText.id"
        ></RemoteVideo>
        <RemoteFile
          v-if="positionText && positionText.id && positionText.id == 207"
          :currentid="positionText.id"
        ></RemoteFile>
        <RemotePhoto
          v-if="positionText && positionText.id && positionText.id == 208"
          :currentid="positionText.id"
        ></RemotePhoto>
        <JellyService
          v-if="positionText && positionText.id && positionText.id == 209"
          :currentid="positionText.id"
        ></JellyService>
        <EmbyService
          v-if="positionText && positionText.id && positionText.id == 210"
          :currentid="positionText.id"
        ></EmbyService>
        <RemoteRouter
          @vipGuideClick="vipGuideClick"
          v-if="positionText && positionText.id && positionText.id == 202"
        ></RemoteRouter>
        <RemoteDesk
          v-if="
            positionText &&
            positionText.parentid &&
            positionText.parentid == 203
          "
          :currentid="positionText.id"
        ></RemoteDesk>
        <RemoteOpen
          v-if="positionText && positionText.id && positionText.id == 204"
        ></RemoteOpen>
        <VipService
          v-if="positionText && positionText.id && positionText.id == 3"
        ></VipService>
        <Tools
          v-if="
            positionText && positionText.parentid && positionText.parentid == 4
          "
          :currentid="positionText.id"
        ></Tools>
        <Questions
          @remoteOpenClick="remoteOpenClick"
          v-if="
            positionText && positionText.parentid && positionText.parentid == 5
          "
          :currentid="positionText.id"
        ></Questions>
      </div>
    </div>

    <Foot></Foot>
  </div>
</template>

<script>
import Foot from "@/components/Foot.vue";
import CascaderItem from "@/components/CascaderItem.vue";
import InstallControled from "@/components/help/InstallControled.vue";
import InstallControl from "@/components/help/InstallControl.vue";
import BindControled from "@/components/help/BindControled.vue";
import NasDesk from "@/components/help/NasDesk.vue";
import RemoteRouter from "@/components/help/RemoteRouter.vue";
import RemoteDesk from "@/components/help/RemoteDesk.vue";
import RemoteOpen from "@/components/help/RemoteOpen.vue";
import RemoteDownload from "@/components/help/RemoteDownload.vue";
import RemoteVideo from "@/components/help/RemoteVideo.vue";
import RemoteFile from "@/components/help/RemoteFile.vue";
import RemotePhoto from "@/components/help/RemotePhoto.vue";
import EmbyService from "@/components/help/EmbyService.vue";
import JellyService from "@/components/help/JellyService.vue";
import VipService from "@/components/help/VipService.vue";
import Tools from "@/components/help/Tools.vue";
import Questions from "@/components/help/Questions.vue";

export default {
  name: "Answer",
  components: {
    Foot,
    CascaderItem,
    InstallControled,
    InstallControl,
    BindControled,
    NasDesk,
    RemoteRouter,
    RemoteDesk,
    RemoteOpen,
    VipService,
    Tools,
    Questions,
    RemoteDownload,
    RemoteVideo,
    RemoteFile,
    RemotePhoto,
    JellyService,
    EmbyService,
  },
  data() {
    return {
      restaurants: [],
      state: "",
      timeout: null,

      //菜单结构
      list: [
        {
          id: 5,
          name: "常见问题",
          children: [
            {
              id: 501,
              parentid: 5,
              name: "手机号已被绑定",
            },
            {
              id: 502,
              parentid: 5,
              name: "传输速率如何",
            },
            {
              id: 503,
              parentid: 5,
              name: "何获取绑定码",
            },
            {
              id: 504,
              parentid: 5,
              name: "支持的设备厂商/型号/平台",
            },
            {
              id: 505,
              parentid: 5,
              name: "无法访问服务/访问白屏",
            },
            {
              id: 506,
              parentid: 5,
              name: "如何申请发票",
            },
            {
              id: 507,
              parentid: 5,
              name: "积分兑换问题",
            },
            {
              id: 508,
              parentid: 5,
              name: "一键远程的功能没有了？",
            },
            {
              id: 509,
              parentid: 5,
              name: "怎么添加内网穿透服务？",
            },
            {
              id: 510,
              parentid: 5,
              name: "内网穿透每次访问都需要验证吗？",
            },
            {
              id: 511,
              parentid: 5,
              name: "怎么使用相册备份？",
            },
            {
              id: 512,
              parentid: 5,
              name: "点对点异地组网怎么使用呢？",
            },
            {
              id: 513,
              parentid: 5,
              name: "全网映射组网怎么使用呢？",
            },
            {
              id: 514,
              parentid: 5,
              name: "全网映射怎么添加网段呢？",
            },
            {
              id: 515,
              parentid: 5,
              name: "节点小宝安全吗？",
            },
            {
              id: 516,
              parentid: 5,
              name: "群晖怎么安装节点小宝？",
            },
            {
              id: 517,
              parentid: 5,
              name: "如何改变相册备份的存储位置？",
            },
            {
              id: 518,
              parentid: 5,
              name: "节点小宝PC端提示有病毒？",
            },
            {
              id: 519,
              parentid: 5,
              name: "手机端如何绑定设备？",
            },
            {
              id: 520,
              parentid: 5,
              name: "节点小宝设备端有哪些服务？",
            },
            {
              id: 521,
              parentid: 5,
              name: "可以远程看视频吗？",
            },
            {
              id: 522,
              parentid: 5,
              name: "如何挂载共享驱动盘？",
            },
            {
              id: 523,
              parentid: 5,
              name: "如何远程使用BT远程下载工具？",
            },
            {
              id: 524,
              parentid: 5,
              name: "节点小宝的VIP可以退费吗？",
            },
            {
              id: 525,
              parentid: 5,
              name: "节点小宝支付后如何开发票？",
            },
            {
              id: 526,
              parentid: 5,
              name: "节点小宝支持多人分享吗？",
            },
            {
              id: 527,
              parentid: 5,
              name: "设备绑定码不显示？",
            },
          ],
        },
        {
          id: 1,
          name: "安装与绑定",
          children: [
            {
              id: 101,
              parentid: 1,
              name: "安装设备端",
              children: [
                {
                  id: 10101,
                  parentid: 101,
                  name: "安装设备端-群晖-Synology",
                },
                {
                  id: 10102,
                  parentid: 101,
                  name: "安装设备端-铁威马-TerraMaster",
                },
                {
                  id: 10103,
                  parentid: 101,
                  name: "安装设备端-威联通-QNAP",
                },
                {
                  id: 10104,
                  parentid: 101,
                  name: "安装设备端-联想-LENOVO",
                },
                {
                  id: 10105,
                  parentid: 101,
                  name: "安装设备端-海康-HIKVISION",
                },
                {
                  id: 10106,
                  parentid: 101,
                  name: "安装设备端-Windows",
                },
                {
                  id: 10107,
                  parentid: 101,
                  name: "安装设备端-Mac",
                },
              ],
            },
            {
              id: 102,
              parentid: 1,
              name: "安装客户端",
              children: [
                {
                  id: 10201,
                  parentid: 102,
                  name: "客户端-Web",
                },
                {
                  id: 10202,
                  parentid: 102,
                  name: "客户端-Windows",
                },
                {
                  id: 10203,
                  parentid: 102,
                  name: "客户端-Mac",
                },
                {
                  id: 10204,
                  parentid: 102,
                  name: "客户端-Android",
                },
                {
                  id: 10205,
                  parentid: 102,
                  name: "客户端-iOS",
                },
                {
                  id: 10206,
                  parentid: 102,
                  name: "客户端-小程序",
                },
              ],
            },
            {
              id: 103,
              parentid: 1,
              name: "绑定设备端设备",
            },
          ],
        },
        {
          id: 2,
          name: "访问远程服务",
          children: [
            {
              id: 201,
              parentid: 2,
              name: "NAS桌面",
            },
            {
              id: 202,
              parentid: 2,
              name: "远程路由",
            },
            {
              id: 203,
              parentid: 2,
              name: "远程桌面",
              children: [
                {
                  id: 20301,
                  parentid: 203,
                  name: "开启Windows远程桌面服务",
                },
                {
                  id: 20302,
                  parentid: 203,
                  name: "开启Mac远程桌面",
                },
                {
                  id: 20303,
                  parentid: 203,
                  name: "Windows访问Windows",
                },
                {
                  id: 20304,
                  parentid: 203,
                  name: "Windows访问Mac",
                },
                {
                  id: 20305,
                  parentid: 203,
                  name: "Mac访问Windows",
                },
                {
                  id: 20306,
                  parentid: 203,
                  name: "Mac访问Mac",
                },
              ],
            },
            {
              id: 204,
              parentid: 2,
              name: "远程开机",
            },
            {
              id: 205,
              parentid: 2,
              name: "远程下载",
            },
            {
              id: 206,
              parentid: 2,
              name: "Video Station",
            },
            {
              id: 207,
              parentid: 2,
              name: "Flie Station",
            },
            {
              id: 208,
              parentid: 2,
              name: "Synology Photos",
            },
            {
              id: 209,
              parentid: 2,
              name: "Jellyfin",
            },
            {
              id: 210,
              parentid: 2,
              name: "Emby",
            },
          ],
        },
        {
          id: 4,
          name: "工具箱",
          children: [
            {
              id: 401,
              parentid: 4,
              name: "相册备份",
            },
            {
              id: 402,
              parentid: 4,
              name: "小宝驿站",
            },
            {
              id: 403,
              parentid: 4,
              name: "宽带提速",
            },
            {
              id: 404,
              parentid: 4,
              name: "远程开机",
            },
          ],
        },
        //        {
        //          id: 3,
        //          name: "高级功能",
        //          children: [],
        //        },
      ],
      positionText: {}, //当前点击的导航栏
    };
  },

  methods: {
    remoteOpenClick: function () {
      let _that = this;
      _that.positionText = {
        id: 204,
        parentid: 2,
        name: "远程开机",
      };
    },
    vipGuideClick: function () {
      let _that = this;
      _that.positionText = _that.list[4];
    },

    nClick: function (item) {
      let _that = this;
      // console.log("item", document, item, _that.$router.path);
      _that.positionText = item;
      _that.$nextTick(() => {
        document.title = item.name;
      });
      _that.$router.push({
        path: _that.$router.path,
        query: { type: item.id },
      });
    },

    loadAll() {
      return [
        { value: "三全鲜食（北新泾店）", address: "长宁区新渔路144号" },
        {
          value: "Hot honey 首尔炸鸡（仙霞路）",
          address: "上海市长宁区淞虹路661号",
        },
        {
          value: "新旺角茶餐厅",
          address: "上海市普陀区真北路988号创邑金沙谷6号楼113",
        },
        { value: "泷千家(天山西路店)", address: "天山西路438号" },
        {
          value: "胖仙女纸杯蛋糕（上海凌空店）",
          address: "上海市长宁区金钟路968号1幢18号楼一层商铺18-101",
        },
        { value: "贡茶", address: "上海市长宁区金钟路633号" },
        {
          value: "豪大大香鸡排超级奶爸",
          address: "上海市嘉定区曹安公路曹安路1685号",
        },
        {
          value: "茶芝兰（奶茶，手抓饼）",
          address: "上海市普陀区同普路1435号",
        },
        { value: "十二泷町", address: "上海市北翟路1444弄81号B幢-107" },
        { value: "星移浓缩咖啡", address: "上海市嘉定区新郁路817号" },
        { value: "阿姨奶茶/豪大大", address: "嘉定区曹安路1611号" },
        {
          value: "新麦甜四季甜品炸鸡",
          address: "嘉定区曹安公路2383弄55号",
        },
        {
          value: "Monica摩托主题咖啡店",
          address: "嘉定区江桥镇曹安公路2409号1F，2383弄62号1F",
        },
        {
          value: "浮生若茶（凌空soho店）",
          address: "上海长宁区金钟路968号9号楼地下一层",
        },
        {
          value: "NONO JUICE  鲜榨果汁",
          address: "上海市长宁区天山西路119号",
        },
        {
          value: "CoCo都可(北新泾店）",
          address: "上海市长宁区仙霞西路",
        },
        {
          value: "快乐柠檬（神州智慧店）",
          address: "上海市长宁区天山西路567号1层R117号店铺",
        },
        {
          value: "Merci Paul cafe",
          address: "上海市普陀区光复西路丹巴路28弄6号楼819",
        },
        {
          value: "猫山王（西郊百联店）",
          address: "上海市长宁区仙霞西路88号第一层G05-F01-1-306",
        },
        { value: "枪会山", address: "上海市普陀区棕榈路" },
        { value: "纵食", address: "元丰天山花园(东门) 双流路267号" },
        { value: "钱记", address: "上海市长宁区天山西路" },
        { value: "壹杯加", address: "上海市长宁区通协路" },
        {
          value: "唦哇嘀咖",
          address: "上海市长宁区新泾镇金钟路999号2幢（B幢）第01层第1-02A单元",
        },
        {
          value: "爱茜茜里(西郊百联)",
          address: "长宁区仙霞西路88号1305室",
        },
        {
          value: "爱茜茜里(近铁广场)",
          address:
            "上海市普陀区真北路818号近铁城市广场北区地下二楼N-B2-O2-C商铺",
        },
        {
          value: "鲜果榨汁（金沙江路和美广店）",
          address: "普陀区金沙江路2239号金沙和美广场B1-10-6",
        },
        {
          value: "开心丽果（缤谷店）",
          address: "上海市长宁区威宁路天山路341号",
        },
        {
          value: "超级鸡车（丰庄路店）",
          address: "上海市嘉定区丰庄路240号",
        },
        {
          value: "妙生活果园（北新泾店）",
          address: "长宁区新渔路144号",
        },
        { value: "香宜度麻辣香锅", address: "长宁区淞虹路148号" },
        {
          value: "凡仔汉堡（老真北路店）",
          address: "上海市普陀区老真北路160号",
        },
        {
          value: "港式小铺",
          address: "上海市长宁区金钟路968号15楼15-105室",
        },
        { value: "蜀香源麻辣香锅（剑河路店）", address: "剑河路443-1" },
        {
          value: "北京饺子馆",
          address: "长宁区北新泾街道天山西路490-1号",
        },
        {
          value: "饭典*新简餐（凌空SOHO店）",
          address: "上海市长宁区金钟路968号9号楼地下一层9-83室",
        },
        {
          value: "焦耳·川式快餐（金钟路店）",
          address: "上海市金钟路633号地下一层甲部",
        },
        { value: "动力鸡车", address: "长宁区仙霞西路299弄3号101B" },
        { value: "浏阳蒸菜", address: "天山西路430号" },
        {
          value: "四海游龙（天山西路店）",
          address: "上海市长宁区天山西路",
        },
        {
          value: "樱花食堂（凌空店）",
          address: "上海市长宁区金钟路968号15楼15-105室",
        },
        {
          value: "壹分米客家传统调制米粉(天山店)",
          address: "天山西路428号",
        },
        {
          value: "福荣祥烧腊（平溪路店）",
          address: "上海市长宁区协和路福泉路255弄57-73号",
        },
        {
          value: "速记黄焖鸡米饭",
          address: "上海市长宁区北新泾街道金钟路180号1层01号摊位",
        },
        { value: "红辣椒麻辣烫", address: "上海市长宁区天山西路492号" },
        {
          value: "(小杨生煎)西郊百联餐厅",
          address: "长宁区仙霞西路88号百联2楼",
        },
        { value: "阳阳麻辣烫", address: "天山西路389号" },
        {
          value: "南拳妈妈龙虾盖浇饭",
          address: "普陀区金沙江路1699号鑫乐惠美食广场A13",
        },
      ];
    },
    querySearchAsync(queryString, cb) {
      var restaurants = this.restaurants;
      var results = queryString
        ? restaurants.filter(this.createStateFilter(queryString))
        : restaurants;

      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        cb(results);
      }, 3000 * Math.random());
    },
    createStateFilter(queryString) {
      return (state) => {
        return (
          state.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0
        );
      };
    },
    handleSelect(item) {
      console.log(item);
    },

    getPositionText: function (type) {
      let _that = this;
      let positionText = {};
      if (type == 501) {
        positionText = { id: 501, parentid: 5, name: "手机号已被绑定" };
      }
      if (type == 502) {
        positionText = { id: 502, parentid: 5, name: "传输速率如何" };
      }
      if (type == 503) {
        positionText = { id: 503, parentid: 5, name: "何获取绑定码" };
      }
      if (type == 504) {
        positionText = {
          id: 504,
          parentid: 5,
          name: "支持的设备厂商/型号/平台",
        };
      }
      if (type == 505) {
        positionText = {
          id: 505,
          parentid: 5,
          name: "无法访问服务/访问白屏",
        };
      }
      if (type == 506) {
        positionText = { id: 506, parentid: 5, name: "如何申请发票" };
      }
      if (type == 507) {
        positionText = { id: 507, parentid: 5, name: "积分兑换问题" };
      }
      if (type == 508) {
        positionText = { id: 508, parentid: 5, name: "一键远程的功能没有了？" };
      }
      if (type == 509) {
        positionText = { id: 509, parentid: 5, name: "怎么添加内网穿透服务？" };
      }
      if (type == 510) {
        positionText = {
          id: 510,
          parentid: 5,
          name: "内网穿透每次访问都需要验证吗？",
        };
      }
      if (type == 511) {
        positionText = { id: 511, parentid: 5, name: "怎么使用相册备份？" };
      }
      if (type == 512) {
        positionText = {
          id: 512,
          parentid: 5,
          name: "点对点异地组网怎么使用呢？",
        };
      }
      if (type == 513) {
        positionText = {
          id: 513,
          parentid: 5,
          name: "全网映射组网怎么使用呢？",
        };
      }
      if (type == 514) {
        positionText = {
          id: 514,
          parentid: 5,
          name: "全网映射怎么添加网段呢？",
        };
      }
      if (type == 515) {
        positionText = { id: 515, parentid: 5, name: "节点小宝安全吗？" };
      }
      if (type == 516) {
        positionText = { id: 516, parentid: 5, name: "群晖怎么安装节点小宝？" };
      }
      if (type == 517) {
        positionText = {
          id: 517,
          parentid: 5,
          name: "如何改变相册备份的存储位置？",
        };
      }
      if (type == 518) {
        positionText = {
          id: 518,
          parentid: 5,
          name: "节点小宝PC端提示有病毒？",
        };
      }
      if (type == 519) {
        positionText = { id: 519, parentid: 5, name: "手机端如何绑定设备？" };
      }
      if (type == 520) {
        positionText = {
          id: 520,
          parentid: 5,
          name: "节点小宝设备端有哪些服务？",
        };
      }
      if (type == 521) {
        positionText = { id: 521, parentid: 5, name: "可以远程看视频吗？" };
      }
      if (type == 522) {
        positionText = { id: 522, parentid: 5, name: "如何挂载共享驱动盘？" };
      }
      if (type == 523) {
        positionText = {
          id: 523,
          parentid: 5,
          name: "如何远程使用BT远程下载工具？",
        };
      }
      if (type == 524) {
        positionText = {
          id: 524,
          parentid: 5,
          name: "节点小宝的VIP可以退费吗？",
        };
      }
      if (type == 525) {
        positionText = {
          id: 525,
          parentid: 5,
          name: "节点小宝支付后如何开发票？",
        };
      }
      if (type == 526) {
        positionText = {
          id: 526,
          parentid: 5,
          name: "节点小宝支持多人分享吗？",
        };
      }
      if (type == 527) {
        positionText = { id: 527, parentid: 5, name: "设备绑定码不显示？" };
      }

      if (type == 101) {
        positionText = {
          id: 101,
          name: "安装设备端",
          children: [
            {
              id: 10101,
              parentid: 101,
              name: "安装设备端-群晖-Synology",
            },
            {
              id: 10102,
              parentid: 101,
              name: "安装设备端-铁威马-TreeaMaster",
            },
            {
              id: 10103,
              parentid: 101,
              name: "安装设备端-威联通-QNAP",
            },
            {
              id: 10104,
              parentid: 101,
              name: "安装设备端-联想-LENOVO",
            },
            {
              id: 10105,
              parentid: 101,
              name: "安装设备端-海康-HIKVISION",
            },
            {
              id: 10106,
              parentid: 101,
              name: "安装设备端-Windows",
            },
            {
              id: 10107,
              parentid: 101,
              name: "安装设备端-Mac",
            },
          ],
        };
      }
      if (type == 10101) {
        positionText = {
          id: 10101,
          parentid: 101,
          name: "安装设备端-群晖-Synology",
        };
      }
      if (type == 10102) {
        positionText = {
          id: 10102,
          parentid: 101,
          name: "安装设备端-铁威马-TreeaMaster",
        };
      }
      if (type == 10103) {
        positionText = {
          id: 10103,
          parentid: 101,
          name: "安装设备端-威联通-QNAP",
        };
      }
      if (type == 10104) {
        positionText = {
          id: 10104,
          parentid: 101,
          name: "安装设备端-联想-LENOVO",
        };
      }
      if (type == 10105) {
        positionText = {
          id: 10105,
          parentid: 101,
          name: "安装设备端-海康-HIKVISION",
        };
      }
      if (type == 102) {
        positionText = {
          id: 102,
          parentid: 1,
          name: "安装客户端",
          children: [
            {
              id: 10201,
              parentid: 102,
              name: "客户端-Web",
            },
            {
              id: 10202,
              parentid: 102,
              name: "客户端-Windows",
            },
            {
              id: 10203,
              parentid: 102,
              name: "客户端-Mac",
            },
            {
              id: 10204,
              parentid: 102,
              name: "客户端-Android",
            },
            {
              id: 10205,
              parentid: 102,
              name: "客户端-iOS",
            },
            {
              id: 10206,
              parentid: 102,
              name: "客户端-小程序",
            },
          ],
        };
      }

      if (type == 103) {
        positionText = {
          id: 103,
          parentid: 1,
          name: "绑定设备端设备",
        };
      }
      if (type == 201) {
        positionText = {
          id: 201,
          parentid: 2,
          name: "NAS桌面",
        };
      }
      if (type == 202) {
        positionText = {
          id: 202,
          parentid: 2,
          name: "远程路由",
        };
      }
      if (type == 203) {
        positionText = {
          id: 203,
          parentid: 2,
          name: "远程桌面",
          children: [
            {
              id: 20301,
              parentid: 203,
              name: "开启Windows远程桌面服务",
            },
            {
              id: 20302,
              parentid: 203,
              name: "开启Mac远程桌面",
            },
            {
              id: 20303,
              parentid: 203,
              name: "Windows访问Windows",
            },
            {
              id: 20304,
              parentid: 203,
              name: "Windows访问Mac",
            },
            {
              id: 20305,
              parentid: 203,
              name: "Mac访问Windows",
            },
            {
              id: 20306,
              parentid: 203,
              name: "Mac访问Mac",
            },
          ],
        };
      }
      if (type == 20301) {
        positionText = {
          id: 20301,
          parentid: 203,
          name: "开启Windows远程桌面服务",
        };
      }
      if (type == 20302) {
        positionText = {
          id: 20302,
          parentid: 203,
          name: "开启Mac远程桌面",
        };
      }
      if (type == 20303) {
        positionText = {
          id: 20303,
          parentid: 203,
          name: "Windows访问Windows",
        };
      }
      if (type == 20304) {
        positionText = {
          id: 20304,
          parentid: 203,
          name: "Windows访问Mac",
        };
      }
      if (type == 20305) {
        positionText = {
          id: 20305,
          parentid: 203,
          name: "Mac访问Windows",
        };
      }
      if (type == 20306) {
        positionText = {
          id: 20306,
          parentid: 203,
          name: "Mac访问Mac",
        };
      }
      if (type == 204) {
        positionText = {
          id: 204,
          parentid: 2,
          name: "远程开机",
        };
      }
      if (type == 205) {
        positionText = {
          id: 205,
          parentid: 2,
          name: "远程下载",
        };
      }
      if (type == 206) {
        positionText = {
          id: 206,
          parentid: 2,
          name: "远程视频",
        };
      }
      if (type == 207) {
        positionText = {
          id: 207,
          parentid: 2,
          name: "远程文件",
        };
      }
      if (type == 208) {
        positionText = {
          id: 208,
          parentid: 2,
          name: "远程照片",
        };
      }
      if (type == 209) {
        positionText = {
          id: 209,
          parentid: 2,
          name: "Jellyfin",
        };
      }
      if (type == 210) {
        positionText = {
          id: 210,
          parentid: 2,
          name: "Emby",
        };
      }
      if (type == 401) {
        positionText = {
          id: 401,
          parentid: 4,
          name: "相册备份",
        };
      }
      if (type == 402) {
        positionText = {
          id: 402,
          parentid: 4,
          name: "小宝驿站",
        };
      }
      if (type == 403) {
        positionText = {
          id: 403,
          parentid: 4,
          name: "宽带提速",
        };
      }
      if (type == 404) {
        positionText = {
          id: 404,
          parentid: 4,
          name: "远程开机",
        };
      }

      _that.positionText = positionText;
    },
    getTypes: function (type) {
      let _that = this;
      let typesList = [
        {
          id: 501,
          types: [5, 501],
        },
        {
          id: 502,
          types: [5, 502],
        },
        {
          id: 503,
          types: [5, 503],
        },
        {
          id: 504,
          types: [5, 504],
        },
        {
          id: 505,
          types: [5, 505],
        },
        {
          id: 506,
          types: [5, 506],
        },
        {
          id: 507,
          types: [5, 507],
        },
        {
          id: 508,
          types: [5, 508],
        },
        {
          id: 509,
          types: [5, 509],
        },
        {
          id: 510,
          types: [5, 510],
        },
        {
          id: 511,
          types: [5, 511],
        },
        {
          id: 512,
          types: [5, 512],
        },
        {
          id: 513,
          types: [5, 513],
        },
        {
          id: 514,
          types: [5, 514],
        },
        {
          id: 515,
          types: [5, 515],
        },
        {
          id: 516,
          types: [5, 516],
        },
        {
          id: 517,
          types: [5, 517],
        },
        {
          id: 518,
          types: [5, 518],
        },
        {
          id: 519,
          types: [5, 519],
        },
        {
          id: 520,
          types: [5, 520],
        },
        {
          id: 521,
          types: [5, 521],
        },
        {
          id: 522,
          types: [5, 522],
        },
        {
          id: 523,
          types: [5, 523],
        },
        {
          id: 524,
          types: [5, 524],
        },
        {
          id: 525,
          types: [5, 525],
        },
        {
          id: 526,
          types: [5, 526],
        },
        {
          id: 527,
          types: [5, 527],
        },
        {
          id: 101,
          types: [1, 101],
        },
        {
          id: 10101,
          types: [1, 101, 10101],
        },
        {
          id: 10102,
          types: [1, 101, 10102],
        },
        {
          id: 10103,
          types: [1, 101, 10103],
        },
        {
          id: 10104,
          types: [1, 101, 10104],
        },
        {
          id: 10105,
          types: [1, 101, 10105],
        },
        {
          id: 102,
          types: [1, 102],
        },
        {
          id: 103,
          types: [1, 103],
        },
        {
          id: 201,
          types: [2, 201],
        },
        {
          id: 203,
          types: [2, 203],
        },
        {
          id: 20301,
          types: [2, 203, 20301],
        },
        {
          id: 20302,
          types: [2, 203, 20302],
        },
        {
          id: 20303,
          types: [2, 203, 20303],
        },
        {
          id: 20304,
          types: [2, 203, 20304],
        },
        {
          id: 20305,
          types: [2, 203, 20305],
        },
        {
          id: 20306,
          types: [2, 203, 20306],
        },
        {
          id: 202,
          types: [2, 202],
        },
        {
          id: 204,
          types: [2, 204],
        },
        {
          id: 205,
          types: [2, 205],
        },
        {
          id: 206,
          types: [2, 206],
        },
        {
          id: 207,
          types: [2, 207],
        },
        {
          id: 208,
          types: [2, 208],
        },
        {
          id: 209,
          types: [2, 209],
        },
        {
          id: 210,
          types: [2, 210],
        },
        {
          id: 401,
          types: [4, 401],
        },
        {
          id: 402,
          types: [4, 402],
        },
        {
          id: 403,
          types: [4, 403],
        },
        {
          id: 404,
          types: [4, 404],
        },
      ];
      let types = [];
      for (let i = 0; i < typesList.length; i++) {
        if (typesList[i].id == type) {
          types = typesList[i].types;
          break;
        }
      }

      _that.$store.state.expandedKeys = [];
      for (let i = 0; i < types.length; i++) {
        _that.$store.state.expandedKeys.push(types[i]);
      }
    },
  },
  mounted() {
    this.restaurants = this.loadAll();
  },
  activated() {
    let _that = this;

    let type1 = _that.$route.params.type;
    if (type1) {
      _that.getTypes(type1);
      _that.getPositionText(type1);
      _that.$nextTick(() => {
        document.title = _that.positionText.name;
      });
      _that.$router.push({
        path: _that.$router.path,
        query: { type: type1 },
      });
      return;
    }
    let type2 = _that.$route.query.type;
    if (type2) {
      _that.getTypes(type2);
      _that.getPositionText(type2);
      _that.$nextTick(() => {
        document.title = _that.positionText.name;
      });
      _that.$router.push({
        path: _that.$router.path,
        query: { type: type2 },
      });
    }
  },
};
</script>

<style lang="less" scoped>
.help {
  padding-top: 56px;

  .title {
    font-weight: 600;
    color: #222429;
    font-size: 34px;
    text-align: center;
  }
  .content-wrap {
    display: flex;
    justify-content: center;
    padding-bottom: 80px;
    margin-top: -1px;

    .left {
      width: 360px;
      background: #f7f8fa;
      border-radius: 3px 0 0 3px;
      padding: 30px 0;
      box-sizing: border-box;
      min-height: 1000px;
      .l-title {
        margin-left: 20px;
        margin-right: 20px;
        padding-bottom: 20px;
        font-weight: 600;
        color: #222429;
        font-size: 20px;
        border-bottom: 1px solid #ccced4;
      }
      .l-list {
        padding-top: 10px;
      }
    }
    .right {
      width: 880px;
      border-radius: 0 3px 3px 0;
      border: 1px solid #ccced4;
      padding: 20px 30px;
      box-sizing: border-box;
      min-height: 1000px;
      .position-wrap {
        .position-text1 {
          font-weight: 500;
          color: #222429;
          font-size: 24px;
          margin-bottom: 30px;
        }
        .position-text2-wrap {
          font-size: 14px;
          color: #0078d7;
          .position-text2 {
            cursor: pointer;
            line-height: 28px;
          }
        }
      }
    }
  }
}
</style>
<style lang="less">
.keyword-search {
  margin: 0 auto;
  margin-top: 34px;
  width: 817px;
  height: 50px;
  .el-input__inner {
    width: 817px;
    height: 50px;
    line-height: 50px;
    border: 1px solid #c2c5cc;
    color: #222429;
    font-size: 20px;
  }
}
</style>