<template>
    <div class="controled">
        <div class="guide1" v-if="currentid==20301">
            <div class="warm-wrap">
                <div class="guide-text ml">个人版/家庭版 请互联网搜索 RDP Wrapper 了解开启方法</div>
            </div>
            <div class="guide-text mt">1. 鼠标右键点击开始菜单->设置：</div>
            <div class="twm-icon">
                <img :src="icon68" alt="">
            </div>
            <div class="guide-text mt">2. 点击“系统”：</div>
            <div class="twm-icon">
                <img :src="icon69" alt="">
            </div>
            <div class="guide-text mt">3. 滑动至菜单底部，点击“远程桌面”，并启用远程桌面:</div>
            <div class="twm-icon">
                <img :src="icon70" alt="">
            </div>
        </div>
        <div class="guide1" v-if="currentid==20302">
            <div class="guide-text">1. 可以通过聚焦搜索或者系统设置开启Mac远程桌面</div>
            <div class="guide-text ml">聚焦搜索：</div>
            <div class="guide-text ml"><span class="blue">command⌘ + 空格</span> 搜索：“vnc”或“远程管理”进入设置页面</div>
            <div class="guide-text ml">系统设置：</div>
            <div class="guide-text ml">系统设置->共享->开启“远程管理”</div>
            <div class="twm-icon">
                <img :src="icon71" alt="">
            </div>
            <div class="guide-text ml">macOS-13（Ventura）系统设置->通用->共享->开启“远程管理”</div>
            <div class="twm-icon">
                <img :src="icon72" alt="">
            </div>
            <div class="guide-text mt">2. 可以点击“i”来设置相关的访问权限或访问密码</div>
        </div>
        <div class="guide1" v-if="currentid==20303">
            <div class="guide-text">1. 点击“远程桌面”</div>
            <div class="twm-icon">
                <img :src="icon73" alt="">
            </div>
            <div class="guide-text">2. 等待与Windows设备建立连接：</div>
            <div class="twm-icon">
                <img :src="icon74" alt="">
            </div>
            <div class="guide-text">3. 输入被控Windows设备的账户名与密码：</div>
            <div class="twm-icon">
                <img :src="icon75" alt="">
            </div>
            <div class="guide-text">4. 连接成功进入远程桌面</div>
            <div class="twm-icon">
                <img :src="icon76" alt="">
            </div>
        </div>
        <div class="guide1" v-if="currentid==20304">
            <div class="guide-text">1. 点击Mac的远程桌面</div>
            <div class="twm-icon">
                <img :src="icon77" alt="">
            </div>
            <div class="guide-text">2. 等待建立连接</div>
            <div class="twm-icon">
                <img :src="icon78" alt="">
            </div>
            <div class="guide-text">3. 输入远程管理密码</div>
            <div class="twm-icon twm-icon4">
                <img :src="icon79" alt="">
            </div>
            <div class="guide-text">4. 进入远程Mac桌面，输入Mac登录密码即可</div>
            <div class="twm-icon">
                <img :src="icon80" alt="">
            </div>
        </div>
        <div class="guide1" v-if="currentid==20305">
            <div class="guide-text">1. 点击“远程桌面”</div>
            <div class="twm-icon">
                <img :src="icon81" alt="">
            </div>
            <div class="guide-text">2. 等待与Windows设备建立连接：</div>
            <div class="warm-wrap">
                <div class="guide-text ml">若Mac上未安装Microsoft Remote Desktop，则会提示并引导安装，若已安装则进入下一步</div>
            </div>
            <div class="twm-icon">
                <img :src="icon82" alt="">
            </div>
            <div class="guide-text">3. 输入被控Windows设备的账户名与密码：</div>
            <div class="twm-icon">
                <img :src="icon83" alt="">
            </div>
            <div class="twm-icon">
                <img :src="icon84" alt="">
            </div>
            <div class="guide-text">4. 进入Windows远程桌面</div>
            <div class="twm-icon">
                <img :src="icon85" alt="">
            </div>
        </div>
        <div class="guide1" v-if="currentid==20306">
            <div class="guide-text">1. 点击Mac的远程桌面</div>
            <div class="twm-icon">
                <img :src="icon86" alt="">
            </div>
            <div class="guide-text">2. 等待建立连接</div>
            <div class="twm-icon">
                <img :src="icon87" alt="">
            </div>
            <div class="guide-text">3. 输入用户名及密码（登录账户名&登录密码）</div>
            <div class="twm-icon twm-icon5 twm-icon3">
                <img :src="icon88" alt="">
            </div>
            <div class="guide-text">4. 进入远程桌面</div>

        </div>

    </div>
</template>

<script>
    export default {
        name: "InstallControled",
        props:['currentid'],
        data:function () {
            return {
                icon68: require("../../assets/help/icon68.png"),
                icon69: require("../../assets/help/icon69.png"),
                icon70: require("../../assets/help/icon70.png"),
                icon71: require("../../assets/help/icon71.png"),
                icon72: require("../../assets/help/icon72.png"),
                icon73: require("../../assets/help/icon73.png"),
                icon74: require("../../assets/help/icon74.png"),
                icon75: require("../../assets/help/icon75.png"),
                icon76: require("../../assets/help/icon76.png"),
                icon77: require("../../assets/help/icon77.png"),
                icon78: require("../../assets/help/icon78.png"),
                icon79: require("../../assets/help/icon79.png"),
                icon80: require("../../assets/help/icon80.png"),
                icon81: require("../../assets/help/icon81.png"),
                icon82: require("../../assets/help/icon82.png"),
                icon83: require("../../assets/help/icon83.png"),
                icon84: require("../../assets/help/icon84.png"),
                icon85: require("../../assets/help/icon85.png"),
                icon86: require("../../assets/help/icon86.png"),
                icon87: require("../../assets/help/icon87.png"),
                icon88: require("../../assets/help/icon88.png"),
            }
        },
        methods:{
            goClick:function (data) {
                let routeUrl = this.$router.resolve({
                    path: data// 这里的路径就可以正常的写，不需要添加_blank: true
                })
                window.open(routeUrl.href, '_blank');
            }
        }
    }
</script>

<style scoped lang="less">
.controled{
    .guide1{
        .guide-title{
            margin-bottom: 30PX;
            margin-top: 30PX;
            font-weight: 500;
            color: #222429;
            font-size: 24PX;
        }
        .content{
            font-size: 16PX;
            div{
                line-height: 30PX;
            }
        }
        .line{
            margin: 20PX 0;
            width: 100%;
            height: 1PX;
            background-color: #ccced4;
        }
        .guide-text{
            font-size: 16PX;
            color: #24292f;
            line-height: 28PX;
            span{
                color: #3370ff;
                cursor: pointer;
            }
            a{
                text-decoration: none;
                color: #3370ff;
            }
            .green{
                color: #2EA121;
                font-weight: 900;
            }
            .blue{
                color: #245bdb;
            }
        }

        .twm-icon2{
            width:50%;
        }
        .twm-icon3{
            margin: 0 auto;
        }
        .twm-icon4{
            text-align: center;
        }
        .twm-icon5{
            width:60%;
        }
        .twm-icon{
            margin-top: 15PX;
            margin-bottom: 15PX;
            img{
                max-width: 100%;
            }
        }
        .mt{
            margin-top: 40PX;
        }
        .ml{
            margin-left: 20PX;
        }

        .warm-wrap{
            padding: 16PX;
            padding-left: 3PX;
            width: 100%;
            border: 1PX solid #bacefd;
            background-color: #f0f4ff;
            box-sizing: border-box;
            border-radius: 8PX;
            .warms{
                padding-left: 40PX;
            }
        }
    }
}
</style>