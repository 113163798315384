<template>
	<div class="telescope">
		<div class="wrap1">
			<img :src="telescope1" alt="#" />
			<div class="text1">一键访问远端设备，安全又高效的远程管理工具</div>
			<div class="download-btn" @click="downloadClick">下载客户端</div>
		</div>
		<div class="wrap2">
			<div class="text1">Nas远程桌面</div>
			<div class="text2">
				无需公网IP，一键完成远程配置，随时随地轻松远程访问Nas桌面
			</div>
			<div class="text3">使用便捷 高速访问 安全传输</div>
		</div>
		<div class="wrap3">
			<div class="text1">远程Windows桌面</div>
			<div class="text2">电脑无需安装应用，快速远程访问Windows设备</div>
			<div class="text3">异地远程办公、远程OA</div>
		</div>
		<div class="wrap4">
			<div class="text1">开机远程</div>
			<div class="text2">无需外置硬件设备即可唤醒局域网内任意主机</div>
			<div class="text3">主机需支持WOL远程唤醒功能</div>
		</div>
		<div class="wrap5">
			<div class="text1">远程路由器管理</div>
			<div class="text2">随时随地轻松访问路由管理页面</div>
		</div>
		<Foot></Foot>
	</div>
</template>

<script>
import Foot from "@/components/Foot.vue";

export default {
	name: "Telescope",
	components: {
		Foot,
	},
	data: function () {
		return {
			telescope1: require("../assets/telescope1.svg"),
			downloadUrl: "https://tan.ionewu.com/api/jdxb/download/v2/windows",
		};
	},
	methods: {
		downloadClick: function () {
			let _that = this;
			window.open(_that.downloadUrl);
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.mixinBg {
	background-position: center;
	background-size: 100% 100%;
	background-repeat: no-repeat;
}
.telescope {
	.wrap1 {
		padding-top: 1px;
		text-align: center;
		height: 900px;
		.mixinBg();
		background-image: url("../assets/telescope2.png");

		img {
			margin-top: 354px;
			width: 377px;
			height: 74px;
		}

		.text1 {
			margin-top: 35px;
			font-size: 24px;
			color: #d8d8d9;
		}

		.download-btn {
			margin: 0 auto;
			margin-top: 84px;
			width: 234px;
			height: 72px;
			line-height: 72px;
			color: #f5f5f5;
			font-weight: 700;
			font-size: 21px;
			text-align: center;
			border-radius: 102px;
			background-image: linear-gradient(to right, #1f5de3, #235ad1);
			cursor: pointer;
		}
	}
	.wrap2,
	.wrap3,
	.wrap4,
	.wrap5 {
		height: 729px;
		.mixinBg();
		box-sizing: border-box;

		.text1 {
			font-size: 48px;
			color: #1a1a1a;
		}
		.text2 {
			margin-top: 36px;
			font-size: 21px;
			color: #666666;
		}
		.text3 {
			margin-top: 63px;
			font-size: 21px;
			color: #666666;
			font-weight: 700;
		}
	}
	.wrap2 {
		padding-left: 315px;
		padding-top: 186px;
		background-image: url("../assets/telescope3.png");

		.text2 {
			width: 531px;
		}
	}
	.wrap3 {
		padding-left: 1124px;
		padding-top: 234px;
		background-image: url("../assets/telescope4.png");
	}
	.wrap4 {
		padding-left: 361px;
		padding-top: 180px;
		background-image: url("../assets/telescope5.png");
	}
	.wrap5 {
		height: 810px;
		padding-left: 1187px;
		padding-top: 324px;
		background-image: url("../assets/telescope6.png");
	}
}
</style>
