<template>
	<div class="telescope">
		<div class="wrap1">
			<img :src="share1" alt="#" />
			<div class="text1">
				加入小宝驿站群，每月额外赚取海量视频会员特权
			</div>
			<div class="download-btn" @click="downloadClick">下载客户端</div>
		</div>
		<div class="wrap3">
			<div class="text1">积分收益</div>
			<div class="text2">
				通过共享上行带宽及部分存储空间，将闲置资源加入到节点小宝边缘云，为其他节点提供加速服务即可获取积分收益。
			</div>
			<div class="text3">共享上行带宽不会影响您的上网体验</div>
		</div>
		<Foot></Foot>
	</div>
</template>

<script>
import Foot from "@/components/Foot.vue";

export default {
	name: "Telescope",
	components: {
		Foot,
	},
	data: function () {
		return {
			share1: require("../assets/share1.svg"),
			downloadUrl: "https://tan.ionewu.com/api/jdxb/download/v2/windows",
		};
	},
	methods: {
		downloadClick: function () {
			let _that = this;
			window.open(_that.downloadUrl);
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.mixinBg {
	background-position: center;
	background-size: 100% 100%;
	background-repeat: no-repeat;
}
.telescope {
	.wrap1 {
		padding-top: 1px;
		text-align: center;
		height: 795px;
		width: 100%;
		box-sizing: border-box;
		.mixinBg();
		background-image: url("../assets/share2.png");

		img {
			margin-top: 311px;
			width: 942px;
			height: 74px;
		}

		.text1 {
			margin-top: 36px;
			font-size: 24px;
			color: #c2cce5;
		}

		.download-btn {
			margin: 0 auto;
			margin-top: 89px;
			width: 234px;
			height: 72px;
			line-height: 72px;
			color: #f5f5f5;
			font-weight: 700;
			font-size: 21px;
			text-align: center;
			border-radius: 102px;
			background-image: linear-gradient(to right, #1f5de3, #235ad1);
			cursor: pointer;
		}
	}
	.wrap3,
	.wrap4,
	.wrap5 {
		height: 1005px;
		.mixinBg();
		box-sizing: border-box;

		.text1 {
			font-size: 48px;
			color: #1a1a1a;
		}
		.text2 {
			margin-top: 20px;
			font-size: 21px;
			color: #666666;
		}
	}

	.wrap3 {
		padding-left: 1124px;
		height: 780px;
		padding-top: 209px;
		background-image: url("../assets/share4.png");

		.text2 {
			margin-top: 36px;
			width: 630px;
		}

		.text3 {
			margin-top: 63px;
			font-size: 21px;
			color: #666666;
			font-weight: 700;
		}
	}
}
</style>
