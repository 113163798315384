<template>
  <div class="twm-device-query">
    <div class="guide1">
      <div class="guide-title">铁威马x86/arm设备查询</div>
      <div class="guide-title">
        arm机型:<font class="title-tips">暂不支持arm32架构</font>
      </div>
      <div class="content">
        <div>F2-211</div>
        <div>F2-210</div>
      </div>
      <div class="line"></div>
      <div class="guide-title">x86机型:</div>
      <div class="content">
        <div>F2-221</div>
        <div>F2-223</div>
        <div>F4-221</div>
        <div>F4-223</div>
      </div>
    </div>
    <Foot></Foot>
  </div>
</template>

<script>
import Foot from "@/components/Foot.vue";

export default {
  name: "TwmDeviceQuery",
  components: {
    Foot,
  },
};
</script>

<style scoped lang="less">
.twm-device-query {
  .guide1 {
    margin: 0 auto;
    padding: 20px 0;
    padding: 20px 0;
    width: 50%;
    min-height: 800px;
    .guide-title {
      font-weight: 500;
      color: #222429;
      font-size: 24px;
      margin-bottom: 30px;
      .title-tips {
        font-size: 16px;
        line-height: 30px;
        margin-left: 20px;
      }
    }
    .content {
      font-size: 16px;
      div {
        line-height: 30px;
      }
    }
    .line {
      margin: 20px 0;
      width: 100%;
      height: 1px;
      background-color: #ccced4;
    }
  }
}
</style>