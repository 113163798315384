<template>
  <div class="controled">
    <div class="guide1" v-if="currentid == 501">
      <div class="guide-text">
        如果您微信和手机号各自注册了一个账号，就会产生2个不同的UID，导致手机号已被绑定的情况。目前，节点小宝只能通过微信登录后绑定手机。
      </div>
      <div class="guide-text mt">
        如果您需要绑定手机号，请先注销手机号关联的账号，然后使用微信登录，再绑定手机。操作步骤如下：
      </div>
      <div class="guide-text">1. 使用验证码登录节点小宝App。</div>
      <div class="guide-text">
        2. 前往“设置”-“注销玩物科技账号”，根据提示完成注销操作。
      </div>
      <div class="guide-text">
        3. 使用微信登录节点小宝，然后前往“设置”，绑定手机号。
      </div>
      <div class="guide-text mt">
        请注意，注销账户将会注销该UID在玩物科技所有产品下的账户。在注销账户之前，请确保已经妥善处理了账户内的相关资产和权益。
      </div>
    </div>
    <div class="guide1" v-if="currentid == 502">
      <div class="guide-text mt">
        节点小宝使用自研P2P技术，实现客户端直连，从而实现不限速的传输速率。客户端访问设备端设备或服务的速率取决于您的硬件及网络。
      </div>
      <div class="guide-text mt">
        当通过web网页端访问远程服务时，可能会根据服务器承载情况进行一定程度的限速。我们会根据实际情况尽可能地优化限速策略，以保证您的访问体验。
      </div>
    </div>
    <div class="guide1" v-if="currentid == 503">
      <div class="guide-text mt">
        节点小宝需要“客户端”与“设备端”结合使用。为了让您更好地了解如何获取绑定码，我们提供以下指南：
      </div>
      <div class="guide-text">1. 在受控设备上，开启“节点小宝”。</div>
      <div class="guide-text">
        2. 在“设备端”界面上，您将看到一个独特的绑定码。
      </div>
      <div class="guide-text">
        3. 前往“客户端”，在其界面上输入上一步骤中得到的绑定码。
      </div>
      <div class="guide-text">4. 您现在可以开始使用节点小宝了！</div>
      <div class="guide-text mt">
        为了让您更好地了解如何使用节点小宝，我们建议您查看我们的其他文档。
      </div>
    </div>
    <div class="guide1" v-if="currentid == 504">
      <div class="guide-text mt">节点小宝目前已支持：</div>
      <div class="guide-text ml">群晖</div>
      <div class="guide-text ml">铁威马</div>
      <div class="guide-text ml">威联通</div>
      <div class="guide-text ml">联想</div>
      <div class="guide-text ml">海康</div>
      <div class="guide-text ml">Windows</div>
      <div class="guide-text ml">macOS</div>

      <div class="guide-text mt">
        以下厂商机型不完全支持，具体支持的上线时间可咨询厂商客服。
      </div>
      <div class="guide-text ml">联想部分型号支持</div>
      <div class="guide-text ml">海康仅支持小宝驿站功能（原小宝积分）</div>
    </div>
    <div class="guide1" v-if="currentid == 505">
      <div class="guide-text mt">
        当您无法通过App访问服务或者访问白屏时，可以尝试以下方法进行排查：
      </div>
      <div class="guide-text ml">1. 确认您的App已经升级到最新版本。</div>
      <div class="guide-text ml">2. 确认您的设备端已经升级到最新版本。</div>
      <div class="guide-text ml">
        3. 确认您的局域网可以通过IP:PORT访问该服务。
      </div>
      <div class="guide-text mt">
        如果您尝试了以上方法，仍然无法解决问题，请联系节点小宝技术团队。我们将会协助您排查并解决问题。
      </div>
    </div>
    <div class="guide1" v-if="currentid == 506">
      <div class="guide-text mt">
        您在节点小宝实际支付金额的部分均可申请发票，请添加客服并发按以下格式发送开票信息：
      </div>
      <div class="guide-text mt">
        会员ID：（节点小宝web、win/mac、App、小程序内查看）
      </div>
      <div class="guide-text">发票类型：（纸质普通发票、纸质专用发票）</div>
      <div class="guide-text">发票内容：（默认“信息技术服务"）</div>
      <div class="guide-text">抬头类型：（个人、企业）</div>
      <div class="guide-text">抬头名称：（企业专用发票必填）</div>
      <div class="guide-text">企业税号：（企业专用发票必填）</div>
      <div class="guide-text">开户行：（企业专用发票必填</div>
      <div class="guide-text">开户账号：（企业专用发票必填）</div>
      <div class="guide-text">开票地址：（企业专用发票必填）</div>
      <div class="guide-text">企业电话：（企业专用发票必填）</div>
      <div class="guide-text">身份证号码：（个人发票必填）</div>
      <div class="guide-text">身份证号码：（个人发票必填）</div>
      <div class="guide-text">收件人及地址：（用于邮寄发票）</div>
      <div class="guide-text">正常情况下，我们将在当月月底为你开具发票</div>
    </div>
    <div class="guide1" v-if="currentid == 507">
      <div class="guide-title">积分兑换话费</div>
      <div class="guide-text">
        由于运营商月初月末为结算日，兑换话费充值的失败率较高，建议错峰兑换（兑换失败后积分会原路返还）
      </div>
      <div class="guide-title4">话费兑换一直没有库存</div>
      <div class="guide-text">请刷新页面或退出后重新登录查看库存</div>
      <div class="guide-title4">兑换迅雷会员</div>
      <div class="guide-text">
        迅雷会员充值将会向充值手机号发送短信，通过短信内的兑换链接进行会员权益的兑换。
      </div>
    </div>
    <div class="guide1" v-if="currentid == 508">
      <div class="guide-text">
        一键远程的功能升级到内网穿透服务了，您可以通过自定义域名来访问对应的服务。这样的好处是可以随时随地，输入域名就可以直达相关服务了。例如：666.iepose.cn
      </div>
    </div>
    <div class="guide1" v-if="currentid == 509">
      <div class="guide-text">
        选择需要中转的设备，然后填入内网服务IP和端口（192.168.1.1：80），自定义一个域名（luyou.iepose.cn）这样就完成了添加。内网穿透是通过域名来访问，使用很方便。且支持多平台多环境下使用。
      </div>
    </div>
    <div class="guide1" v-if="currentid == 510">
      <div class="guide-text">
        您开启了客户端是免验证的，如果没有开启客户端是需要验证身份的，验证入口有节点小宝小程序，WEB管理台等。
      </div>
    </div>
    <div class="guide1" v-if="currentid == 511">
      <div class="guide-text">
        首先需要安装设备端，例如：NAS或PC端。然后手机端安装节点小宝APP，两端用相同的帐号登陆，例如微信扫码登陆。这样就可以将手机的相片，快速的备份到设备端了，轻松释放手机空间，还可以珍藏回忆。免去用数据线或U盘备份的麻烦和成本。
      </div>
    </div>
    <div class="guide1" v-if="currentid == 512">
      <div class="guide-text">
        点对点异地组网是将两端的设备进行互联，可以访问设备端的相关服务，例如：PC端访问NAS端，就可以通过虚拟IP+端口来访问。例如：10.20.30.2：5000，这样就能访问群晖NAS了。同时也可以通过SMB挂载共享文件夹，实现像本地化一样的访问操作。
      </div>
    </div>
    <div class="guide1" v-if="currentid == 513">
      <div class="guide-text">
       本功能是将对方的局域网全部映射到本地来访问，可以像在对方局域网一样，使用相同的内网IP进行访问。比如对方局域网IP段是192.168.0.1，就可以在本地使用192.168.0.1访问相关服务，完全还原了局域网的体验。这对于异地办公时，不需要更改配置，直接就可以进入工作。还可以挂载了共享文件夹，启动节点小宝后，无论在什么地方，都可以很方便的访问，不需要另外配置IP信息。
      </div>
    </div>
    <div class="guide1" v-if="currentid == 514">
      <div class="guide-text">
       选择一台中转设备，例如远程网段为192.168.66.1则输入192.168.66.0/24 自定义网段是根据自已的喜好来添加，例如：11.22.33.0/24，请注意，0/24为结尾。
        添加完之后，就可以通过原IP访问，也可以通过自定义网段访问。
      </div>
      <div class="guide-text">
        例如：
      </div>
      <div class="guide-text">
        192.168.66.88：5000 访问群晖
      </div>
      <div class="guide-text">
        11.22.33.88：5000 访问群晖
      </div>
      <div class="guide-text">
        以上二个IP都可以访问。
      </div>
    </div>
    <div class="guide1" v-if="currentid == 515">
      <div class="guide-text">
        节点小宝采用极高的安全策略，支持双向认证、一次一密、动态域名、地域限制、出口IP限制、多级标签加密等多重安全机制，保障数据安全。节点小宝是一款集异地组网和内网穿透于一体的远程管理软件。可以帮助您轻松管理远程设备，实现了随时随地远程管理。
      </div>
    </div>
    <div class="guide1" v-if="currentid == 516">
      <div class="guide-text">
        群晖有4个版本的安装包，请选择对应的安装包手动安装节点小宝。安装成功之后，可以通过绑定码或微信扫码进行用户关联绑定。下载地址：www.iepose.com
      </div>
    </div>
    <div class="guide1" v-if="currentid == 517">

      <div class="guide-text">
       1、群晖的路径更改方法
      </div>
      <div class="guide-text">
        群晖：公共目录/OWSpeedUP由于NAS设备安全限制，不能随意修改下载路径。只可通过以下方式修改下载磁盘：控制面板>>共享文件夹>>选中OWSpeedUP>>点击编辑>>选择“所在位置”切换下载磁盘。
      </div>
      <div class="guide-text" style="margin-top: 10px">
        2、电脑或MAC，请在设备端修改相册存储位置
      </div>
      <div class="guide-text" style="margin-top: 10px">
        3、其它设备端，请到相应的APP或设备端，参考以上方法。
      </div>
    </div>
    <div class="guide1" v-if="currentid == 518">
      <div class="guide-text">
        为了方便用户快速使用产品，推出了绿色免安装版本，该版本使用时并未完成注册表的添加，因此会提示相应的错误。节点小宝程序都会进行公司签名认证，软件绿色且安全。
        出现该提示，请到Windows安全中心选择对节点小宝进行释放，释放后，建议重新解压或下载，再次运行，将恢复正常。同时我们将会尽快推出安装包版本。感谢您的支持。
      </div>
    </div>
    <div class="guide1" v-if="currentid == 519">
      <div class="guide-text">
        手机端仅作为客户端，不具备组网和代理功能，请到产品网站下载对应的设备端。设备端安装完成之后，通过微信扫码或绑定码进行用户关系。就可以使用产品的相关服务。例如：异地组网、内网穿透、相册备份等等。
      </div>
    </div>
    <div class="guide1" v-if="currentid == 520">
      <div class="guide-text">
        节点小宝有三大功能服务，1、内网穿透 2、异地组网 3、相册备份，产品可免费试用。
      </div>
    </div>
    <div class="guide1" v-if="currentid == 521">
      <div class="guide-text">
        节点小宝是一个功能强大的远程管理工具，运行了客户端之后，就和设备端建立了相应的连接。节点小宝支持广联通道，可以极快的传输文件和视频。如果您安装了Jeffly或Emby等视频工具，节点小宝就是最好的助手。开启节点小宝后，让您感受到像局域网一样的观影体验，且不用改变视频工具的IP配置，十分方便和快速。
      </div>
    </div>
    <div class="guide1" v-if="currentid == 522">
      <div class="guide-text">
        启动节点小宝异地组网之后，通过访问共享文件夹的IP。例如：smb://192.168.1.8
        挂载之后，就如同本地文件一样，快速方便的使用。

      </div>
    </div>
    <div class="guide1" v-if="currentid == 523">
      <div class="guide-text">
        建议通过内网穿透工具，添加对应的远程下载工具的IP和端口，通过自定义的域名就能随时随地的访问远程下载了。
      </div>
    </div>
    <div class="guide1" v-if="currentid == 524">
      <div class="guide-text">
        节点小宝VIP支持无理由退费，您可以联系客服，反馈您的需求。我们将在第一时间处理您的需求。
      </div>
    </div>
    <div class="guide1" v-if="currentid == 525">
      <div class="guide-text">
        支持产品售出3个月内开具发票，请联系客服并提供开票信息，我们将在7个工作日内开具对应金额的发票发送到您的邮箱中。
      </div>
    </div>
    <div class="guide1" v-if="currentid == 526">
      <div class="guide-text">
        目前暂不支持多人分享功能。专业版支持多人分享和使用，请联系客服咨询专业版。
      </div>
    </div>
    <div class="guide1" v-if="currentid == 527">
      <div class="guide-text">
        请确认是否开启了VPN软件或代理软件，关闭后重试。
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "InstallControled",
  props: ["currentid"],
  data: function () {
    return {
      icon105: require("../../assets/help/icon105.jpeg"),
      icon106: require("../../assets/help/icon106.jpeg"),
      icon107: require("../../assets/help/icon107.jpeg"),
      icon108: require("../../assets/help/icon108.png"),
      icon109: require("../../assets/help/icon109.png"),
    };
  },
  methods: {
    remoteOpenClick: function () {
      this.$emit("remoteOpenClick");
    },

    goClick: function (data) {
      let routeUrl = this.$router.resolve({
        path: data, // 这里的路径就可以正常的写，不需要添加_blank: true
      });
      window.open(routeUrl.href, "_blank");
    },
  },
};
</script>

<style scoped lang="less">
.controled {
  .guide1 {
    .guide-title {
      margin-bottom: 30px;
      margin-top: 30px;
      font-weight: 500;
      color: #222429;
      font-size: 24px;
    }
    .guide-title2 {
      margin-bottom: 10px;
      font-weight: 500;
      color: #222429;
      font-size: 20px;
    }
    .guide-title3 {
      margin-top: 20px;
    }
    .guide-title4 {
      margin-top: 30px;
      margin-bottom: 10px;
      font-weight: 500;
      color: #222429;
      font-size: 24px;
    }

    .content {
      font-size: 16px;
      div {
        line-height: 30px;
      }
    }
    .line {
      margin: 20px 0;
      width: 100%;
      height: 1px;
      background-color: #ccced4;
    }
    .guide-text {
      font-size: 16px;
      color: #24292f;
      line-height: 28px;
      span {
        color: #3370ff;
        cursor: pointer;
      }
      a {
        text-decoration: none;
        color: #3370ff;
      }
      .green {
        color: #2ea121;
        font-weight: 900;
      }
      .blue {
        color: #245bdb;
      }
      .go-text2 {
        color: #24292f;
        background-color: #fbbfbc;
      }
    }

    .twm-icon2 {
      width: 50%;
    }
    .twm-icon3 {
      margin: 0 auto;
    }
    .twm-icon4 {
      text-align: center;
    }
    .twm-icon5 {
      width: 60%;
    }
    .twm-icon6 {
      width: 40%;
    }
    .twm-icon {
      margin-top: 15px;
      margin-bottom: 15px;
      img {
        max-width: 100%;
      }
    }
    .mt {
      margin-top: 40px;
    }
    .mt2 {
      margin-top: 20px;
    }
    .ml {
      margin-left: 20px;
    }
    .ml2 {
      margin-left: 40px;
    }

    .warm-wrap {
      padding: 16px;
      padding-left: 3px;
      width: 100%;
      border: 1px solid #fed4a4;
      background-color: #fff5eb;
      box-sizing: border-box;
      border-radius: 8px;
      .warms {
        padding-left: 40px;
      }
    }

    .go-text2 {
    }
  }
}
</style>