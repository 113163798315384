<template>
	<div class="support" id="support">
		<div class="head1">支持与帮助</div>
		<ul class="nav">
			<li
				class="level1"
				@click="goClick('level12')"
				:class="{ active: checkedElement == 'level12' }"
			>
				安装节点小宝
			</li>
			<li
				class="level1"
				@click="goClick('level14')"
				:class="{ active: checkedElement == 'level14' }"
			>
				远程服务
			</li>
			<li
				class="level2"
				@click="goClick('level24')"
				:class="{ active: checkedElement == 'level24' }"
			>
				搜索远程服务
			</li>
			<li
				class="level2"
				@click="goClick('level25')"
				:class="{ active: checkedElement == 'level25' }"
			>
				自定义端口映射
			</li>
			<li
				class="level2"
				@click="goClick('level26')"
				:class="{ active: checkedElement == 'level26' }"
			>
				远程桌面服务
			</li>
			<li
				class="level2"
				@click="goClick('level27')"
				:class="{ active: checkedElement == 'level27' }"
			>
				远程 NAS 管理
			</li>
			<li
				class="level2"
				@click="goClick('level28')"
				:class="{ active: checkedElement == 'level28' }"
			>
				远程路由管理
			</li>
			<li
				class="level2"
				@click="goClick('level29')"
				:class="{ active: checkedElement == 'level29' }"
			>
				远程开机
			</li>
			<li
				class="level1"
				@click="goClick('level13')"
				:class="{ active: checkedElement == 'level13' }"
			>
				设备管理
			</li>
			<li
				class="level2"
				@click="goClick('level21')"
				:class="{ active: checkedElement == 'level21' }"
			>
				绑定设备
			</li>
			<li
				class="level2"
				@click="goClick('level22')"
				:class="{ active: checkedElement == 'level22' }"
			>
				缓存设置
			</li>
			<li
				class="level2"
				@click="goClick('level23')"
				:class="{ active: checkedElement == 'level23' }"
			>
				解绑设备
			</li>
			<li
				class="level1"
				@click="goClick('level15')"
				:class="{ active: checkedElement == 'level15' }"
			>
				小宝积分
			</li>
			<li
				class="level2"
				@click="goClick('level210')"
				:class="{ active: checkedElement == 'level210' }"
			>
				共享小宝驿站
			</li>
			<li
				class="level2"
				@click="goClick('level211')"
				:class="{ active: checkedElement == 'level211' }"
			>
				积分模式
			</li>
			<li
				class="level1"
				@click="goClick('level16')"
				:class="{ active: checkedElement == 'level16' }"
			>
				小宝会员
			</li>
			<li
				class="level1"
				@click="goClick('level17')"
				:class="{ active: checkedElement == 'level17' }"
			>
				小宝商城
			</li>
		</ul>
		<div class="content">
			<div class="head all-head" id="level12">安装节点小宝</div>
			<div class="question">
				<div class="question-text">
					节点小宝需搭配 NAS 或路由设备使用
				</div>
			</div>
			<div class="head4">联想、海康</div>
			<div class="texts">
				可前往 NAS 的套件中心搜索“节点小宝”进行安装；
			</div>
			<div class="head4">威联通</div>
			<div class="download-btns">
				<a href="https://tan.ionewu.com/api/jdxb/download/v2/wlt"
					>点击下载</a
				>
			</div>
			<div class="head4">群晖套件</div>
			<div class="texts">
				群晖套件可根据自己的平台选择下载对应的套件并手动安装：
			</div>
			<div class="download-btns">
				<a href="https://tan.ionewu.com/api/jdxb/download/v2/qh_x86"
					>DSM6_x86</a
				>
				<a href="https://tan.ionewu.com/api/jdxb/download/v2/qh_arm"
					>DSM6_arm</a
				>
				<a
					href="https://tan.ionewu.com/api/jdxb/download/v2/qh_dsm7_x86"
					>DSM7_x86</a
				>
				<a
					href="https://tan.ionewu.com/api/jdxb/download/v2/qh_dsm7_arm"
					>DSM7_arm</a
				>
			</div>
			<div class="question">
				<div class="question-text">如何辨别 NAS 型号及架构平台？</div>
			</div>
			<div class="texts">
				1、进入 NAS 管理后台，点击“主菜单”→“控制面板”→“信息中心”
			</div>
			<div class="texts">
				查看自己的<span class="stronger">设备型号</span>与<span
					class="stronger"
					>DSM 版本号：</span
				>
			</div>
			<img :src="support_a1" alt="#" class="icon" />
			<div class="texts">
				2、复制设备型号，前往文档
				<a href="https://jdxb.ionewu.com/support/6" target="_blank"
					>《已支持的群晖x86和arm机型判断》</a
				>中搜索自己的设备并确自己设备的<span class="stronger"
					>架构平台</span
				>。
			</div>

			<div class="head all-head" id="level14">远程服务</div>
			<div class="head2 all-head" id="level24">搜索远程服务</div>
			<div class="texts">
				绑定设备时小宝会自动搜索当前设备所在局域网的远程服务并自动添加；
			</div>
			<div class="texts">
				若服务列表内没有你所需要的服务，可点击“搜索远程服务”查看更多开放的局域网服务并添加。
			</div>
			<img :src="support_a7" alt="#" class="icon" />
			<div class="texts">
				以下是点击搜索远程服务后搜索到的本局域网内开放的服务：
			</div>
			<img :src="support_a8" alt="#" class="icon" />
			<div class="texts">添加完成后即可在远程服务列表内使用该服务。</div>
			<div class="head3">切换网络（设备）</div>
			<div class="texts">
				点击不同的设备即可切换至不同的局域网下使用所需要的远程服务
			</div>
			<img :src="support_a9" alt="#" class="icon" />
			<div class="head2 all-head" id="level25">自定义端口映射</div>
			<div class="texts">
				若未能搜索到所需要的服务，可点击自定义端口映射
			</div>
			<img :src="support_a10" alt="#" class="icon" />
			<div class="texts">
				输入需映射的设备 IP 和端口号后点击“极客映射”即可获得映射地址。
			</div>
			<div class="light-color texts">
				（此处以该设备所在局域网内的路由器管理后台为例 192.168.71.1:80）
			</div>
			<img :src="support_a11" alt="#" class="icon" />
			<div class="texts">
				点击复制映射地址，前往浏览器即可访问该服务啦~
			</div>
			<img :src="support_a12" alt="#" class="icon" />
			<div class="head2 all-head" id="level26">远程桌面服务</div>
			<div class="texts">
				远程桌面服务需要
				<a
					href="https://tan.ionewu.com/api/jdxb/download/v2/windows"
					target="_blank"
					>节点小宝 windows 端（戳我下载）</a
				>支持：
			</div>
			<div class="texts">
				点击小宝服务列表中的“远程桌面”即可访问指定 windows
				设备的远程桌面。
			</div>
			<img :src="support_a13" alt="#" class="icon" />
			<div class="head2 all-head" id="level27">远程 NAS 管理</div>
			<div class="texts">
				远程 NAS 管理可通过
				<a href="https://yc.iepose.com/jdxb/home/node" target="_blank"
					>节点小宝web端 </a
				>、<a
					href="https://tan.ionewu.com/api/jdxb/download/v2/windows"
					target="_blank"
					>节点小宝 windows 端</a
				>直接访问：
			</div>
			<div class="texts">
				点击 NAS 设备的“NAS 管理”即可打开浏览器并跳转到 NAS 桌面。
			</div>
			<div class="question">
				<div class="question-text">
					若无法访问远程 NAS 设备，请先确认 NAS
					管理服务端口后使用自定义端口映射
				</div>
				<div class="question-text2">
					如emby的服务端口为8096（请通过自定义端口映射访问）
				</div>
			</div>
			<img :src="support_a100" alt="#" class="icon" />
			<div class="head2 all-head" id="level28">远程路由管理</div>
			<div class="texts">
				远程路由管理可通过
				<a href="https://yc.iepose.com/jdxb/home/node" target="_blank"
					>节点小宝web端 </a
				>、<a
					href="https://tan.ionewu.com/api/jdxb/download/v2/windows"
					target="_blank"
					>节点小宝 windows 端</a
				>直接访问：
			</div>
			<div class="texts">
				点击路由设备的“路由器管理”即可打开浏览器并自动跳转到路由器管理后台。
			</div>
			<img :src="support_a14" alt="#" class="icon" />
			<div class="head2 all-head" id="level29">远程开机</div>
			<div class="texts">
				当 PC 主机离线时，可远程唤醒该 PC 主机。无需外置硬件设备，仅需
				PC 主板支持 Wake on LAN 功能即可实现远程开机。
			</div>
			<img :src="support_a15" alt="#" class="icon" />
			<div class="head3">【判断是否支持远程开机】</div>
			<div class="texts">
				重启电脑设备进入 BIOS 的电源管理菜单查看菜单是否有 Remote Wake
				Up 或 Wake on LAN 等类似选项，否则不支持远程开机。
			</div>
			<div class="head3">【进入 BIOS】</div>
			<div class="texts">
				开机时反复按“DELETE“或 F2/F8/F12 等按键进入 BIOS
				设置界面，具体按键可参考主板说明书或参考网络教程（百度经验）
			</div>
			<div class="head3">【开启唤醒功能】</div>
			<div class="texts">
				通常到“Power Managment（电源管理）”下寻找如下列选项：
			</div>
			<div class="texts">"Boot on LAN";</div>
			<div class="texts">"Wake on LAN";</div>
			<div class="texts">"PME Event WakeUp";</div>
			<div class="texts">"Resume by MAC LAN";</div>
			<div class="texts">"Wake-Up by PCI card";</div>
			<div class="texts">"Wake Up On PCI PME";</div>
			<div class="texts">"Power On by PCI Card";</div>
			<div class="texts">"WakeUp by PME of PCI";</div>
			<div class="texts">"Power On By PCI Devices";</div>
			<div class="texts">"WakeUp by Onborad LAN";</div>
			<div class="texts">"Resume By PCI or PCI-E Ddevice"</div>
			<div class="texts">或类似的配置项，并可以启用它。</div>
			<div class="texts">
				可视图形化的 UEFI BIOS，可参考下列方式设置：
			</div>
			<div class="texts">
				高级 > 高级电源管理（APM）> 开启 Resume By PCI or PCI-E
				Ddevice（由 pci/pcie 设备唤醒）选项。
			</div>

			<div class="head all-head" id="level13">设备管理</div>
			<div class="head2 all-head" id="level21">绑定设备</div>
			<div class="question">
				<div class="question-text">
					只有绑定设备后才可以对设备所在局域网的其它设备进行管理。
				</div>
			</div>
			<div class="head3">快速绑定</div>
			<div class="texts">
				1. 从 NAS 设备的套件中心打开节点小宝将会跳转至节点小宝登录页；
			</div>
			<div class="texts">2. 使用微信扫码登录；</div>
			<div class="texts">3. 根据弹窗提示，点击“确定”即可绑定设备；</div>
			<img :src="support_a2" alt="#" class="icon" />
			<div class="texts">
				4. 绑定完成后可通过
				<a href="https://yc.iepose.com/jdxb/home/node" target="_blank"
					>节点小宝 Web 版（传送门）</a
				>或
				<a
					href="https://tan.ionewu.com/api/jdxb/download/v2/windows"
					target="_blank"
					>节点小宝 Windows（戳我下载）</a
				>版直接访问 NAS 或路由设备。
			</div>
			<div class="head3">绑定码绑定：</div>
			<div class="texts">
				点击设备列表旁的添加设备按钮输入绑定码即可完成设备绑定：
			</div>
			<img :src="support_a3" alt="#" class="icon" />
			<img :src="support_a3_1" alt="#" class="icon" />
			<div class="head2 all-head" id="level22">缓存设置</div>
			<div class="texts">更改缓存路径：</div>
			<div class="texts">
				控制面板→共享文件夹→选中“OWSpeedup”→点击编辑→常规→所在位置→选择用于缓存的磁盘。
			</div>
			<div class="head3">清除缓存：</div>
			<div class="texts">1、点击设备下方的设置按钮进入设备管理：</div>
			<img :src="support_a4" alt="#" class="icon" />
			<div class="texts">
				2、点击“清除缓存”即可清除当前小宝共享小宝驿站占用的缓存啦
			</div>
			<div class="light-color texts">ps：清除缓存有一定延时哦</div>
			<img :src="support_a6" alt="#" class="icon" />
			<div class="head2 all-head" id="level23">解绑设备</div>
			<div class="texts">
				前往设备管理，设备详情后通过解绑按钮即可解绑设备。
			</div>

			<div class="head all-head" id="level15">小宝积分</div>
			<div class="head2 all-head" id="level210">共享小宝驿站</div>
			<div class="texts">
				小宝积分是将您闲置的带宽加入共享小宝驿站，对于有贡献闲置带宽的用户就会获得积分奖励，积分可以兑换各类
				VIP 卡或电话卡，让您快乐享受积分收益。
			</div>
			<div class="question">
				<div class="question-text">
					本服务不会影响您的看视频、玩游戏、购物的上网体验
				</div>
			</div>
			<div class="question">
				<div class="question-text">
					开启小宝积分需设备具有存储功能，若设备无存储功能则无法使用小宝积分服务，但可正常使用
				</div>
				<div class="texts">远程服务</div>
			</div>
			<div class="head2 all-head" id="level211">积分模式</div>
			<a
				href="https://yc.iepose.com/jdxb/home/speedshare"
				target="_blank"
				style="display: inline-block; margin-top: 10px"
				>模式设置传送门</a
			>
			<div class="texts">您可根据上网需求来选择对应的积分模式</div>
			<div class="question">
				<div class="question-text">
					普通模式：普通模式下小宝一般不会占用或消耗您的资源
				</div>
			</div>
			<div class="question">
				<div class="question-text">
					收益模式：开启收益模式可赚取积分收益
				</div>
			</div>

			<div class="head all-head" id="level16">小宝会员</div>
			<div class="texts">
				小宝会员是节点小宝推出的增值服务，购买后会解锁远程服务的数量限制及更多的设备绑定数量。
			</div>

			<div class="head all-head" id="level17">小宝商城</div>
			<div class="texts">
				小宝商城内可使用积分兑换各类会员卡或全网话费。
			</div>
			<div class="texts">兑换比例：1000 小宝积分=1 元</div>
			<div class="texts">
				小宝将会陆续上线更多商品兑换，商品详情请前往<a
					href="https://yc.iepose.com/jdxb/home/mall"
					target="_blank"
				>
					小宝商城 </a
				>查看。
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "Support",
	components: {},
	data: function () {
		return {
			support_a1: require("../assets/support_a1.png"),
			support_a2: require("../assets/support_a2.png"),
			support_a3: require("../assets/support_a3.png"),
			support_a3_1: require("../assets/support_a3_1.png"),
			support_a4: require("../assets/support_a4.png"),
			support_a6: require("../assets/support_a6.png"),
			support_a7: require("../assets/support_a7.png"),
			support_a8: require("../assets/support_a8.png"),
			support_a9: require("../assets/support_a9.png"),
			support_a10: require("../assets/support_a10.png"),
			support_a11: require("../assets/support_a11.png"),
			support_a12: require("../assets/support_a12.png"),
			support_a13: require("../assets/support_a13.png"),
			support_a14: require("../assets/support_a14.png"),
			support_a15: require("../assets/support_a15.png"),
			support_a100: require("../assets/support_a100.png"),

			checkedElement: "level12",
			isScrollLock: false,
		};
	},
	methods: {
		goClick: function (data) {
			let _that = this;
			_that.checkedElement = data;
			let target = document.getElementById(data);
			target.parentNode.parentNode.scrollTop = target.offsetTop; //元素上边距（相对于第一个定位的父元素）
			_that.isScrollLock = true;
			setTimeout(function () {
				_that.isScrollLock = false;
			}, 100);
		},
		scroll: function () {
			let _that = this;
			if (_that.isScrollLock) {
				//手动切换时，禁止执行下面的导航
				return;
			}
			let heads = document.getElementsByClassName("all-head");
			let headsOffsetTops = [];
			for (let index = 0; index < heads.length; index++) {
				let o = {
					id: heads[index].id,
					offsetTop: heads[index].offsetTop,
				};
				headsOffsetTops.push(o);
			}
			let st = document.getElementById("support").scrollTop;
			for (let index = headsOffsetTops.length - 1; index >= 0; index--) {
				if (st > headsOffsetTops[index].offsetTop) {
					_that.checkedElement = headsOffsetTops[index].id;
					break;
				}
			}
		},
	},
	mounted() {
		let _that = this;
		document
			.getElementById("support")
			.addEventListener("scroll", _that.scroll);
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.bg {
	background-position: center;
	background-size: 100% 100%;
	background-repeat: no-repeat;
}

.support {
	padding-top: 105px;
	height: 100%;
	overflow-y: scroll;
	background-color: #ffffff;
	color: #666666;
	font-size: 16px;

	.head1 {
		position: fixed;
		top: 42px;
		z-index: 1;
		width: 100%;
		padding-left: 360px;
		height: 105px;
		line-height: 105px;
		font-size: 34px;
		color: #1a1a1a;
		font-weight: 500;
		background-color: #ffffff;
	}
	.nav {
		position: fixed;
		top: 147px;
		left: 360px;
		height: 100%;
		width: 180px;
		cursor: pointer;
		background-color: #f7fbff;
		box-sizing: border-box;

		li {
			font-size: 14px;
			line-height: 42px;
			font-weight: 500;
			list-style: none;
		}
		.level2 {
			padding-left: 40px;
			font-weight: 400;
		}
		.level1 {
			padding-left: 20px;
		}

		.active {
			position: relative;
			color: #3370ff;
			background-color: #e6eefd;
		}
		.active::after {
			content: "";
			position: absolute;
			top: 0;
			right: 0;
			width: 4px;
			height: 42px;
			background-color: #1f5de3;
		}
	}
	.content {
		position: relative;
		padding-bottom: 300px;
		margin: 0 auto;
		min-width: 210px;
		font-size: 16px;
		max-width: 790px;
		min-width: 470px;

		.head {
			margin-top: 30px;
			font-size: 26px;
			color: #1a1a1a;
			font-weight: 500;
		}
		.head:first-child {
			margin-top: 0px;
		}
		.head2 {
			margin-top: 20px;
			font-size: 18px;
			color: #1a1a1a;
			font-weight: 700;
		}
		.head3 {
			margin-top: 20px;
			color: #1a1a1a;
			font-weight: 500;
		}
		.head4 {
			position: relative;
			padding-left: 8px;
			margin-top: 15px;
			color: #1a1a1a;
			font-weight: 500;
		}
		.head4::before {
			content: "";
			position: absolute;
			left: 0;
			top: 50%;
			transform: translate(0, -50%);
			width: 3px;
			height: 3px;
			border-radius: 50%;
			background-color: #1a1a1a;
		}

		.question {
			margin-top: 20px;
			padding: 16px 16px 16px 46px;
			background-color: #f5f5f5;
			border-radius: 4px;

			.question-text {
				position: relative;
			}
			.question-text::before {
				position: absolute;
				top: 50%;
				left: -30px;
				transform: translate(0, -50%);
				content: "";
				width: 24px;
				height: 24px;
				.bg();
				background-image: url("../assets/support14.svg");
			}
		}

		.download-btns {
			margin-top: 10px;

			a {
				margin-right: 20px;
				display: inline-block;
				color: #3370ff;
			}
		}
		.icon {
			margin-top: 20px;
			width: 100%;
		}
		.stronger {
			font-weight: 700;
		}

		.light-color {
			color: #999999;
		}

		a {
			text-decoration: none;
			color: #3370ff;
		}

		.texts {
			margin-top: 10px;
		}
	}
}
.support::-webkit-scrollbar {
	display: none;
}
</style>
