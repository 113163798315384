<template>
  <div id="app">
    <GoTop></GoTop>
    <Nav bg="bg1"></Nav>
    <keep-alive>
      <router-view v-slot="{ Component }">
        <component :is="Component" />
      </router-view>
    </keep-alive>
  </div>
</template>

<script>
import Nav from "@/components/Nav.vue";
import GoTop from "@/components/GoTop.vue";
import { mapActions } from "vuex";
export default {
  name: "App",

  components: {
    Nav,
    GoTop,
  },
  data: function () {
    return {};
  },
  watch: {
    $route(to) {
      if (
        to.path == "/" ||
        to.path == "/telescope" ||
        to.path == "/share" ||
        to.path == "/download" ||
        to.path == "/support"
      ) {
        document.getElementById("app").scrollIntoView();
      } else {
        document.getElementById("app").scrollIntoView({ behavior: "smooth" });
      }
    },
  },
  methods: {
    ...mapActions(["sendUserlog"]),
  },
  mounted: function () {
    this.sendUserlog(["open"]);
  },
};
</script>

<style lang="less">
* {
  padding: 0;
  margin: 0;
  border: 0;
  outline: 0;
}

html,
body {
  width: 100%;
  height: 100%;
}
#app {
  width: 100%;
}
body::-webkit-scrollbar {
  display: none;
}
body {
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: scroll;
}
html {
  overflow: -moz-hidden-unscrollable; /*注意！若只打 hidden，chrome 的其它 hidden 会出问题*/
}

/*body {*/
/*	width: calc(100vw + 18px); !*浏览器滚动条的长度大约是 18px*!*/
/*	overflow: auto;*/
/*}*/

#app {
  padding-top: 70px;
  font-family: "Microsoft Yahei", "微软雅黑", Avenir, Helvetica, Arial,
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /*-webkit-touch-callout: none;*/
  /* iOS Safari */
  /*-webkit-user-select: none;*/
  /* Chrome/Safari/Opera */
  /*-khtml-user-select: none;*/
  /* Konqueror */
  /*-moz-user-select: none;*/
  /* Firefox */
  /*-ms-user-select: none;*/
  /* Internet Explorer/Edge */
  /*user-select: none;*/
  /* Non-prefixed version, currently
not supported by any browser */
}
</style>

