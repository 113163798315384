<template>
  <div class="controled">
    <div class="guide1" v-if="currentid == 401">
      <div class="guide-text">
        <b>相册备份需App客户端与PC/NAS设备端搭配使用</b>
      </div>
      <div class="guide-text"><b>可一键导出手机相册到PC/NAS</b></div>
      <div class="guide-text ml">可跨网传输，不受地域限制</div>
      <div class="guide-text ml">原图不压缩，原照片完美存</div>
      <div class="guide-text"><b>使用步骤</b></div>
      <div class="guide-text">1. 打开节点小宝App，在工具箱打开“相册备份”</div>
      <div class="twm-icon twm-icon6 twm-icon3">
        <img :src="icon105" alt="" />
      </div>
      <div class="guide-text">2. 选择开启相册服务</div>
      <div class="twm-icon twm-icon6 twm-icon3">
        <img :src="icon106" alt="" />
      </div>
      <div class="guide-text">
        3. 选中需要导出的相册（默认全选），点击一键备份
      </div>
      <div class="twm-icon twm-icon6 twm-icon3">
        <img :src="icon107" alt="" />
      </div>
      <div class="guide-text">4. 备份中请尽量保持App在前台运行</div>
      <div class="twm-icon twm-icon6 twm-icon3">
        <img :src="icon108" alt="" />
      </div>
    </div>
    <div class="guide1" v-if="currentid == 402">
      <div class="guide-text">开启小宝驿站，共享闲置带宽</div>
      <div class="guide-text">
        可以赚取积分收益，积分可以兑换各类话费及影视会员卡
      </div>
      <div class="guide-text">
        共享带宽为上行带宽，不影响观影、游戏及购物等体验
      </div>
      <div class="guide-title">存储路径</div>
      <div class="guide-text">
        由于NAS设备安全限制，节点小宝不能随意修改下载路径。
      </div>
      <div class="guide-text">群晖可以通过以下方式修改下载磁盘：</div>
      <div class="guide-text ml">
        控制面板>>共享文件夹>>选中OWSpeedup>>点击编辑>>选择“所在位置”切换磁盘。
      </div>
      <div class="guide-text mt">群晖：File Station/OWSpeedup；</div>
      <div class="guide-text">海康：文件/下载/OWSpeedup；</div>
      <div class="guide-text">联想：三方应用/OWSpeedup；</div>
      <div class="guide-text">QNAP：Public/OWSpeedup。</div>
      <div class="guide-title">NAT类型</div>
      <div class="guide-title2">什么是NAT类型？</div>
      <div class="guide-text ml">具体NAT类型原理可查阅网络资料;</div>
      <div class="guide-text ml">
        对于小宝积分而言，NAT类型的数值越低代表设备积分能力越强。
      </div>
      <div class="guide-title2 guide-title3">NAT类型检测</div>
      <div class="guide-text ml">下载NAT类型检测工具（NatTypeTester）</div>
      <div class="guide-text ml">
        默认STUN server失效时，可使用<a
          href="http://stun.ionewu.com"
          target="_blank"
          >stun.ionewu.com</a
        >
      </div>
      <div class="twm-icon twm-icon2 twm-icon3">
        <img :src="icon109" alt="" />
      </div>
      <div class="guide-text ml">
        根据NAT检测工具中NAT type测试的结果，对照下方信息确认自己的NAT类型：
      </div>
      <div class="guide-text ml"><b>NAT1 → Full Cone NAT</b></div>
      <div class="guide-text ml">
        <b>NAT2 → Address-Restricted Cone NAT</b>
      </div>
      <div class="guide-text ml">
        <b>NAT3 → Port-Restricted Cone NAT</b>
      </div>
      <div class="guide-text ml"><b>NAT4 → Symmetric NAT</b></div>
      <div class="guide-title2 guide-title3">NAT类型优化</div>
      <div class="warm-wrap">
        <div class="guide-text ml">
          路由相关配置可查阅<a
            href="https://yc.iepose.com/jdb_help/nat.html"
            target="_blank"
            >《路由优化指引》</a
          >
        </div>
      </div>
      <div class="guide-text mt2">1. 尽可能少的路由层级；</div>
      <div class="guide-text">2. 尽可能使用近端路由/光猫或电脑拨号上网；</div>
      <div class="guide-text">3. 向运营商申请公网IP。</div>
      <div class="guide-title2 guide-title3">设备积分未到账</div>
      <div class="guide-text ml">初次启动小宝积分，需要24-72小时的部署期</div>
      <div class="guide-text ml">当日产生贡献的积分收益会在次日发放</div>
    </div>
    <div class="guide1" v-if="currentid == 403">
      <div class="guide-text">电信联通，一键宽带物理提速，最高可提速至700M</div>
      <div class="guide-text">支付即提速，无效可全额退款</div>
      <div class="guide-title4">提速能力</div>
      <div class="guide-text">
        提速页面将展示当前宽带账号信息，运营商信息与可提速能力信息
      </div>
    </div>
    <div class="guide1" v-if="currentid == 404">
      <div class="guide-title">功能概述</div>
      <div class="guide-text">
        通过节点小宝客户端可以远程开启控端设备局域网内的Windows主机（Windows主机需要支持WOL功能）
      </div>
      <div class="guide-text">
        判断设备是否支持或已开启WOL<a class="go-text2" @click="remoteOpenClick"
          >(跳转到“访问远程服务->远程开机”）</a
        >
      </div>
      <div class="guide-title4">添加Windows主机</div>
      <div class="guide-text ml">
        高级功能（此方式适用于已开启3389远程桌面及WOL功能）
      </div>
      <div class="guide-text ml2">发现服务：添加3389端口的服务</div>
      <div class="guide-text ml2">
        手动添加：手动添加Windows主机的IP+3389端口
      </div>
      <div class="guide-text ml">工具箱-远程开机</div>
      <div class="guide-text ml2">
        进入开机工具，通过如下方法查询本机mac地址，添加开机设备
      </div>
      <div class="guide-text ml2">
        您可以按下 "Win + R" 组合键打开运行窗口，输入 "cmd"
        后按下回车键。在打开的命令行窗口中输入"ipconfig/all"
        后按下回车键，可以看到本机的 MAC 地址。
      </div>
      <div class="guide-title">如何远程开机</div>
      <div class="guide-text">
        找到Windows3389主机离线后的远程服务卡片，点击“开机唤醒”即可发送开机指令
      </div>
      <div class="guide-title4">远程开机失败</div>
      <div class="guide-text ml">
        下发指令后，需要一定时间完成开机过程，请稍作等待后查看设备在线状态
      </div>
      <div class="guide-text ml">
        被开机主机局域网内没有在线的节点小宝设备端设备
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "InstallControled",
  props: ["currentid"],
  data: function () {
    return {
      icon105: require("../../assets/help/icon105.jpeg"),
      icon106: require("../../assets/help/icon106.jpeg"),
      icon107: require("../../assets/help/icon107.jpeg"),
      icon108: require("../../assets/help/icon108.png"),
      icon109: require("../../assets/help/icon109.png"),
    };
  },
  methods: {
    remoteOpenClick: function () {
      this.$emit("remoteOpenClick");
    },

    goClick: function (data) {
      let routeUrl = this.$router.resolve({
        path: data, // 这里的路径就可以正常的写，不需要添加_blank: true
      });
      window.open(routeUrl.href, "_blank");
    },
  },
};
</script>

<style scoped lang="less">
.controled {
  .guide1 {
    .guide-title {
      margin-bottom: 30px;
      margin-top: 30px;
      font-weight: 500;
      color: #222429;
      font-size: 24px;
    }
    .guide-title2 {
      margin-bottom: 10px;
      font-weight: 500;
      color: #222429;
      font-size: 20px;
    }
    .guide-title3 {
      margin-top: 20px;
    }
    .guide-title4 {
      margin-top: 30px;
      margin-bottom: 10px;
      font-weight: 500;
      color: #222429;
      font-size: 24px;
    }

    .content {
      font-size: 16px;
      div {
        line-height: 30px;
      }
    }
    .line {
      margin: 20px 0;
      width: 100%;
      height: 1px;
      background-color: #ccced4;
    }
    .guide-text {
      font-size: 16px;
      color: #24292f;
      line-height: 28px;
      span {
        color: #3370ff;
        cursor: pointer;
      }
      a {
        text-decoration: none;
        color: #3370ff;
      }
      .green {
        color: #2ea121;
        font-weight: 900;
      }
      .blue {
        color: #245bdb;
      }
      .go-text2 {
        color: #24292f;
        background-color: #fbbfbc;
      }
    }

    .twm-icon2 {
      width: 50%;
    }
    .twm-icon3 {
      margin: 0 auto;
    }
    .twm-icon4 {
      text-align: center;
    }
    .twm-icon5 {
      width: 60%;
    }
    .twm-icon6 {
      width: 40%;
    }
    .twm-icon {
      margin-top: 15px;
      margin-bottom: 15px;
      img {
        max-width: 100%;
      }
    }
    .mt {
      margin-top: 40px;
    }
    .mt2 {
      margin-top: 20px;
    }
    .ml {
      margin-left: 20px;
    }
    .ml2 {
      margin-left: 40px;
    }

    .warm-wrap {
      padding: 16px;
      padding-left: 3px;
      width: 100%;
      border: 1px solid #fed4a4;
      background-color: #fff5eb;
      box-sizing: border-box;
      border-radius: 8px;
      .warms {
        padding-left: 40px;
      }
    }

    .go-text2 {
    }
  }
}
</style>