<template>
  <div class="support1">
    <div class="content">
      <div class="head">OA异地办公</div>
      <div class="texts m1">受疫情的影响，远程办公已是常态，但众多企业的办公系统部署在本地，只支持内网访问，员工无法在公司外访问公司内网的办公系统。</div>
      <div class="texts m1">节点小宝支持内网穿透一键映射，随时随地轻松访问内网OA/CRM系统。</div>
      <div class="texts m2">需要材料:</div>
      <div class="texts m1">公司内网中支持节点小宝的NAS设备或路由设备</div>
      <div class="texts m2">1、首先确认服务器本地能访问OA系统</div>
      <img :src="support_b1" alt="#" class="icon">
      <div class="texts m3">2、通过NAS套件中心或路由插件中心安装节点小宝</div>
      <img :src="support_b2" alt="#" class="icon">
      <div class="texts m3">3、在NAS中打开节点小宝，进入节点小宝登录页</div>
      <img :src="support_b3" alt="#" class="icon">
      <div class="texts m3">4、使用微信扫码登录</div>
      <img :src="support_b4" alt="#" class="icon">
      <div class="texts m3">5、登录后会提示绑定设备，点击确定即可绑定当前设备</div>
      <img :src="support_b5" alt="#" class="icon">
      <div class="texts m3">6、点击自定义端口映射，输入内网OA系统的IP地址及端口号，点击极客映射获取映射地址</div>
      <img :src="support_b6" alt="#" class="icon">
      <div class="texts m3">7、将映射地址粘贴至浏览器网址栏访问，即可远程访问OA系统</div>
      <img :src="support_b7" alt="#" class="icon">
    </div>
    <Foot></Foot>
  </div>
</template>

<script>
  import Foot from "@/components/Foot.vue";

  export default {
  name: 'Support1',
  components:{
    Foot
  },
  data:function () {
    return {
      support_b1:require("../assets/support_b1.png"),
      support_b2:require("../assets/support_b2.png"),
      support_b3:require("../assets/support_b3.png"),
      support_b4:require("../assets/support_b4.png"),
      support_b5:require("../assets/support_b5.png"),
      support_b6:require("../assets/support_b6.png"),
      support_b7:require("../assets/support_b7.png"),
    }
  },


  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
  .bg{
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .support1{
    height: 100%;
    overflow-y: scroll;
    background-color: #FFFFFF;
    color: #666666;
    font-size: 16px;

    .content{
      position: relative;
      padding-top: 36px;
      padding-bottom: 150px;
      margin: 0 auto;
      min-width: 210px;
      font-size: 18px;
      max-width: 790px;
      min-width: 470px;

      .head{
        margin-top: 30px;
        font-size: 30px;
        color: #1A1A1A;
        font-weight: 500;
      }

      .texts{
        line-height: 26px;
      }

      .m1{
        margin-top: 10px;
      }
      .m2{
        margin-top: 40px;
      }
      .m3{
        margin-top: 20px;
      }
      .icon{
        margin-top: 20px;
        width: 100%;
      }

    }


  }
  .support1::-webkit-scrollbar {
    display: none;
  }
</style>
