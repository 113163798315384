<template>
  <div class="controled">
    <div class="guide1">
      <div class="warm-wrap">
        <div class="guide-text ml">节点小宝2.0远程下载功能已支持：</div>
        <div class="warms">
          <div class="guide-text">qBittorrent</div>
          <div class="guide-text">Transmission</div>
          <div class="guide-text">Aria2（即将支持）</div>
        </div>
      </div>
      <div class="guide-text mt">1. 添加远程下载服务</div>
      <div class="guide-text">
        若已在NAS开启相关服务，且端口为默认端口，绑定NAS后即自动创建相关的远程服务
      </div>
      <div class="guide-text">相关下载服务默认端口号参考：</div>
      <div class="guide-text ml">qBittorrent：8085</div>
      <div class="guide-text ml">Transmission：9091</div>
      <div class="guide-text ml">Aria2：6800</div>
      <div class="guide-text mt">
        2.
        若已为下载服务自定义端口号，请前往节点小宝“高级功能”中手动添加对应的ip:port
      </div>
      <div class="guide-text mt">3. 远程访问下载服务（以qb为例）</div>
      <div class="guide-text">在设备端点击对应服务的访问按钮即可发起访问</div>
      <div class="twm-icon">
        <img :src="icon46" alt="" />
      </div>
      <div class="guide-text">4. 等待连接</div>
      <div class="twm-icon">
        <img :src="icon47" alt="" />
      </div>
      <div class="guide-text">5. 输入登录密码</div>
      <div class="twm-icon">
        <img :src="icon48" alt="" />
      </div>
      <div class="guide-text">6. 进入qb下载页</div>
      <div class="twm-icon">
        <img :src="icon49" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "InstallControled",
  props: ["currentid"],
  data: function () {
    return {
      icon46: require("../../assets/help/icon46.png"),
      icon47: require("../../assets/help/icon47.png"),
      icon48: require("../../assets/help/icon48.png"),
      icon49: require("../../assets/help/icon49.png"),
    };
  },
  methods: {
    vipGuideClick: function () {
      this.$emit("vipGuideClick");
    },
    goClick: function (data) {
      let routeUrl = this.$router.resolve({
        path: data, // 这里的路径就可以正常的写，不需要添加_blank: true
      });
      window.open(routeUrl.href, "_blank");
    },
  },
};
</script>

<style scoped lang="less">
.controled {
  .guide1 {
    .guide-title {
      margin-bottom: 30px;
      margin-top: 30px;
      font-weight: 500;
      color: #222429;
      font-size: 24px;
    }
    .content {
      font-size: 16px;
      div {
        line-height: 30px;
      }
    }
    .line {
      margin: 20px 0;
      width: 100%;
      height: 1px;
      background-color: #ccced4;
    }
    .guide-text {
      font-size: 16px;
      color: #24292f;
      line-height: 28px;
      span {
        color: #3370ff;
        cursor: pointer;
      }
      a {
        text-decoration: none;
        color: #3370ff;
      }
      .green {
        color: #2ea121;
        font-weight: 900;
      }
      .go-text {
        text-decoration: underline;
        color: #24292f;
        cursor: pointer;
      }
    }
    .twm-icon {
      margin-top: 15px;
      margin-bottom: 15px;
      img {
        max-width: 100%;
      }
    }
    .twm-icon2 {
      width: 50%;
    }
    .twm-icon3 {
      margin: 0 auto;
    }
    .mt {
      margin-top: 40px;
    }
    .ml {
      margin-left: 20px;
    }
    .warm-wrap {
      padding: 16px;
      padding-left: 3px;
      width: 100%;
      border: 1px solid #bacefd;
      background-color: #f0f4ff;
      box-sizing: border-box;
      border-radius: 8px;
      .warms {
        padding-left: 40px;
      }
    }
  }
}
</style>