<template>
  <div class="support5">
    <div class="content">
      <div class="head">远程开机</div>
      <div class="text-head m2">1、判断是否支持远程开机</div>
      <div class="texts m1">重启电脑设备进入 BIOS 的电源管理菜单查看菜单是否有 Remote Wake Up 或 Wake on LAN 等类似选项，否则不支持远程开机。</div>
      <div class="text-head m2">2、进入 BIOS</div>
      <div class="texts m1">开机时反复按“DELETE“或 F2/F8/F12 等按键进入 BIOS 设置界面，具体按键可参考主板说明书或参考网络教程（百度经验）</div>
      <div class="text-head m2">3、开启唤醒功能</div>
      <div class="texts m1">通常到“Power Managment（电源管理）”下寻找如下列选项：</div>
      <div class="texts m1">"Boot on LAN";</div>
      <div class="texts m1">"Wake on LAN";</div>
      <div class="texts m1">"PME Event WakeUp";</div>
      <div class="texts m1">"Resume by MAC LAN";</div>
      <div class="texts m1">"Wake-Up by PCI card";</div>
      <div class="texts m1">"Wake Up On PCI PME";</div>
      <div class="texts m1">"Power On by PCI Card";</div>
      <div class="texts m1">"WakeUp by PME of PCI";</div>
      <div class="texts m1">"Power On By PCI Devices";</div>
      <div class="texts m1">"WakeUp by Onborad LAN";</div>
      <div class="texts m1">"Resume By PCI or PCI-E Ddevice"</div>
      <div class="texts m1">或类似的配置项，并可以启用它。</div>
      <div class="text-head m2">可视图形化的 UEFI BIOS，可参考下列方式设置：</div>
      <div class="texts m1">高级 > 高级电源管理（APM）> 开启 Resume By PCI or PCI-E Ddevice（由 pci/pcie 设备唤醒）选项。</div>
      <div class="text-head m2">系统设置</div>
      <div class="texts m1">右键点击开始菜单-设备管理器</div>
      <div class="texts m1">对网卡进行设置</div>
      <img :src="support_f1" alt="#" class="icon">
      <div class="texts m1">网卡电源设置</div>
      <img :src="support_f2" alt="#" class="icon">


    </div>
    <Foot></Foot>

  </div>
</template>

<script>
  import Foot from "@/components/Foot.vue";

  export default {
    name: 'Support6',
    components:{
      Foot
    },
    data:function () {
      return {
        support_f1:require("../assets/support_f1.png"),
        support_f2:require("../assets/support_f2.png"),

      }
    },


  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
  .bg{
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .support5{
    height: 100%;
    overflow-y: scroll;
    background-color: #FFFFFF;
    color: #666666;
    font-size: 18px;

    .content{
      position: relative;
      padding-top: 36px;
      padding-bottom: 100px;
      margin: 0 auto;
      min-width: 210px;
      font-size: 16px;
      max-width: 790px;
      min-width: 470px;

      .head{
        margin-top: 30px;
        font-size: 30px;
        color: #1A1A1A;
        font-weight: 500;
      }
      .text-head{
        font-size: 18px;
        color: #1A1A1A;
        font-weight: 700;
      }

      .texts{
        line-height: 26px;
      }

      .m1{
        margin-top: 10px;
      }
      .m2{
        margin-top: 15px;
      }

      .icon{
        margin-top: 20px;
        width: 100%;
      }





    }


  }
  .support5::-webkit-scrollbar {
    display: none;
  }
</style>
