<template>
  <div class="controled">
    <div class="guide1">
      <div class="guide-title">1. 准备工作</div>
      <div class="guide-text">1. 判断设备是否支持远程开机</div>
      <div class="guide-text ml">重启电脑设备进入BIOS的电源管理菜单</div>
      <div class="guide-text ml">
        查看菜单是否有Remote Wake Up或Wake on
        LAN等类似选项，否则不支持远程开机。
      </div>
      <div class="guide-text">2. 进入BIOS</div>
      <div class="guide-text ml">
        开机时反复按“DELTET”或“F2/F8/F12”等按键进入BIOS设置界面
      </div>
      <div class="guide-text ml">具体按键可参考主板说明书或参考网络教程。</div>
      <div class="guide-text">3. 开启唤醒功能</div>
      <div class="guide-text ml">
        通常到“Power
        Managment（电源管理）”下寻找如下列选项或类似的配置项，并可以启用它。
      </div>
      <div class="wram-wrap">
        <div class="guide-text">"Boot on LAN";</div>
        <div class="guide-text">"Wake on LAN";</div>
        <div class="guide-text">"PME Event WakeUp";</div>
        <div class="guide-text">"Resume by MAC LAN";</div>
        <div class="guide-text">"Wake-Up by PCI card";</div>
        <div class="guide-text">"Wake Up On PCI PME";</div>
        <div class="guide-text">"Power On by PCI Card";</div>
        <div class="guide-text">"WakeUp by PME of PCI";</div>
        <div class="guide-text">"Power On By PCI Devices";</div>
        <div class="guide-text">"WakeUp by Onborad LAN";</div>
        <div class="guide-text">"Resume By PCI or PCI-E Ddevice"</div>
      </div>
      <div class="guide-text ml">
        <b>可视化图形的UEFI BIOS，可参考下列设置方式：</b>
      </div>
      <div class="guide-text ml">
        高级 > 高级电源管理（APM）> 开启 Resume By PCI or PCI-E Ddevice（由
        pci/pcie 设备唤醒）选项。
      </div>
      <div class="guide-text">4. 系统设置</div>
      <div class="guide-text ml">
        右键点击开始菜单-设备管理器，对网卡进行设置。
      </div>
      <div class="twm-icon">
        <img :src="icon89" alt="" />
      </div>
      <div class="guide-text ml">网卡电源设置</div>
      <div class="twm-icon twm-icon4">
        <img :src="icon90" alt="" />
      </div>

      <div class="guide-title">2. 添加目标主机（被开机设备）</div>
      <div class="guide-text">添加方式1：</div>
      <div class="guide-text">
        通过高级功能“发现服务”或“手填添加”服务，添加了Windows3389服务后，在目标主机离线后，该服务按钮会更改为“远程开机”
      </div>
      <div class="guide-text mt">添加方式2：</div>
      <div class="guide-text">
        通过节点小宝工具箱“远程开机”工具添加目标主机，详见《工具箱-远程开机工具》
      </div>
      <div class="guide-title">3. 如何开机</div>
      <div class="guide-text">
        找到离线主机的远程服务卡片，点击“开机唤醒”即可发送开机指令
      </div>
      <div class="twm-icon">
        <img :src="icon91" alt="" />
      </div>
      <div class="guide-title">4. 远程开机失败</div>
      <div class="guide-text ml">
        下发指令后，需要一定时间完成开机过程，请稍作等待后查看设备在线状态
      </div>
      <div class="guide-text ml">
        被开机主机局域网内没有在线的节点小宝设备端设备
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "InstallControled",
  props: ["currentid"],
  data: function () {
    return {
      icon89: require("../../assets/help/icon89.png"),
      icon90: require("../../assets/help/icon90.png"),
      icon91: require("../../assets/help/icon91.png"),
    };
  },
  methods: {
    vipGuideClick: function () {
      this.$emit("vipGuideClick");
    },
    goClick: function (data) {
      let routeUrl = this.$router.resolve({
        path: data, // 这里的路径就可以正常的写，不需要添加_blank: true
      });
      window.open(routeUrl.href, "_blank");
    },
  },
};
</script>

<style scoped lang="less">
.controled {
  .guide1 {
    .guide-title {
      margin-bottom: 30px;
      margin-top: 30px;
      font-weight: 500;
      color: #222429;
      font-size: 24px;
    }
    .content {
      font-size: 16px;
      div {
        line-height: 30px;
      }
    }
    .line {
      margin: 20px 0;
      width: 100%;
      height: 1px;
      background-color: #ccced4;
    }
    .guide-text {
      font-size: 16px;
      color: #24292f;
      line-height: 28px;
      span {
        color: #3370ff;
        cursor: pointer;
      }
      a {
        text-decoration: none;
        color: #3370ff;
      }
      .go-text {
        text-decoration: underline;
        color: #24292f;
        cursor: pointer;
      }
    }
    .twm-icon {
      margin-top: 15px;
      margin-bottom: 15px;
      img {
        max-width: 100%;
      }
    }
    .twm-icon2 {
      width: 50%;
    }
    .twm-icon3 {
      margin: 0 auto;
    }
    .twm-icon4 {
      text-align: center;
    }

    .mt {
      margin-top: 40px;
    }
    .ml {
      margin-left: 20px;
    }
    .wram-wrap {
      margin-top: 10px;
      margin-bottom: 10px;
      padding: 16px;
      padding-left: 32px;
      width: 100%;
      border: 1px solid #bacefd;
      background-color: #f0f4ff;
      box-sizing: border-box;
      border-radius: 8px;
    }
  }
}
</style>