<template>
  <div class="controled">
    <div class="guide1" v-if="currentid == 10101">
      <div class="guide-title">1. 获取系统版本</div>
      <div class="guide-text">判断设备DSM系统版本号：DSM6/DSM7</div>
      <div class="guide-text">
        登录NAS控制台->控制面板->信息中心->查看“DSM版本号”
      </div>
      <div class="twm-icon">
        <img :src="icon92" alt="" />
      </div>
      <div class="twm-icon">
        <img :src="icon93" alt="" />
      </div>
      <div class="guide-title">2. 获取架构</div>
      <div class="guide-text">
        请查阅文档：<span @click="goClick('/qh/device/query')"
          >已支持的群晖x86/arm设备查询</span
        >
      </div>
      <div class="guide-title">3. 下载</div>
      <div class="guide-text">
        根据1.2步骤获取的DSM版本与架构，前往节点小宝官网下载对应的套件安装包
      </div>
      <div class="guide-text">
        下载地址：<a
          href="https://www.iepose.com/download?device=nas&brand=synology"
          target="_blank"
          >https://www.iepose.com/download?device=nas&brand=synology</a
        >
      </div>
      <div class="twm-icon">
        <img :src="icon94" alt="" />
      </div>
      <div class="guide-title">4. 安装</div>
      <div class="guide-text">a. 打开套件中心</div>
      <div class="guide-text">b. 右上角“手动安装”</div>
      <div class="twm-icon">
        <img :src="icon95" alt="" />
      </div>
      <div class="guide-text">c. 上传套件</div>
      <div class="twm-icon">
        <img :src="icon96" alt="" />
      </div>
      <div class="guide-text">d. 点击“应用”，等待安装完成</div>
      <div class="twm-icon">
        <img :src="icon97" alt="" />
      </div>
      <div class="guide-title">5. 获取绑定码</div>
      <div class="guide-text">启动节点小宝，将自动获取绑定码</div>
      <div class="twm-icon">
        <img :src="icon98" alt="" />
      </div>
    </div>
    <div class="guide1" v-if="currentid == 10102">
      <div class="guide-title">1. 获取系统版本</div>
      <div class="guide-text">判断设备DSM系统版本号：TOS4/TOS5</div>
      <div class="guide-text">
        TOS4：登录NAS控制台->控制面板->通用设置->更新与还原
      </div>
      <div class="guide-text">
        TOS5：登录NAS控制台->控制面板->通用设置->系统
      </div>
      <div class="twm-icon">
        <img :src="icon1" alt="" />
      </div>
      <div class="twm-icon">
        <img :src="icon2" alt="" />
      </div>
      <div class="guide-title">2. 获取架构</div>
      <div class="guide-text">
        请查阅文档：<span @click="goClick('/twm/device/query')"
          >已支持的铁威马x86/arm设备查询</span
        >
      </div>
      <div class="guide-title">3. 下载</div>
      <div class="guide-text">
        请查阅文档：根据1.2步骤获取的TOS版本与架构，前往节点小宝官网下载对应的安装包
      </div>
      <div class="guide-text">
        下载地址：<a
          href="https://www.iepose.com/download?device=nas&brand=treeamaster"
          target="_blank"
          >https://www.iepose.com/download?device=nas&brand=treeamaster</a
        >
      </div>
      <div class="twm-icon">
        <img :src="icon3" alt="" />
      </div>
      <div class="guide-title">4. 安装</div>
      <div class="guide-text">1. 打开“应用中心”</div>
      <div class="guide-text">2. 点击“设置”</div>
      <div class="twm-icon">
        <img :src="icon4" alt="" />
      </div>
      <div class="guide-text">
        3. 选择已下载的安装包，点击“确认”，等待安装完成
      </div>
      <div class="twm-icon">
        <img :src="icon5" alt="" />
      </div>
      <div class="guide-title">5. 获取绑定码</div>
      <div class="guide-text">启动节点小宝，将自动获取绑定码</div>
      <div class="twm-icon">
        <img :src="icon6" alt="" />
      </div>
    </div>
    <div class="guide1" v-if="currentid == 10103">
      <div class="guide-title">1. 获取架构</div>
      <div class="guide-text">
        请查阅文档：<span @click="goClick('/wlt/device/query')"
          >威联通x86/arm设备查询
        </span>
      </div>
      <div class="guide-title">2. 下载</div>
      <div class="guide-text">
        根据1.步骤获取的架构，前往节点小宝官网下载对应的安装包
      </div>
      <div class="guide-text">
        下载地址：<a
          href="https://www.iepose.com/download?device=nas&brand=treeamaster"
          target="_blank"
          >https://www.iepose.com/download?device=nas&brand=treeamaster</a
        >
      </div>
      <div class="twm-icon">
        <img :src="icon7" alt="" />
      </div>
      <div class="guide-title">3. 安装</div>
      <div class="guide-text">a. 打开“App Center”</div>
      <div class="guide-text">b. 选择“手动安装”</div>
      <div class="twm-icon">
        <img :src="icon8" alt="" />
      </div>
      <div class="guide-text">c. 点击“安装”，等待安装完成</div>
      <div class="twm-icon">
        <img :src="icon9" alt="" />
      </div>
      <div class="guide-title">4. 获取绑定码</div>
      <div class="guide-text">启动节点小宝，将自动获取绑定码</div>
      <div class="twm-icon">
        <img :src="icon10" alt="" />
      </div>
    </div>
    <div class="guide1" v-if="currentid == 10104">
      <div class="guide-title">1. 安装</div>
      <div class="guide-text">打开“联想个人云App”，在首页依次点击：</div>
      <div class="guide-text">
        首页>工具>第三方工具>节点小宝,启动后即可获取绑定码
      </div>
      <div class="guide-title">2. 获取绑定码</div>
      <div class="guide-text">启动“节点小宝”，点击登录，获取绑定码</div>
      <div class="twm-icon">
        <img :src="icon11" alt="" />
      </div>
      <div class="twm-icon">
        <img :src="icon12" alt="" />
      </div>
    </div>
    <div class="guide1" v-if="currentid == 10105">
      <div class="guide-title">1. 安装</div>
      <div class="guide-text">打开“海康智存App”，在首页依次点击：</div>
      <div class="guide-text">智能生活->更多->三方服务->添加节点小宝</div>
      <div class="guide-title">2. 获取绑定码</div>
      <div class="guide-text">启动“节点小宝”，点击登录，获取绑定码</div>
      <div class="twm-icon">
        <img :src="icon11" alt="" />
      </div>
      <div class="twm-icon">
        <img :src="icon12" alt="" />
      </div>
    </div>
    <div class="guide1" v-if="currentid == 10106">
      <div class="guide-title">1. 下载</div>
      <div class="guide-text">前往节点小宝官网下载Windows设备端</div>
      <div class="guide-text">
        官网地址：<a
          href="https://www.iepose.com/download?device=windows"
          target="_blank"
          >https://www.iepose.com/download?device=windows</a
        >
      </div>
      <div class="twm-icon">
        <img :src="icon13" alt="" />
      </div>
      <div class="guide-title">2. 启动</div>
      <div class="guide-text">a. 解压“节点小宝设备端”</div>
      <div class="guide-text">b. 双击运行“节点小宝设备端”</div>
      <div class="line"></div>
      <div class="guide-text">若启动失败：</div>
      <div class="guide-text">
        若出现“缺少运行库”等错误提示导致无法启动时，请下载并安装如下“运行库”，然后再次尝试启动。
      </div>
      <div class="guide-text">
        运行库下载：<a
          href="https://cdn.ionewu.com/upgrade/d/windows_devlibs.zip"
          target="_blank"
          >https://cdn.ionewu.com/upgrade/d/windows_devlibs.zip</a
        >
      </div>
      <div class="guide-title">3. 获取绑定码</div>
      <div class="guide-text">启动节点小宝，将自动获取绑定码</div>
      <div class="twm-icon">
        <img :src="icon14" alt="" />
      </div>
    </div>
    <div class="guide1" v-if="currentid == 10107">
      <div class="guide-title">1. 下载</div>
      <div class="guide-text">前往节点小宝官网下载Mac设备端</div>
      <div class="guide-text">
        官网地址：<a
          href="https://www.iepose.com/download?device=mac"
          target="_blank"
          >https://www.iepose.com/download?device=mac</a
        >
      </div>
      <div class="twm-icon">
        <img :src="icon15" alt="" />
      </div>
      <div class="guide-title">2. 启动</div>
      <div class="guide-text">1. 启动“节点小宝设备端.dmg”程序</div>
      <div class="guide-text">2. 拖拽“节点小宝设备端”到“应用程序”文件夹</div>
      <div class="twm-icon">
        <img :src="icon16" alt="" />
      </div>
      <div class="guide-text">3. 启动节点小宝设备端，点击“打开”</div>
      <div class="twm-icon">
        <img :src="icon17" alt="" />
      </div>
      <div class="line"></div>
      <div class="guide-text">
        若无法打开，出现错误提示时，请参照下方指引进行设置：
      </div>
      <div class="guide-text"><b>macOS_12及以下：</b></div>
      <div class="twm-icon">
        <img :src="icon18" alt="" />
      </div>
      <div class="guide-text">
        1. 点击弹窗“好”以后，打开“系统偏好设置”->安全性与隐私
      </div>
      <div class="twm-icon">
        <img :src="icon19" alt="" />
      </div>
      <div class="guide-text">
        2.
        根据自身需求选择对应的打开方案（选择“任何来源”方案后需要从程序坞启动“节点小宝”）
      </div>
      <div class="twm-icon">
        <img :src="icon20" alt="" />
      </div>
      <div class="line"></div>
      <div class="guide-text"><b>macOS_13：</b></div>
      <div class="twm-icon">
        <img :src="icon21" alt="" />
      </div>
      <div class="guide-text">1. 打开系统设置->隐私与安全性->下拉到底部</div>
      <div class="guide-text">
        根据自身需求选择对应的打开方案（选择“任何来源”方案后需要从程序坞启动“节点小宝”）
      </div>
      <div class="twm-icon">
        <img :src="icon22" alt="" />
      </div>
      <div class="guide-title">3. 获取绑定码</div>
      <div class="guide-text">启动节点小宝，将自动获取绑定码</div>
      <div class="twm-icon">
        <img :src="icon23" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "InstallControled",
  props: ["currentid"],
  data: function () {
    return {
      icon1: require("../../assets/help/icon1.png"),
      icon2: require("../../assets/help/icon2.png"),
      icon3: require("../../assets/help/icon3.png"),
      icon4: require("../../assets/help/icon4.png"),
      icon5: require("../../assets/help/icon5.png"),
      icon6: require("../../assets/help/icon6.png"),
      icon7: require("../../assets/help/icon7.png"),
      icon8: require("../../assets/help/icon8.png"),
      icon9: require("../../assets/help/icon9.png"),
      icon10: require("../../assets/help/icon10.png"),
      icon11: require("../../assets/help/icon11.png"),
      icon12: require("../../assets/help/icon12.png"),
      icon13: require("../../assets/help/icon13.png"),
      icon14: require("../../assets/help/icon14.png"),
      icon15: require("../../assets/help/icon15.png"),
      icon16: require("../../assets/help/icon16.png"),
      icon17: require("../../assets/help/icon17.png"),
      icon18: require("../../assets/help/icon18.png"),
      icon19: require("../../assets/help/icon19.png"),
      icon20: require("../../assets/help/icon20.png"),
      icon21: require("../../assets/help/icon21.png"),
      icon22: require("../../assets/help/icon22.png"),
      icon23: require("../../assets/help/icon23.png"),
      icon92: require("../../assets/help/icon92.png"),
      icon93: require("../../assets/help/icon93.png"),
      icon94: require("../../assets/help/icon94.png"),
      icon95: require("../../assets/help/icon95.png"),
      icon96: require("../../assets/help/icon96.png"),
      icon97: require("../../assets/help/icon97.png"),
      icon98: require("../../assets/help/icon98.png"),
    };
  },
  methods: {
    goClick: function (data) {
      let routeUrl = this.$router.resolve({
        path: data, // 这里的路径就可以正常的写，不需要添加_blank: true
      });
      window.open(routeUrl.href, "_blank");
    },
  },
};
</script>

<style scoped lang="less">
.controled {
  .guide1 {
    .guide-title {
      margin-bottom: 30px;
      margin-top: 30px;
      font-weight: 500;
      color: #222429;
      font-size: 24px;
    }
    .content {
      font-size: 16px;
      div {
        line-height: 30px;
      }
    }
    .line {
      margin: 20px 0;
      width: 100%;
      height: 1px;
      background-color: #ccced4;
    }
    .guide-text {
      font-size: 16px;
      color: #24292f;
      line-height: 28px;
      span {
        color: #3370ff;
        cursor: pointer;
      }
      a {
        text-decoration: none;
        color: #3370ff;
      }
    }
    .twm-icon {
      margin-top: 15px;
      margin-bottom: 15px;
      img {
        max-width: 100%;
      }
    }
  }
}
</style>