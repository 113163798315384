import axios from "axios";

let dpis = "https://dpis.ionewu.com"
const sendDpisGet = function (path, data, cb) {
    axios.get(dpis + path, {
        params: data
    }).then((response) => {
        cb ? cb(null, response.data) : null;
    }).catch((error) => {
        cb ? cb(error, null) : null;
    });
}

let api = process.env.NODE_ENV === 'development' ? "https://c.ionewu.com" : "https://c.ionewu.com";
const sendApi = function (path, data, cb) {
    axios.post(api + path, data).then((response) => {
        cb ? cb(null, response.data) : null;
    }).catch((error) => {
        cb ? cb(error, null) : null;
    });
}
const sendApiGet = function (path, data, cb) {
    axios.get(api + path, {
        params: data
    }).then((response) => {
        cb ? cb(null, response.data) : null;
    }).catch((error) => {
        cb ? cb(error, null) : null;
    });
}

let kdts = process.env.NODE_ENV === 'development' ? "https://kdts.ionewu.com" : "https://kdts.ionewu.com";
const kdtsPost = function (path, data, cb) {
    axios.post(kdts + path, data).then((response) => {
        cb ? cb(null, response.data) : null;
    }).catch((error) => {
        cb ? cb(error, null) : null;
    });
}

let c = process.env.NODE_ENV === 'development' ? "https://c.ionewu.com" : "https://c.ionewu.com";
const cPost = function (path, data, cb) {
    axios.post(c + path, data).then((response) => {
        cb ? cb(null, response.data) : null;
    }).catch((error) => {
        cb ? cb(error, null) : null;
    });
}
const cGet = function (path, data, cb) {
    data.cache_ver = Math.random();
    axios.get(c + path, {
        params: data
    }).then((response) => {
        cb ? cb(null, response.data) : null;
    }).catch((error) => {
        console.log(c + path);
        console.log(error);
        cb ? cb(error, null) : null;
    });
}

let statApi = process.env.NODE_ENV === 'development' ? "https://stat.ionewu.com" : "https://stat.ionewu.com";
const statPost = function (path, data, cb) {
    axios.post(statApi + path, data).then((response) => {
        cb ? cb(null, response.data) : null;
    }).catch((error) => {
        cb ? cb(error, null) : null;
    });
}


let tan = process.env.NODE_ENV === 'development' ? "https://tan.ionewu.com" : "https://tan.ionewu.com";
const tanPost = function (path, data, cb) {
    axios.post(tan + path, data).then((response) => {
        cb ? cb(null, response.data) : null;
    }).catch((error) => {
        cb ? cb(error, null) : null;
    });
}
const tanGet = function (path, data, cb) {
    data.cache_ver = Math.random();
    axios.get(tan + path, {
        params: data
    }).then((response) => {
        cb ? cb(null, response.data) : null;
    }).catch((error) => {
        console.log(tan + path);
        console.log(error);
        cb ? cb(error, null) : null;
    });
}

let jdisApi = process.env.NODE_ENV === 'development' ? "https://jdis.ionewu.com" : "https://jdis.ionewu.com";
const jdisGet = function (path, data, cb) {
    data.cache_ver = Math.random();
    axios.get(jdisApi + path, {
        params: data
    }).then((response) => {
        cb ? cb(null, response.data) : null;
    }).catch((error) => {
        console.log(jdisApi + path);
        console.log(error);
        cb ? cb(error, null) : null;
    });
}
const jdisPost = function (path, data, cb) {
    axios.post(jdisApi + path, data).then((response) => {
        cb ? cb(null, response.data) : null;
    }).catch((error) => {
        console.log(jdisApi + path);
        console.log(error);
        cb ? cb(error, null) : null;
    });
}


export default {
    downloadPage:{
        windowsVersion:function (cb) {
            let data = {};
            tanGet("/api/jdxb/download/version/windows", data, cb);
        },
        macVersion:function (cb) {
            let data = {};
            tanGet("/api/jdxb/download/version/mac", data, cb);
        }
    },
    updateHttps: function () {
        if (process.env.NODE_ENV != 'development') {
            tan = tan.replace("http:", "https:");
            dpis = dpis.replace("http:", "https:");
            api = api.replace("http:", "https:");
            kdts = kdts.replace("http:", "https:");
            c = c.replace("http:", "https:");
            statApi = statApi.replace("http:", "https:");
            jdisApi = jdisApi.replace("http:", "https:");
        }
    },
    stat: {
        alive: function (data, cb) {
            statPost("/run/rpt/alive", data, cb);
        }
    },
    user: {
        login: function (data, cb) {
            cPost("/wx/account/auth", data, cb);
        },
        log: function (data) {
            tanPost("/api/website/log/user", data, null);
        },
        devices: function (data, cb) {
            sendApi("/jdb/book/query", data, cb);
        },
        activeCode: {
            get: function (data, cb) {
                sendDpisGet("/dp/box/activecode", data, cb);
            },
        },
        imagebackup: {
            log: function (data, cb) {
                tanPost("/api/jdxb/log/imagebackup", data, cb);
            },
            list: function (data, cb) {
                tanGet("/api/jdxb/log/imagebackup", data, cb);
            },
            getPath: function (data, cb) {
                tanGet("/api/jdxb/device/imagebackup/path", data, cb);
            },
            setPath: function (data, cb) {
                tanPost("/api/jdxb/device/imagebackup/path", data, cb);
            }
        },
        phoneLogin: function (data, cb) {
            cPost("/phone/login2", data, cb);
        },
        sendCode: function (data, cb) {
            cGet("/phone/captcha2", data, cb);
        },
        unbind: function (data, cb) {
            cGet("/air/jdb/clean", data, cb);
        },
        bindget: function (data, cb) {
            jdisGet("/jdis/bindget", data, cb);
        },
    }
}