<template>
  <div class="controled">
    <div class="guide1">
      <div class="guide-title">1. 添加远程路由</div>
      <div class="guide-text">
        节点小宝绑定设备端设备后，会自动添加设备本机局域网的远程路由服务到基础服务列表
      </div>
      <div class="twm-icon">
        <img :src="icon65" alt="" />
      </div>
      <div class="guide-title">2. 访问远程路由服务</div>
      <div class="guide-text">
        点击“路由管理”按钮，待设备建立连接完成后将自动拉起网页或打开新标签页，并自动打开路由登录界面。
      </div>
      <div class="twm-icon">
        <img :src="icon66" alt="" />
      </div>
      <div class="twm-icon">
        <img :src="icon67" alt="" />
      </div>
      <div class="guide-title">3. 没有NAS桌面服务？</div>
      <div class="guide-text">1. 请确认NAS在局域网内可通过IP:prot成功访问</div>
      <div class="guide-text">
        2.
        部分厂商路由器可能不支持远程访问，如遇该情况，可反馈给客服，我们将排期适配。
      </div>
      <div class="guide-text">
        若上述配置仍无法自动发现远程路由器服务，请使用节点小宝高级功能手动添加远程路由器服务
      </div>
      <div class="guide-text">
        <a class="go-text" @click="vipGuideClick">点击查看如何使用高级功能</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "InstallControled",
  props: ["currentid"],
  data: function () {
    return {
      icon65: require("../../assets/help/icon65.png"),
      icon66: require("../../assets/help/icon66.png"),
      icon67: require("../../assets/help/icon67.png"),
    };
  },
  methods: {
    vipGuideClick: function () {
      this.$emit("vipGuideClick");
    },
    goClick: function (data) {
      let routeUrl = this.$router.resolve({
        path: data, // 这里的路径就可以正常的写，不需要添加_blank: true
      });
      window.open(routeUrl.href, "_blank");
    },
  },
};
</script>

<style scoped lang="less">
.controled {
  .guide1 {
    .guide-title {
      margin-bottom: 30px;
      margin-top: 30px;
      font-weight: 500;
      color: #222429;
      font-size: 24px;
    }
    .content {
      font-size: 16px;
      div {
        line-height: 30px;
      }
    }
    .line {
      margin: 20px 0;
      width: 100%;
      height: 1px;
      background-color: #ccced4;
    }
    .guide-text {
      font-size: 16px;
      color: #24292f;
      line-height: 28px;
      span {
        color: #3370ff;
        cursor: pointer;
      }
      a {
        text-decoration: none;
        color: #3370ff;
      }
      .go-text {
        text-decoration: underline;
        color: #24292f;
        cursor: pointer;
      }
    }
    .twm-icon {
      margin-top: 15px;
      margin-bottom: 15px;
      img {
        max-width: 100%;
      }
    }
    .twm-icon2 {
      width: 50%;
    }
    .twm-icon3 {
      margin: 0 auto;
    }
    .mt {
      margin-top: 40px;
    }
    .wram-wrap {
      padding: 16px;
      padding-left: 32px;
      width: 100%;
      border: 1px solid #bacefd;
      background-color: #fef1f1;
      box-sizing: border-box;
      border-radius: 8px;
    }
  }
}
</style>