<template>
    <div class="controled">
        <div class="guide1">
            <div class="guide-text">1. 安装jellyfin服务</div>
            <div class="guide-text">2. 设置jellyfin的服务端口为默认的8096端口</div>
            <div class="guide-text ml">设置->控制台->联网，设置服务端口为8096</div>
            <div class="twm-icon">
                <img :src="icon114" alt="">
            </div>
            <div class="twm-icon">
                <img :src="icon115" alt="">
            </div>
            <div class="twm-icon">
                <img :src="icon116" alt="">
            </div>
            <div class="guide-text">3. 稍等片刻，小宝会自动添加jellyfin服务到列表</div>
            <div class="twm-icon">
                <img :src="icon117" alt="">
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "InstallControled",
        props:['currentid'],
        data:function () {
            return {
                icon114: require("../../assets/help/icon114.png"),
                icon115: require("../../assets/help/icon115.png"),
                icon116: require("../../assets/help/icon116.png"),
                icon117: require("../../assets/help/icon117.png"),
            }
        },
        methods:{
            vipGuideClick:function(){
                this.$emit("vipGuideClick");
            },
            goClick:function (data) {
                let routeUrl = this.$router.resolve({
                    path: data// 这里的路径就可以正常的写，不需要添加_blank: true
                })
                window.open(routeUrl.href, '_blank');
            }
        }
    }
</script>

<style scoped lang="less">
.controled{
    .guide1{
        .guide-title{
            margin-bottom: 30PX;
            margin-top: 30PX;
            font-weight: 500;
            color: #222429;
            font-size: 24PX;
        }
        .content{
            font-size: 16PX;
            div{
                line-height: 30PX;
            }
        }
        .line{
            margin: 20PX 0;
            width: 100%;
            height: 1PX;
            background-color: #ccced4;
        }
        .guide-text{
            font-size: 16PX;
            color: #24292f;
            line-height: 28PX;
            span{
                color: #3370ff;
                cursor: pointer;
            }
            a{
                text-decoration: none;
                color: #3370ff;
            }
            .green{
                color: #2EA121;
                font-weight: 900;
            }
            .go-text{
                text-decoration: underline;
                color:#24292f;
                cursor: pointer;
            }
        }
        .twm-icon{
            margin-top: 15PX;
            margin-bottom: 15PX;
            img{
                max-width: 100%;
            }
        }
        .twm-icon2{
            width:50%;
        }
        .twm-icon3{
            margin: 0 auto;
        }
        .mt{
            margin-top: 40PX;
        }
        .ml{
            margin-left: 20PX;
        }
        .warm-wrap{
            padding: 16PX;
            padding-left: 3PX;
            width: 100%;
            border: 1PX solid #bacefd;
            background-color: #f0f4ff;
            box-sizing: border-box;
            border-radius: 8PX;
            .warms{
                padding-left: 40PX;
            }
        }
    }
}
</style>