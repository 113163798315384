<template>
  <div class="controled">
    <div class="guide1">
      <div class="guide-text">1. 获取服务</div>
      <div class="guide-text">
        前往Synology套件中心安装<span class="green">Synology Photos</span>
      </div>
      <div class="twm-icon">
        <img :src="icon60" alt="" />
      </div>
      <div class="guide-text">2. 开启服务</div>
      <div class="guide-text">
        前往 控制面板->登录门户->应用程序->开启http服务为默认端口即可
      </div>
      <div class="twm-icon">
        <img :src="icon61" alt="" />
      </div>
      <div class="twm-icon">
        <img :src="icon62" alt="" />
      </div>
      <div class="guide-text">3. 访问服务</div>
      <div class="guide-text">
        通过节点小宝客户端），点击“远程照片”即可一键直达
      </div>
      <div class="twm-icon">
        <img :src="icon63" alt="" />
      </div>
      <div class="twm-icon">
        <img :src="icon64" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "InstallControled",
  props: ["currentid"],
  data: function () {
    return {
      icon60: require("../../assets/help/icon60.png"),
      icon61: require("../../assets/help/icon61.png"),
      icon62: require("../../assets/help/icon62.png"),
      icon63: require("../../assets/help/icon63.png"),
      icon64: require("../../assets/help/icon64.png"),
    };
  },
  methods: {
    vipGuideClick: function () {
      this.$emit("vipGuideClick");
    },
    goClick: function (data) {
      let routeUrl = this.$router.resolve({
        path: data, // 这里的路径就可以正常的写，不需要添加_blank: true
      });
      window.open(routeUrl.href, "_blank");
    },
  },
};
</script>

<style scoped lang="less">
.controled {
  .guide1 {
    .guide-title {
      margin-bottom: 30px;
      margin-top: 30px;
      font-weight: 500;
      color: #222429;
      font-size: 24px;
    }
    .content {
      font-size: 16px;
      div {
        line-height: 30px;
      }
    }
    .line {
      margin: 20px 0;
      width: 100%;
      height: 1px;
      background-color: #ccced4;
    }
    .guide-text {
      font-size: 16px;
      color: #24292f;
      line-height: 28px;
      span {
        color: #3370ff;
        cursor: pointer;
      }
      a {
        text-decoration: none;
        color: #3370ff;
      }
      .green {
        color: #2ea121;
        font-weight: 900;
      }
      .go-text {
        text-decoration: underline;
        color: #24292f;
        cursor: pointer;
      }
    }
    .twm-icon {
      margin-top: 15px;
      margin-bottom: 15px;
      img {
        max-width: 100%;
      }
    }
    .twm-icon2 {
      width: 50%;
    }
    .twm-icon3 {
      margin: 0 auto;
    }
    .mt {
      margin-top: 40px;
    }
    .ml {
      margin-left: 20px;
    }
    .warm-wrap {
      padding: 16px;
      padding-left: 3px;
      width: 100%;
      border: 1px solid #bacefd;
      background-color: #f0f4ff;
      box-sizing: border-box;
      border-radius: 8px;
      .warms {
        padding-left: 40px;
      }
    }
  }
}
</style>