<template>
    <div class="buy">
        <div class="buy-c">
            <div class="title">
                <span class="t1">享受极速</span>
            </div>
            <div class="b-item">
                <div class="t1 t2">免费版</div>
                <div class="t3">使用异地组网和内网穿透基础功能</div>
                <div class="o1" @click="vipClick"><div class="icon"></div></div>
                <a class="btn o-btn" target="_blank" href="https://iepose.com/jdxb/pc/download?device=nas&brand=synology">立即下载</a>
                <div class="t8 t9">不限绑定设备</div>
                <div class="t8">广联通道</div>
                <div class="t8">点对点组网</div>
                <div class="t8">不限速组网</div>
                <div class="t8">内网穿透</div>
                <div class="t8">转发流量20GB/月</div>
            </div>
            <div class="b-item">
                <div class="pro">热销推荐</div>
                <div class="t1"></div>
                <div class="t3">满足专业人士高质量需求</div>
                <div class="t4">
                    <div class="t5">¥</div> <div class="t6">99</div> <div class="t7">/年</div>
                </div>
                <a class="btn btn1" target="_blank" href="https://iepose.com/jdxb/pc/download?device=nas&brand=synology">立即下载</a>
                <div class="t8 t9">不限绑定设备</div>
                <div class="t8">广联通道</div>
                <div class="t8">点对点组网</div>
                <div class="t8">不限速组网</div>
                <div class="t8">全网映射</div>
                <div class="t8">内网穿透</div>
                <div class="t8">自定义组网虚拟IP</div>
                <div class="t8">高速转发通道</div>
                <div class="t8">转发流量100GB/月</div>
            </div>
            <div class="b-item">
                <div class="qrcode" v-if="showQrcode"></div>
                <div class="t1 t2">私有部署</div>
                <div class="t3">适合用于定制化需求的用户</div>
                <div class="p1">0755-86607260</div>
                <div class="btn p-btn" @mouseleave="mleave" @mouseover="mover">联系我们</div>
                <div class="t8 t9">绑定设备数量按需定制</div>
                <div class="t8">广联通道</div>
                <div class="t8">点对点组网</div>
                <div class="t8">不限速组网</div>
                <div class="t8">全网映射</div>
                <div class="t8">内网穿透</div>
                <div class="t8">自定义组网虚拟IP</div>
                <div class="t8">定制域名</div>
                <div class="t8">针对性解决方案</div>
            </div>
        </div>
        <div class="buy-c2">
            <div class="buy-c3">
                <div class="title2">更多功能对比</div>
                <div class="title3">
                    <div class="title3a"></div>
                    <div class="title3b">免费版</div>
                    <div class="title3c">专业版</div>
                </div>
                <div class="buy-table">
                    <div class="tr1">
                        <div class="col1"></div>
                    </div>
                    <div class="tr3">
                        <div class="col1 col-head">基础权益</div>
                    </div>
                    <div class="tr2">
                        <div class="col1">绑定设备</div>
                        <div class="col2">不限</div>
                        <div class="col3">不限</div>
                    </div>
                    <div class="tr2">
                        <div class="col1">广联通道(支持IPv6）</div>
                        <div class="col2">支持</div>
                        <div class="col3">支持</div>
                    </div>
                    <div class="tr1">
                        <div class="col1"></div>
                    </div>
                    <div class="tr3">
                        <div class="col1 col-head">异地组网</div>
                    </div>
                    <div class="tr2">
                        <div class="col1">点对点组网</div>
                        <div class="col2">支持</div>
                        <div class="col3">支持</div>
                    </div>
                    <div class="tr2">
                        <div class="col1">组网虚拟 IP</div>
                        <div class="col2">支持</div>
                        <div class="col3">支持</div>
                    </div>
                    <div class="tr2">
                        <div class="col1">全网映射</div>
                        <div class="col2">1</div>
                        <div class="col3">不限</div>
                    </div>
                    <div class="tr2">
                        <div class="col1">组网转发速率</div>
                        <div class="col2">4</div>
                        <div class="col3">12</div>
                    </div>
                    <div class="tr1">
                        <div class="col1"></div>
                    </div>
                    <div class="tr3">
                        <div class="col1 col-head">内网穿透</div>
                    </div>
                    <div class="tr2">
                        <div class="col1">穿透数量</div>
                        <div class="col2">4</div>
                        <div class="col3">10</div>
                    </div>
                    <div class="tr2">
                        <div class="col1">转发速率（Mbps)</div>
                        <div class="col2">4</div>
                        <div class="col3">12</div>
                    </div>
                    <div class="tr2">
                        <div class="col1">每月流量（GB)</div>
                        <div class="col2">20</div>
                        <div class="col3">100</div>
                    </div>
                    <div class="tr2">
                        <div class="col1">转发通道</div>
                        <div class="col2">共享通道</div>
                        <div class="col3">VIP共享通道</div>
                    </div>
                    <div class="tr1">
                        <div class="col1"></div>
                    </div>
                    <div class="tr3">
                        <div class="col1 col-head">功能权益</div>
                    </div>
                    <div class="tr2">
                        <div class="col1">相册备份</div>
                        <div class="col2">单通道备份</div>
                        <div class="col3">多通道备份</div>
                    </div>
                    <div class="tr2">
                        <div class="col1">远程开机</div>
                        <div class="col2">支持（限次）</div>
                        <div class="col3">支持</div>
                    </div>
                    <div class="tr3">
                        <div class="col1"></div>
                    </div>
                </div>
            </div>

        </div>
        <Foot></Foot>
    </div>
</template>

<script>
    import Foot from "@/components/Foot.vue";

    export default {
        name: "Buy",
        data:function (){
            return {
                showQrcode:false
            }
        },
        components: {
            Foot,
        },
        methods:{
            vipClick:function(){
                window.open("https://www.iepose.com/landing/?chanid=gw",'_blank')
            },
            mleave:function () {
                this.showQrcode = false;
            },
            mover:function () {
                this.showQrcode = true;
            }
        }
    }
</script>

<style scoped lang="less">
.buy{
    background-color: #FFFFFF;
    .buy-c{
        padding-top: 64px;
        width: 100%;
        height: 1400px;
        text-align: center;
        background:linear-gradient(rgba(255, 255, 255, 0) 75%, rgb(255, 255, 255) 100%),  linear-gradient(90deg, rgba(236, 236, 255, 0.7) 0%, rgb(241, 237, 255) 49%, rgba(255, 244, 237, 0.7) 100%);
        .title{
            .t1{
                display: inline-block;
                padding-right: 196px;
                color: #17171B;
                font-size: 48px;
                font-weight: bold;
                line-height: 80px;
                height: 70px;
                background-image: url("../assets/v4/b1.svg");
                background-size: 192px 70px;
                background-repeat: no-repeat;
                background-position: right center;
            }

        }
        .b-item{
            position: relative;
            margin: 0 20px;
            margin-top: 54px;
            display: inline-block;
            width: 400px;
            height: 700px;
            border: 2px solid #FFFFFF;
            background: rgba(255, 255, 255, 0.6);
            box-sizing: border-box;
            border-radius: 20px;
            text-align: center;
            vertical-align: top;
            .pro{
                position: absolute;
                top: -2px;
                left: -2px;
                width: 110px;
                height: 44px;
                border-radius: 20px 0px 20px 0px;
                background: linear-gradient(270deg, #FF5652 0%, #FF741D 100%);
                font-size: 18px;
                font-weight: 500;
                color: #FFFFFF;
                line-height: 44px;
            }
            .qrcode{
                position: absolute;
                top: 270px;
                left: 140px;
                width: 120px;
                height: 120px;
                background-image: url("../assets/v4/home44.png");
                background-size: 100% 100%;
                background-repeat: no-repeat;
            }
            .t1{
                display: inline-block;
                margin-top: 50px;
                width: 96px;
                height: 35px;
                line-height: 35px;
                background-image: url("../assets/v4/b2.svg");
                background-size: 100% 100%;
                background-repeat: no-repeat;
                font-size: 24px;
                font-weight: bold;
                color: #17171B;
            }
            .t2{
                background: none;
            }
            .t3{
                margin-top: 10px;
                font-size: 16px;
                line-height: 20px;
                color: #57576C;
            }
            .t4{
                margin-top: 16px;
                color: #17171B;
                .t5,.t6,.t7{
                    display: inline-block;
                }
                .t5{
                    font-weight: 500;
                    font-size: 20px;
                }
                .t6{
                    margin-left: 4px;
                    font-size: 48px;
                    font-weight: bold;
                    line-height: 56px;
                }
                .t7{
                    margin-left: 4px;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 20px;
                }
            }
            .btn{
                display: inline-block;
                margin: 0 auto;
                margin-top: 20px;
                width: 320px;
                height: 44px;
                line-height: 44px;
                border-radius: 6px;
                box-sizing: border-box;
                border: 1px solid #4E4CFF;
                font-size: 16px;
                font-weight: 500;
                color: #4E4CFF;
                cursor: pointer;
                text-decoration: none;
            }
            .btn:hover{
                background: #4E4CFF;
                color: #FFFFFF;
            }
            .btn1{
                background: #4E4CFF;
                color: #FFFFFF;
            }
            .btn1:hover{
                opacity: 0.9;
            }
            .t8{
                margin-left: 40px;
                margin-top: 20px;
                padding-left: 30px;
                text-align: left;
                font-size: 16px;
                color: #17171B;
                line-height: 22px;
                background-image: url("../assets/v4/b3.svg");
                background-size: 20px 20px;
                background-repeat: no-repeat;
                background-position: left center;
            }
            .t9{
                margin-top: 38px;
            }
            .o1{
                margin: 0 auto;
                margin-top: 20px;
                padding-left: 2px;
                width: 248px;
                height: 34px;
                border-radius: 4px;
                background: #FFE9DB;
                font-size: 16px;
                font-weight: 500;
                overflow: hidden;
                cursor: pointer;
                .icon{
                    margin: 0 auto;
                    margin-top: 5px;
                    width: 227px;
                    height: 24px;
                    background-image: url("../assets/v4/b7.svg");
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                }
            }
            .o-btn{
                margin-top: 40px;
            }
            .p1{
                margin-top: 20px;
                line-height: 38px;
                font-size: 32px;
                font-weight: bold;
                color: #17171B;
            }
            .p-btn{
                margin-top: 38px;
            }
        }
    }
    .buy-c2{
        position: relative;
        height: 720px;
        .buy-c3{
            position: absolute;
            top: -408px;
            left: 50%;
            transform: translate(-50%,0);
            .title2{
                width: 1280px;
                font-size: 40px;
                font-weight: bold;
                line-height: 58px;
                color: #17171B;
                text-align: center;
            }
            .title3{
                margin-top: 50px;
                font-size: 20px;
                font-weight: 500;
                color: #1D2129;
                .title3a{
                    padding-left: 32px;
                    width: 240px;
                    height: 100%;
                    box-sizing: border-box;
                }

                .title3a,.title3b,.title3c{
                    vertical-align: top;
                    display: inline-block;
                    text-align: center;
                    line-height: 30px;
                }
                .title3b{
                    position: relative;
                    padding-left: 246px;
                    width: 168px;
                }
                .title3c{
                    position: relative;
                    padding-left: 212px;
                    width: 168px;
                }
                .title3b::before{
                    position: absolute;
                    top: 0;
                    right: 128px;
                    content: "";
                    width: 30px;
                    height: 30px;
                    background-image: url("../assets/v4/b4.svg");
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                }
                .title3c::before{
                    position: absolute;
                    top: 0;
                    right: 128px;
                    content: "";
                    width: 30px;
                    height: 30px;
                    background-image: url("../assets/v4/b5.svg");
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                }

            }
            .buy-table{
                margin-top: 24px;
                width: 1280px;
                height: 860px;
                border-radius: 10px;
                background: #ffffff;
                box-sizing: border-box;
                border: 1px solid #EAEAFA;
                overflow: hidden;
                .tr1{
                    height: 14px;
                    .col1{
                        width: 240px;
                        background: #F6F8FF;
                        height: 14px;
                    }

                }
                .tr2{
                    height: 48px;
                    .col1{
                        display: inline-block;
                        padding-left: 32px;
                        width: 240px;
                        background: #F6F8FF;
                        height: 100%;
                        line-height: 48px;
                        font-size: 16px;
                        color: #17171B;
                        box-sizing: border-box;
                        vertical-align: top;

                    }

                    .col2,.col3{
                        display: inline-block;
                        text-align: center;
                        height: 48px;
                        font-size: 16px;
                        line-height: 48px;
                        vertical-align: top;
                    }
                    .col2{
                        padding-left: 246px;
                        width: 168px;
                    }
                    .col3{
                        padding-left: 212px;
                        width: 168px;
                        color: #4E4CFF;
                    }

                }
                .tr2:hover{
                    background-color: #F0F2FC;
                    .col1{
                        background-color: #F0F2FC;
                    }
                }
                .tr3 {
                    height: 48px;

                    .col1 {
                        display: inline-block;
                        padding-left: 32px;
                        width: 240px;
                        background: #F6F8FF;
                        height: 100%;
                        line-height: 48px;
                        font-size: 16px;
                        color: #17171B;
                        box-sizing: border-box;
                    }

                    .col-head {
                        position: relative;
                        font-weight: 500;
                        font-size: 20px;

                    }
                    .col-head::before{
                        position: absolute;
                        left: 0;
                        top: 13px;
                        content: "";
                        width: 3px;
                        height: 20px;
                        background: #4E4CFF;
                    }
                }


            }
        }

    }

}
</style>