<template>
  <div class="controled">
    <div class="guide1" v-if="currentid == 10201">
      <div class="guide-title">1. 访问</div>
      <div class="guide-text">
        Web端访问地址：<a href="https://yc.iepose.com" target="_blank"
          >https://yc.iepose.com</a
        >
      </div>
      <div class="guide-text">ps：yc=远程，域名很好记哦！~</div>
      <div class="guide-title">2. 登录</div>
      <div class="guide-text">使用微信扫码或手机验证码注册登录</div>
      <div class="twm-icon">
        <img :src="icon24" alt="" />
      </div>
    </div>
    <div class="guide1" v-if="currentid == 10202">
      <div class="guide-title">1. 访问</div>
      <div class="guide-text">前往节点小宝官网下载Windows客户端</div>
      <div class="guide-text">
        下载地址：<a
          href="https://www.iepose.com/download?client=windows"
          target="_blank"
          >https://www.iepose.com/download?client=windows</a
        >
      </div>
      <div class="twm-icon">
        <img :src="icon25" alt="" />
      </div>
      <div class="guide-title">2. 启动</div>
      <div class="guide-text">1. 解压“节点小宝客户端”</div>
      <div class="guide-text">2. 双击运行“节点小宝客户端”</div>
      <div class="line"></div>
      <div class="guide-text">若启动失败：</div>
      <div class="guide-text">
        若出现“缺少运行库”等错误提示导致无法启动时，请下载并安装如下“运行库”，然后再次尝试启动。
      </div>
      <div class="guide-text">
        运行库下载：<a
          href="https://cdn.ionewu.com/upgrade/d/windows_devlibs.zip"
          target="_blank"
          >https://cdn.ionewu.com/upgrade/d/windows_devlibs.zip</a
        >
      </div>
      <div class="guide-title">3. 登录</div>
      <div class="guide-text">使用微信扫码或手机验证码注册登录</div>
      <div class="twm-icon">
        <img :src="icon26" alt="" />
      </div>
    </div>
    <div class="guide1" v-if="currentid == 10203">
      <div class="guide-title">1. 下载</div>
      <div class="guide-text">前往节点小宝官网下载Mac客户端</div>
      <div class="guide-text">
        官网地址：<a
          href="https://www.iepose.com/download?cilent=mac"
          target="_blank"
          >https://www.iepose.com/download?cilent=mac</a
        >
      </div>
      <div class="twm-icon">
        <img :src="icon27" alt="" />
      </div>
      <div class="guide-title">2. 启动</div>
      <div class="guide-text">a. 启动“节点小宝客户端.dmg”程序</div>
      <div class="guide-text">b. 拖拽“节点小宝客户端”到“应用程序”文件夹</div>
      <div class="twm-icon">
        <img :src="icon28" alt="" />
      </div>
      <div class="guide-text">c. 启动节点小宝客户端，点击“打开”</div>
      <div class="line"></div>
      <div class="guide-text">
        若无法打开，出现错误提示时，请参照下方指引进行设置：
      </div>
      <div class="guide-text"><b>macOS_12及以下：</b></div>
      <div class="twm-icon">
        <img :src="icon29" alt="" />
      </div>
      <div class="guide-text">
        a. 点击弹窗“好”以后，打开“系统偏好设置”->安全性与隐私
      </div>
      <div class="twm-icon">
        <img :src="icon30" alt="" />
      </div>
      <div class="guide-text">
        b.
        根据自身需求选择对应的打开方案（选择“任何来源”方案后需要从程序坞启动“节点小宝”）
      </div>
      <div class="twm-icon">
        <img :src="icon31" alt="" />
      </div>
      <div class="line"></div>
      <div class="guide-text"><b> macOS_13：</b></div>
      <div class="twm-icon">
        <img :src="icon32" alt="" />
      </div>
      <div class="guide-text">a. 打开系统设置->隐私与安全性->下拉到底部</div>
      <div class="guide-text">
        根据自身需求选择对应的打开方案（选择“任何来源”方案后需要从程序坞启动“节点小宝”）
      </div>
      <div class="twm-icon">
        <img :src="icon33" alt="" />
      </div>
      <div class="guide-title">3. 登录</div>
      <div class="guide-text">使用微信扫码或手机验证码注册登录</div>
      <div class="twm-icon">
        <img :src="icon34" alt="" />
      </div>
    </div>
    <div class="guide1" v-if="currentid == 10204">
      <div class="guide-title">1. 下载</div>
      <div class="guide-text">下载方式1：</div>
      <div class="guide-text">前往应用市场搜索“节点小宝”下载App</div>
      <div class="guide-text">
        已上架市场：1、已上架市场：华为、小米、OPPO、vivo、应用宝、百度手机助手、360手机助手、豌豆荚
      </div>

      <div class="guide-text mt">下载方式2：</div>
      <div class="guide-text">节点小宝官网下载：</div>
      <div class="guide-text">
        官网下载.apk：<a
          href="https://www.iepose.com/download?client=Android"
          target="_blank"
          >https://www.iepose.com/download?client=Android</a
        >
      </div>
      <div class="guide-text">扫码下载.apk：</div>
      <div class="twm-icon twm-icon2">
        <img :src="icon35" alt="" />
      </div>
      <div class="guide-title">2. 登录</div>
      <div class="guide-text">使用微信或手机验证码注册登录</div>
    </div>
    <div class="guide1" v-if="currentid == 10205">
      <div class="guide-title">1. 下载</div>
      <div class="guide-text">前往App Store搜索“节点小宝”</div>
      <div class="guide-text">使用微信扫一扫，或系统相机对准二维码</div>
      <div class="twm-icon twm-icon2">
        <img :src="icon36" alt="" />
      </div>

      <div class="guide-title">2. 登录</div>
      <div class="guide-text">使用微信或手机验证码注册登录</div>
    </div>
    <div class="guide1" v-if="currentid == 10206">
      <div class="guide-text">使用微信扫码访问微信小程序</div>
      <div class="twm-icon twm-icon2">
        <img :src="icon37" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "InstallControled",
  props: ["currentid"],
  data: function () {
    return {
      icon24: require("../../assets/help/icon24.png"),
      icon25: require("../../assets/help/icon25.png"),
      icon26: require("../../assets/help/icon26.png"),
      icon27: require("../../assets/help/icon27.png"),
      icon28: require("../../assets/help/icon28.png"),
      icon29: require("../../assets/help/icon29.png"),
      icon30: require("../../assets/help/icon30.png"),
      icon31: require("../../assets/help/icon31.png"),
      icon32: require("../../assets/help/icon32.png"),
      icon33: require("../../assets/help/icon33.png"),
      icon34: require("../../assets/help/icon34.png"),
      icon35: require("../../assets/help/icon35.png"),
      icon36: require("../../assets/help/icon36.png"),
      icon37: require("../../assets/help/icon37.png"),
    };
  },
  methods: {
    goClick: function (data) {
      let routeUrl = this.$router.resolve({
        path: data, // 这里的路径就可以正常的写，不需要添加_blank: true
      });
      window.open(routeUrl.href, "_blank");
    },
  },
};
</script>

<style scoped lang="less">
.controled {
  .guide1 {
    .guide-title {
      margin-bottom: 30px;
      margin-top: 30px;
      font-weight: 500;
      color: #222429;
      font-size: 24px;
    }
    .content {
      font-size: 16px;
      div {
        line-height: 30px;
      }
    }
    .line {
      margin: 20px 0;
      width: 100%;
      height: 1px;
      background-color: #ccced4;
    }
    .guide-text {
      font-size: 16px;
      color: #24292f;
      line-height: 28px;
      span {
        color: #3370ff;
        cursor: pointer;
      }
      a {
        text-decoration: none;
        color: #3370ff;
      }
    }
    .twm-icon {
      margin-top: 15px;
      margin-bottom: 15px;
      img {
        max-width: 100%;
      }
    }
    .twm-icon2 {
      width: 50%;
    }
    .mt {
      margin-top: 40px;
    }
  }
}
</style>