<template>
    <div class="twm-device-query">
        <div class="guide1">
            <div class="guide-title">群晖x86/arm设备查询</div>
            <div class="guide-title">arm机型:</div>
            <div class="content">
                <div>DS420j</div>
                <div>DS418</div>
                <div>DS218play</div>
                <div>DS119j</div>
                <div>DS220j</div>
                <div>DS418j</div>
                <div>DS118</div>
                <div>RS819</div>
                <div>DS218</div>
                <div>DS120j</div>
            </div>
            <div class="line"></div>
            <div class="guide-title">x86机型:</div>
            <div class="content">
                <div>DS712+</div>
                <div>RS2211+/RS2211RP+</div>
                <div>DS2411+</div>
                <div>DS1511+</div>
                <div>DS411+II</div>
                <div>DS411+</div>
                <div>RS810+/RS810RP+</div>
                <div>DS1010+</div>
                <div>DS710+</div>
                <div>RS3617xs</div>
                <div>RS18016xs+</div>
                <div>RC18015xs+</div>
                <div>DS3615xs</div>
                <div>RS3614xs+</div>
                <div>RS3614xs/RS3614RPxs</div>
                <div>RS10613xs+</div>
                <div>RS3413xs+</div>
                <div>RS3412xs/RS3412RPxs</div>
                <div>DS3612xs</div>
                <div>RS3411xs/RS3411RPxs</div>

                <div>DS3611xs</div>
                <div>RS2414+/RS2414RP+</div>
                <div>RS814+/RS814RP+</div>
                <div>DS2413+</div>
                <div>DS1813+</div>
                <div>DS1513+</div>
                <div>DS713+</div>
                <div>RS2212+/RS2212RP+</div>
                <div>RS812+/RS812RP+</div>
                <div>DS1812+</div>
                <div>DS1512+</div>
                <div>DS412+</div>
                <div>RS1219+</div>
                <div>RS818+/RS818RP+</div>
                <div>DS1817+</div>
                <div>DS1517+</div>
                <div>RS2416+/RS2416RP+</div>
                <div>RS815+/RS815RP+</div>
                <div>DS2415+</div>
                <div>DS1815+</div>
                <div>DS1515+</div>
                <div>DS415+</div>
                <div>DS916+</div>
                <div>DS716+II</div>
                <div>DS716+</div>
                <div>DS416play</div>
                <div>DS216+II</div>
                <div>DS216+</div>
                <div>FS3400</div>
                <div>FS2017</div>
                <div>RS3618xs</div>
                <div>RS18017xs+</div>
                <div>RS4017xs+</div>
                <div>RS3617xs+</div>
                <div>RS3617RPxs</div>
                <div>DS3617xs</div>
                <div>FS3017</div>
                <div>RS820+/RS820RP+</div>
                <div>DS2419+</div>
                <div>DS1819+</div>
                <div>DVA3219</div>
                <div>RS2818RP+</div>
                <div>RS2418+/RS2418RP+</div>
                <div>DS1618+</div>
                <div>DS620slim</div>
                <div>DS1019+</div>
                <div>DS918+</div>
                <div>DS718+</div>
                <div>DS418play</div>
                <div>DS218+</div>
                <div>FS3600</div>
                <div>FS1018</div>
                <div>SA3600</div>
                <div>SA3400</div>
                <div>RS1619xs+</div>
                <div>DS3018xs</div>
                <div>DS220+</div>
                <div>DS920+</div>

            </div>
        </div>
        <Foot></Foot>
    </div>
</template>

<script>
    import Foot from "@/components/Foot.vue";

    export default {
        name: "QhDeviceQuery",
        components:{
            Foot
        }
    }
</script>

<style scoped lang="less">
.twm-device-query{

    .guide1{
        margin: 0 auto;
        padding: 20PX 0;
        padding: 20PX 0;
        width: 50%;
        min-height: 800PX;
        .guide-title{
            font-weight: 500;
            color: #222429;
            font-size: 24PX;
            margin-bottom: 30PX;
        }
        .content{
            font-size: 16PX;
            div{
                line-height: 30PX;
            }
        }
        .line{
            margin: 20PX 0;
            width: 100%;
            height: 1PX;
            background-color: #ccced4;
        }

    }
}
</style>