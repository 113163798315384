<template>
  <div class="controled">
    <div class="guide1">
      <div class="guide-title">1. 添加NAS桌面服务</div>
      <div class="guide-text">
        节点小宝绑定设备端设备后，会自动添加设备本机桌面服务到基础服务列表
      </div>
      <div class="twm-icon">
        <img :src="icon111" alt="" />
      </div>
      <div class="guide-title">2. 访问NAS桌面服务</div>
      <div class="guide-text">
        点击“NAS管理”按钮，待设备建立连接完成后将自动拉起网页或打开新标签页，并自动打开NAS登录界面。
      </div>
      <div class="twm-icon">
        <img :src="icon112" alt="" />
      </div>
      <div class="twm-icon">
        <img :src="icon113" alt="" />
      </div>
      <div class="guide-title">3. 没有NAS桌面服务？</div>
      <div class="guide-text">1. 请确认NAS在局域网内可通过IP:prot成功访问</div>
      <div class="guide-text">2. 请确保NAS配置了http/https访问端口</div>
      <div class="guide-text">
        若上述配置仍无法自动发现NAS桌面服务，请使用节点小宝高级功能手动添加NAS桌面服务
      </div>
      <div class="guide-text">
        <a class="go-text" @click="vipGuideClick">点击查看如何使用高级功能</a>
      </div>
    </div>
    <div class="guide1" v-if="currentid == 20101">
      <div class="warm-wrap">
        <div class="guide-text ml">节点小宝2.0远程下载功能已支持：</div>
        <div class="warms">
          <div class="guide-text">qBittorrent</div>
          <div class="guide-text">Transmission</div>
          <div class="guide-text">Aria2（即将支持）</div>
        </div>
      </div>
      <div class="guide-text mt">1. 添加远程下载服务</div>
      <div class="guide-text">
        若已在NAS开启相关服务，且端口为默认端口，绑定NAS后即自动创建相关的远程服务
      </div>
      <div class="guide-text">相关下载服务默认端口号参考：</div>
      <div class="guide-text ml">qBittorrent：8085</div>
      <div class="guide-text ml">Transmission：9091</div>
      <div class="guide-text ml">Aria2：6800</div>
      <div class="guide-text mt">
        2.
        若已为下载服务自定义端口号，请前往节点小宝“高级功能”中手动添加对应的ip:port
      </div>
      <div class="guide-text mt">3. 远程访问下载服务（以qb为例）</div>
      <div class="guide-text">在设备端点击对应服务的访问按钮即可发起访问</div>
      <div class="twm-icon">
        <img :src="icon46" alt="" />
      </div>
      <div class="guide-text">4. 等待连接</div>
      <div class="twm-icon">
        <img :src="icon47" alt="" />
      </div>
      <div class="guide-text">5. 输入登录密码</div>
      <div class="twm-icon">
        <img :src="icon48" alt="" />
      </div>
      <div class="guide-text">6. 进入qb下载页</div>
      <div class="twm-icon">
        <img :src="icon49" alt="" />
      </div>
    </div>
    <div class="guide1" v-if="currentid == 20102">
      <div class="guide-title">群晖</div>
      <div class="guide-text">1. 获取服务</div>
      <div class="guide-text">
        前往Synology套件中心安装<span class="green">Video Station</span>
      </div>
      <div class="twm-icon">
        <img :src="icon50" alt="" />
      </div>
      <div class="guide-text mt">2. 开启服务</div>
      <div class="guide-text">
        前往 控制面板->登录门户->应用程序->开启http服务为默认端口即可
      </div>
      <div class="twm-icon">
        <img :src="icon51" alt="" />
      </div>
      <div class="twm-icon">
        <img :src="icon52" alt="" />
      </div>
      <div class="twm-icon">
        <img :src="icon53" alt="" />
      </div>
      <div class="guide-text mt">3. 访问服务</div>
      <div class="guide-text">
        通过节点小宝客户端(以Audio Station为例），点击任意服务即可一键直达
      </div>
      <div class="twm-icon">
        <img :src="icon54" alt="" />
      </div>
      <div class="twm-icon">
        <img :src="icon55" alt="" />
      </div>
    </div>
    <div class="guide1" v-if="currentid == 20103">
      <div class="guide-title">群晖</div>
      <div class="guide-text">1. 获取服务</div>
      <div class="guide-text">
        前往Synology套件中心安装<span class="green">File Station</span>
      </div>
      <div class="twm-icon">
        <img :src="icon56" alt="" />
      </div>
      <div class="guide-text">2. 开启服务</div>
      <div class="guide-text">
        前往 控制面板->登录门户->应用程序->开启http服务为默认端口即可
      </div>
      <div class="twm-icon">
        <img :src="icon57" alt="" />
      </div>
      <div class="guide-text">3. 访问服务</div>
      <div class="guide-text">
        通过节点小宝客户端，点击“远程照片”即可一键直达
      </div>
      <div class="twm-icon">
        <img :src="icon58" alt="" />
      </div>
      <div class="twm-icon">
        <img :src="icon59" alt="" />
      </div>
    </div>
    <div class="guide1" v-if="currentid == 20104">
      <div class="guide-title">群晖</div>
      <div class="guide-text">1. 获取服务</div>
      <div class="guide-text">
        前往Synology套件中心安装<span class="green">Synology Photos</span>
      </div>
      <div class="twm-icon">
        <img :src="icon60" alt="" />
      </div>
      <div class="guide-text">2. 开启服务</div>
      <div class="guide-text">
        前往 控制面板->登录门户->应用程序->开启http服务为默认端口即可
      </div>
      <div class="twm-icon">
        <img :src="icon61" alt="" />
      </div>
      <div class="twm-icon">
        <img :src="icon62" alt="" />
      </div>
      <div class="guide-text">3. 访问服务</div>
      <div class="guide-text">
        通过节点小宝客户端），点击“远程照片”即可一键直达
      </div>
      <div class="twm-icon">
        <img :src="icon63" alt="" />
      </div>
      <div class="twm-icon">
        <img :src="icon64" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "InstallControled",
  props: ["currentid"],
  data: function () {
    return {
      icon46: require("../../assets/help/icon46.png"),
      icon47: require("../../assets/help/icon47.png"),
      icon48: require("../../assets/help/icon48.png"),
      icon49: require("../../assets/help/icon49.png"),
      icon50: require("../../assets/help/icon50.png"),
      icon51: require("../../assets/help/icon51.png"),
      icon52: require("../../assets/help/icon52.png"),
      icon53: require("../../assets/help/icon53.png"),
      icon54: require("../../assets/help/icon54.png"),
      icon55: require("../../assets/help/icon55.png"),
      icon56: require("../../assets/help/icon56.png"),
      icon57: require("../../assets/help/icon57.png"),
      icon58: require("../../assets/help/icon58.png"),
      icon59: require("../../assets/help/icon59.png"),
      icon60: require("../../assets/help/icon60.png"),
      icon61: require("../../assets/help/icon61.png"),
      icon62: require("../../assets/help/icon62.png"),
      icon63: require("../../assets/help/icon63.png"),
      icon64: require("../../assets/help/icon64.png"),

      icon111: require("../../assets/help/icon111.png"),
      icon112: require("../../assets/help/icon112.png"),
      icon113: require("../../assets/help/icon113.png"),
    };
  },
  methods: {
    vipGuideClick: function () {
      this.$emit("vipGuideClick");
    },
    goClick: function (data) {
      let routeUrl = this.$router.resolve({
        path: data, // 这里的路径就可以正常的写，不需要添加_blank: true
      });
      window.open(routeUrl.href, "_blank");
    },
  },
};
</script>

<style scoped lang="less">
.controled {
  .guide1 {
    .guide-title {
      margin-bottom: 30px;
      margin-top: 30px;
      font-weight: 500;
      color: #222429;
      font-size: 24px;
    }
    .content {
      font-size: 16px;
      div {
        line-height: 30px;
      }
    }
    .line {
      margin: 20px 0;
      width: 100%;
      height: 1px;
      background-color: #ccced4;
    }
    .guide-text {
      font-size: 16px;
      color: #24292f;
      line-height: 28px;
      span {
        color: #3370ff;
        cursor: pointer;
      }
      a {
        text-decoration: none;
        color: #3370ff;
      }
      .green {
        color: #2ea121;
        font-weight: 900;
      }
      .go-text {
        text-decoration: underline;
        color: #24292f;
        cursor: pointer;
      }
    }
    .twm-icon {
      margin-top: 15px;
      margin-bottom: 15px;
      img {
        max-width: 100%;
      }
    }
    .twm-icon2 {
      width: 50%;
    }
    .twm-icon3 {
      margin: 0 auto;
    }
    .mt {
      margin-top: 40px;
    }
    .ml {
      margin-left: 20px;
    }
    .warm-wrap {
      padding: 16px;
      padding-left: 3px;
      width: 100%;
      border: 1px solid #bacefd;
      background-color: #f0f4ff;
      box-sizing: border-box;
      border-radius: 8px;
      .warms {
        padding-left: 40px;
      }
    }
  }
}
</style>