<template>
  <div class="support1">
    <div class="content">
      <div class="head">远程NAS管理</div>
      <div class="texts m1">远程访问NAS目前主要通过公网IP+端口或第三方工具实现NAS的远程访问，对于前者需要一定的技术知识和动手能力但存在一定的风险，后者需要购买硬件或购买配套软件。</div>
      <div class="texts m1">节点小宝将各方式的优点进行提炼并应用到小宝望远镜产品中，无操作门槛和使用门槛，无收费限制。</div>
      <div class="texts m2">仅需3步即可通过节点小宝远程管理Nas</div>
      <div class="texts m2">1、前往NAS套件中心搜素“节点小宝”并安装</div>
      <img :src="support_c1" alt="#" class="icon">
      <div class="texts m3">2、在NAS中打开节点小宝，进入节点小宝登录页</div>
      <img :src="support_c2" alt="#" class="icon">
      <div class="texts m3">3、使用微信扫码登录</div>
      <img :src="support_c3" alt="#" class="icon">
      <div class="texts m3">4、登录后会提示绑定设备，点击确定即可绑定当前NAS</div>
      <img :src="support_c4" alt="#" class="icon">
      <div class="texts m3">5、绑定后节点小宝会自动搜索并添加该设备所在局域网内的开放服务</div>
      <img :src="support_c5" alt="#" class="icon">
      <div class="texts m3">6、找到对应的NAS设备，点击“NAS管理”即可一键访问NAS设备</div>
      <img :src="support_c6" alt="#" class="icon">
      <div class="texts m3">7、等待设备连接</div>
      <img :src="support_c7" alt="#" class="icon">
      <div class="texts m3">8、连接成功后自动打开浏览器并跳转到NAS管理登录页</div>
      <img :src="support_c8" alt="#" class="icon">

    </div>
    <Foot></Foot>

  </div>
</template>

<script>
  import Foot from "@/components/Foot.vue";


  export default {
    name: 'Support1',
    components:{
      Foot
    },
    data:function () {
      return {
        support_c1:require("../assets/support_c1.png"),
        support_c2:require("../assets/support_c2.png"),
        support_c3:require("../assets/support_c3.png"),
        support_c4:require("../assets/support_c4.png"),
        support_c5:require("../assets/support_c5.png"),
        support_c6:require("../assets/support_c6.png"),
        support_c7:require("../assets/support_c7.png"),
        support_c8:require("../assets/support_c8.png"),

      }
    },


  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
  .bg{
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .support1{
    height: 100%;
    overflow-y: scroll;
    background-color: #FFFFFF;
    color: #666666;
    font-size: 16px;

    .content{
      position: relative;
      padding-top: 36px;
      padding-bottom: 100px;
      margin: 0 auto;
      min-width: 210px;
      font-size: 18px;
      max-width: 790px;
      min-width: 470px;

      .head{
        margin-top: 30px;
        font-size: 30px;
        color: #1A1A1A;
        font-weight: 500;
      }

      .texts{
        line-height: 26px;
      }

      .m1{
        margin-top: 10px;
      }
      .m2{
        margin-top: 40px;
      }
      .m3{
        margin-top: 20px;
      }
      .icon{
        margin-top: 20px;
        width: 100%;
      }

    }


  }
  .support1::-webkit-scrollbar {
    display: none;
  }
</style>
