var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"help"},[(false)?_c('div',{staticClass:"title"},[_vm._v("快速找到问题解决办法")]):_vm._e(),(false)?_c('div',{staticClass:"keyword-search"},[_c('el-autocomplete',{attrs:{"fetch-suggestions":_vm.querySearchAsync,"placeholder":"请输入内容"},on:{"select":_vm.handleSelect},model:{value:(_vm.state),callback:function ($$v) {_vm.state=$$v},expression:"state"}})],1):_vm._e(),_c('div',{staticClass:"content-wrap"},[_c('div',{staticClass:"left"},[_c('div',{staticClass:"l-title"},[_vm._v("帮助中心")]),_c('div',{staticClass:"l-list"},[_c('CascaderItem',{attrs:{"tree":_vm.list,"treeIndex":1},on:{"nodeClick":_vm.nClick}})],1)]),_c('div',{staticClass:"right"},[_c('div',{staticClass:"position-wrap"},[_c('div',{staticClass:"position-text1"},[_vm._v(" "+_vm._s(_vm.positionText.name ? _vm.positionText.name : "")+" ")]),(_vm.positionText && _vm.positionText.children)?_c('div',{staticClass:"position-text2-wrap"},_vm._l((_vm.positionText.children),function(item,index){return _c('div',{key:index,staticClass:"position-text2",on:{"click":function($event){return _vm.nClick(item)}}},[_vm._v(" "+_vm._s(item.name)+" ")])}),0):_vm._e()]),(
          _vm.positionText &&
          _vm.positionText.parentid &&
          _vm.positionText.parentid == 101
        )?_c('InstallControled',{attrs:{"currentid":_vm.positionText.id}}):_vm._e(),(
          _vm.positionText &&
          _vm.positionText.parentid &&
          _vm.positionText.parentid == 102
        )?_c('InstallControl',{attrs:{"currentid":_vm.positionText.id}}):_vm._e(),(_vm.positionText && _vm.positionText.id && _vm.positionText.id == 103)?_c('BindControled'):_vm._e(),(_vm.positionText && _vm.positionText.id && _vm.positionText.id == 201)?_c('NasDesk',{attrs:{"currentid":_vm.positionText.id},on:{"vipGuideClick":_vm.vipGuideClick}}):_vm._e(),(_vm.positionText && _vm.positionText.id && _vm.positionText.id == 205)?_c('RemoteDownload',{attrs:{"currentid":_vm.positionText.id}}):_vm._e(),(_vm.positionText && _vm.positionText.id && _vm.positionText.id == 206)?_c('RemoteVideo',{attrs:{"currentid":_vm.positionText.id}}):_vm._e(),(_vm.positionText && _vm.positionText.id && _vm.positionText.id == 207)?_c('RemoteFile',{attrs:{"currentid":_vm.positionText.id}}):_vm._e(),(_vm.positionText && _vm.positionText.id && _vm.positionText.id == 208)?_c('RemotePhoto',{attrs:{"currentid":_vm.positionText.id}}):_vm._e(),(_vm.positionText && _vm.positionText.id && _vm.positionText.id == 209)?_c('JellyService',{attrs:{"currentid":_vm.positionText.id}}):_vm._e(),(_vm.positionText && _vm.positionText.id && _vm.positionText.id == 210)?_c('EmbyService',{attrs:{"currentid":_vm.positionText.id}}):_vm._e(),(_vm.positionText && _vm.positionText.id && _vm.positionText.id == 202)?_c('RemoteRouter',{on:{"vipGuideClick":_vm.vipGuideClick}}):_vm._e(),(
          _vm.positionText &&
          _vm.positionText.parentid &&
          _vm.positionText.parentid == 203
        )?_c('RemoteDesk',{attrs:{"currentid":_vm.positionText.id}}):_vm._e(),(_vm.positionText && _vm.positionText.id && _vm.positionText.id == 204)?_c('RemoteOpen'):_vm._e(),(_vm.positionText && _vm.positionText.id && _vm.positionText.id == 3)?_c('VipService'):_vm._e(),(
          _vm.positionText && _vm.positionText.parentid && _vm.positionText.parentid == 4
        )?_c('Tools',{attrs:{"currentid":_vm.positionText.id}}):_vm._e(),(
          _vm.positionText && _vm.positionText.parentid && _vm.positionText.parentid == 5
        )?_c('Questions',{attrs:{"currentid":_vm.positionText.id},on:{"remoteOpenClick":_vm.remoteOpenClick}}):_vm._e()],1)]),_c('Foot')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }