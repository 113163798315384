<template>
  <div class="controled">
    <div class="guide1">
      <div class="guide-text">客户端完成注册登录后开始绑定设备端设备</div>
      <div class="guide-title">绑定码绑定</div>
      <div class="guide-text">移动端输入绑定码路径：</div>
      <div class="guide-text">初次使用：登录即展示输入绑定码窗口</div>
      <div class="guide-text">首页->受控设备->添加设备->输入绑定码</div>
      <div class="twm-icon">
        <img :src="icon38" alt="" />
      </div>
      <div class="guide-text mt">PC端输入绑定码路径：</div>
      <div class="guide-text">初次使用：登录即展示输入绑定码窗口</div>
      <div class="guide-text">方法1：菜单栏->添加设备->输入绑定码</div>
      <div class="guide-text">方法2：设备管理->添加设备->输入绑定码</div>
      <div class="twm-icon">
        <img :src="icon39" alt="" />
      </div>
      <div class="twm-icon">
        <img :src="icon40" alt="" />
      </div>
      <div class="guide-title">一键绑定</div>
      <div class="guide-text">该绑定方式仅支持PC端</div>
      <div class="guide-text">1. 在设备端打开节点小宝，进入设备端界面</div>
      <div class="twm-icon">
        <img :src="icon41" alt="" />
      </div>
      <div class="guide-text">
        2. 点击绑定码下方的：一键绑定按钮，跳转至节点小宝Web站
      </div>
      <div class="twm-icon">
        <img :src="icon42" alt="" />
      </div>
      <div class="guide-text">
        3. 完成登录后会弹出发现新设备窗口，点击立即绑定即可完成设备端绑定
      </div>
      <div class="twm-icon">
        <img :src="icon43" alt="" />
      </div>
      <div class="twm-icon">
        <img :src="icon44" alt="" />
      </div>
      <div class="guide-title">绑定异常相关问题</div>
      <div class="wram-wrap">
        <div class="guide-text"><b>设备显示离线</b></div>
        <div class="guide-text">设备实际在线，但显示离线</div>
      </div>
      <div class="guide-text">1. 将NAS上节点小宝设备端更新到最新版本；</div>
      <div class="guide-text">2. 重启节点小宝客户端或刷新客户端页面。</div>
      <div class="line"></div>
      <div class="wram-wrap">
        <div class="guide-text"><b>远程服务暂不支持</b></div>
        <div class="guide-text">远程服务列表提示“当前设备暂不支持远程服务”</div>
      </div>
      <div class="guide-text">
        1.
        当前设备的节点小宝主机端版本较低，请到应用市场或节点小宝官网下载并更新节点小宝主机端；
      </div>
      <div class="guide-text">
        2. 若无支持的新版本，请等待设备厂商的应用市场更新。
      </div>
      <div class="line"></div>
      <div class="guide-text">
        <b>如遇其他问题，请扫码加入社群，获取帮助</b>
      </div>
      <div class="twm-icon twm-icon2 twm-icon3">
        <img :src="icon45" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "InstallControled",
  props: ["currentid"],
  data: function () {
    return {
      icon38: require("../../assets/help/icon38.png"),
      icon39: require("../../assets/help/icon39.png"),
      icon40: require("../../assets/help/icon40.png"),
      icon41: require("../../assets/help/icon41.png"),
      icon42: require("../../assets/help/icon42.png"),
      icon43: require("../../assets/help/icon43.png"),
      icon44: require("../../assets/help/icon44.png"),
      icon45: require("../../assets/help/icon45.png"),
    };
  },
  methods: {
    goClick: function (data) {
      let routeUrl = this.$router.resolve({
        path: data, // 这里的路径就可以正常的写，不需要添加_blank: true
      });
      window.open(routeUrl.href, "_blank");
    },
  },
};
</script>

<style scoped lang="less">
.controled {
  .guide1 {
    .guide-title {
      margin-bottom: 30px;
      margin-top: 30px;
      font-weight: 500;
      color: #222429;
      font-size: 24px;
    }
    .content {
      font-size: 16px;
      div {
        line-height: 30px;
      }
    }
    .line {
      margin: 20px 0;
      width: 100%;
      height: 1px;
      background-color: #ccced4;
    }
    .guide-text {
      font-size: 16px;
      color: #24292f;
      line-height: 28px;
      span {
        color: #3370ff;
        cursor: pointer;
      }
      a {
        text-decoration: none;
        color: #3370ff;
      }
    }
    .twm-icon {
      margin-top: 15px;
      margin-bottom: 15px;
      img {
        max-width: 100%;
      }
    }
    .twm-icon2 {
      width: 50%;
    }
    .twm-icon3 {
      margin: 0 auto;
    }
    .mt {
      margin-top: 40px;
    }
    .wram-wrap {
      padding: 16px;
      padding-left: 32px;
      width: 100%;
      border: 1px solid #bacefd;
      background-color: #fef1f1;
      box-sizing: border-box;
      border-radius: 8px;
    }
  }
}
</style>