<template>
  <div class="support1">
    <div class="content">
      <div class="head">远程管理路由器</div>
      <div class="texts m1">节点小宝支持外网一键管理路由器，仅需4步：安装、登录、绑定、管理</div>
      <div class="texts m2">1、前往NAS套件中心搜素“节点小宝”并安装</div>
      <img :src="support_d1" alt="#" class="icon">
      <div class="texts m3">2、在NAS或路由管理后台中打开节点小宝，进入节点小宝登录页</div>
      <img :src="support_d2" alt="#" class="icon">
      <div class="texts m3">3、使用微信扫码登录</div>
      <img :src="support_d3" alt="#" class="icon">
      <div class="texts m3">4、登录后会提示绑定设备，点击确定即可绑定当前设备</div>
      <img :src="support_d4" alt="#" class="icon">
      <div class="texts m3">5、点击远程服务列表中的路由器管理即可一键远程访问路由器管理后台</div>
      <img :src="support_d5" alt="#" class="icon">
      <div class="texts m3">6、等待建立连接</div>
      <img :src="support_d6" alt="#" class="icon">
      <div class="texts m3">7、连接成功后会自动打开浏览器并跳转到路由器管理登录页</div>
      <img :src="support_d7" alt="#" class="icon">

    </div>
    <Foot></Foot>

  </div>
</template>

<script>
  import Foot from "@/components/Foot.vue";

  export default {
    name: 'Support1',
    components:{
      Foot
    },
    data:function () {
      return {
        support_d1:require("../assets/support_d1.png"),
        support_d2:require("../assets/support_d2.png"),
        support_d3:require("../assets/support_d3.png"),
        support_d4:require("../assets/support_d4.png"),
        support_d5:require("../assets/support_d5.png"),
        support_d6:require("../assets/support_d6.png"),
        support_d7:require("../assets/support_d7.png"),
      }
    },


  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
  .bg{
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .support1{
    height: 100%;
    overflow-y: scroll;
    background-color: #FFFFFF;
    color: #666666;
    font-size: 16px;

    .content{
      position: relative;
      padding-top: 36px;
      padding-bottom: 100px;
      margin: 0 auto;
      min-width: 210px;
      font-size: 18px;
      max-width: 790px;
      min-width: 470px;

      .head{
        margin-top: 30px;
        font-size: 30px;
        color: #1A1A1A;
        font-weight: 500;
      }

      .texts{
        line-height: 26px;
      }

      .m1{
        margin-top: 10px;
      }
      .m2{
        margin-top: 40px;
      }
      .m3{
        margin-top: 20px;
      }
      .icon{
        margin-top: 20px;
        width: 100%;
      }

    }


  }
  .support1::-webkit-scrollbar {
    display: none;
  }
</style>
