<template>
  <div class="support1">
    <div class="content">
      <div class="head">远程windows桌面</div>
      <div class="texts m1">受疫情影响，越来越多的企业选择远程办公，在远程OA的基础上仍需使用部分内网应用软件来完成相关工作，又或者需要传输电脑上未备份的文件时只能让同事帮忙，工作成果和隐私无法得到保障。通过节点小宝可以实现远程链接公司电脑。</div>
      <div class="question">
        <div class="question-text">首先需要开启电脑的远程桌面服务</div>
      </div>
      <div class="texts m3">1、右键点击开始菜单→设置</div>
      <img :src="support_e1" alt="#" class="icon">
      <div class="texts m3">2、点击“系统”</div>
      <img :src="support_e2" alt="#" class="icon">
      <div class="texts m3">3、滑动菜单至底部，点击远程桌面，并启用远程桌面</div>
      <img :src="support_e3" alt="#" class="icon">
      <div class="question">
        <div class="question-text">NAS或路由设备安装节点小宝套件或插件，以创建映射</div>
      </div>
      <div class="texts m3">1、通过NAS套件中心或路由插件中心安装节点小宝</div>
      <img :src="support_e4" alt="#" class="icon">
      <div class="texts m3">2、在NAS或路由管理后台中打开节点小宝，进入节点小宝登录页</div>
      <img :src="support_e5" alt="#" class="icon">
      <div class="texts m3">3、使用微信扫码登录</div>
      <img :src="support_e6" alt="#" class="icon">
      <div class="texts m3">4、登录后会提示绑定设备，点击确定即可绑定当前设备。</div>
      <div class="texts m1 c9">此时已创建远程桌面服务的映射，接下来需要使用节点小宝Windows端来访问远程桌面。</div>
      <img :src="support_e7" alt="#" class="icon">
      <div class="question">
        <div class="question-text">安装并打开节点小宝Windows端 <a href="#">（点击下载）</a></div>
      </div>
      <div class="texts m3">1、点击需要访问远程桌面的设备</div>
      <img :src="support_e8" alt="#" class="icon">
      <div class="texts m3">2、初次链接需要验证Windows账户密码</div>
      <img :src="support_e9" alt="#" class="icon">
      <div class="texts m3">3、成功访问远程桌面</div>
      <img :src="support_e10" alt="#" class="icon">

    </div>
    <Foot></Foot>

  </div>
</template>

<script>
  import Foot from "@/components/Foot.vue";

  export default {
    name: 'Support1',
    components:{
      Foot
    },
    data:function () {
      return {
        support_e1:require("../assets/support_e1.png"),
        support_e2:require("../assets/support_e2.png"),
        support_e3:require("../assets/support_e3.png"),
        support_e4:require("../assets/support_e4.png"),
        support_e5:require("../assets/support_e5.png"),
        support_e6:require("../assets/support_e6.png"),
        support_e7:require("../assets/support_e7.png"),
        support_e8:require("../assets/support_e8.png"),
        support_e9:require("../assets/support_e9.png"),
        support_e10:require("../assets/support_e10.png"),
      }
    },


  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
  .bg{
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .support1{
    height: 100%;
    overflow-y: scroll;
    background-color: #FFFFFF;
    color: #666666;
    font-size: 18px;

    .content{
      position: relative;
      padding-top: 36px;
      padding-bottom: 100px;
      margin: 0 auto;
      min-width: 210px;
      font-size: 16px;
      max-width: 790px;
      min-width: 470px;

      .head{
        margin-top: 30px;
        font-size: 30px;
        color: #1A1A1A;
        font-weight: 500;
      }

      .texts{
        line-height: 26px;
      }

      .m1{
        margin-top: 10px;
      }
      .m2{
        margin-top: 40px;
      }
      .m3{
        margin-top: 20px;
      }
      .icon{
        margin-top: 20px;
        width: 100%;
      }

      .question{
        margin-top: 20px;
        padding: 16px 16px 16px 46px;
        background-color: #F5F5F5;
        border-radius: 4px;

        .question-text{
          position: relative;
        }
        .question-text::before{
          position: absolute;
          top: 50%;
          left: -30px;
          transform: translate(0,-50%);
          content: "";
          width: 24px;
          height: 24px;
          .bg();
          background-image: url("../assets/support14.svg");
        }
      }

      .c9{
        color: #999999;
      }

    }


  }
  .support1::-webkit-scrollbar {
    display: none;
  }
</style>
