<template>
  <div class="controled">
    <div class="guide1">
      <div class="guide-title">发现服务</div>
      <div class="guide-text">
        使用“高级功能”的“发现服务”，可发现设备端局域网内其他开放的服务
      </div>
      <div class="twm-icon">
        <img :src="icon99" alt="" />
      </div>
      <div class="twm-icon">
        <img :src="icon100" alt="" />
      </div>
      <div class="guide-title">自定义服务</div>
      <div class="guide-text">
        若未发现需要的服务，可点击“手动添加”，输入目标设备端局域网内的服务IP:port即可添加服务
      </div>
      <div class="twm-icon">
        <img :src="icon101" alt="" />
      </div>
      <div class="guide-text">
        如：添加群晖的Vedio Station服务（默认端口9007）
      </div>
      <div class="twm-icon">
        <img :src="icon102" alt="" />
      </div>
      <div class="guide-text">添加后列表即可见Vedio Station服务，点击访问</div>
      <div class="twm-icon">
        <img :src="icon103" alt="" />
      </div>
      <div class="twm-icon">
        <img :src="icon104" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "InstallControled",
  props: ["currentid"],
  data: function () {
    return {
      icon99: require("../../assets/help/icon99.png"),
      icon100: require("../../assets/help/icon100.png"),
      icon101: require("../../assets/help/icon101.png"),
      icon102: require("../../assets/help/icon102.png"),
      icon103: require("../../assets/help/icon103.png"),
      icon104: require("../../assets/help/icon104.png"),
    };
  },
  methods: {
    vipGuideClick: function () {
      this.$emit("vipGuideClick");
    },
    goClick: function (data) {
      let routeUrl = this.$router.resolve({
        path: data, // 这里的路径就可以正常的写，不需要添加_blank: true
      });
      window.open(routeUrl.href, "_blank");
    },
  },
};
</script>

<style scoped lang="less">
.controled {
  .guide1 {
    .guide-title {
      margin-bottom: 30px;
      margin-top: 30px;
      font-weight: 500;
      color: #222429;
      font-size: 24px;
    }
    .content {
      font-size: 16px;
      div {
        line-height: 30px;
      }
    }
    .line {
      margin: 20px 0;
      width: 100%;
      height: 1px;
      background-color: #ccced4;
    }
    .guide-text {
      font-size: 16px;
      color: #24292f;
      line-height: 28px;
      span {
        color: #3370ff;
        cursor: pointer;
      }
      a {
        text-decoration: none;
        color: #3370ff;
      }
      .go-text {
        text-decoration: underline;
        color: #24292f;
        cursor: pointer;
      }
    }
    .twm-icon {
      margin-top: 15px;
      margin-bottom: 15px;
      img {
        max-width: 100%;
      }
    }
    .twm-icon2 {
      width: 50%;
    }
    .twm-icon3 {
      margin: 0 auto;
    }
    .twm-icon4 {
      text-align: center;
    }

    .mt {
      margin-top: 40px;
    }
    .ml {
      margin-left: 20px;
    }
    .wram-wrap {
      margin-top: 10px;
      margin-bottom: 10px;
      padding: 16px;
      padding-left: 32px;
      width: 100%;
      border: 1px solid #bacefd;
      background-color: #f0f4ff;
      box-sizing: border-box;
      border-radius: 8px;
    }
  }
}
</style>