<template>
    <div class="cascader-item">
        <div :class="{'l-item':true,'l-item-active':item.id == $store.state.currentKey && treeIndex != 1}" v-for="(item,index) in tree" :key="index" @click.stop="itemNodeClick(item)">
            <div :class="{'l-item-title':true}" :style="{'padding-left':treeIndex > 1 ? (treeIndex*8)+'PX' : '0PX'}">
                <div class="item-text">{{item.name}}</div>
                <div :class="{'item-icon':true,'item-icon2': isOpen(item.id)}" v-if="item.children && item.children.length"></div>
            </div>
            <div v-if="item.children" v-show="isOpen(item.id)">
                <CascaderItem :tree="item.children" :treeIndex="treeIndex+1" @nodeClick="$emit('nodeClick',$event)"></CascaderItem>
            </div>
        </div>
    </div>
</template>

<script>
    import CascaderItem from "@/components/CascaderItem.vue";

    export default {
        name: "CascaderItem",
        props:['tree','treeIndex'],
        components:{
            CascaderItem
        },
        data:function(){
           return {
               currentIndex:null,
           }
        },
        methods:{
            itemNodeClick:function (item) {
                let _that = this;
                _that.$emit("nodeClick",item);
                _that.currentIndex = item.id;
                if(item.children && item.children.length){
                    let index = _that.$store.state.expandedKeys.indexOf(item.id);
                    //有，则删除，不显示
                    if(index > -1){
                        _that.$store.state.expandedKeys.splice(index,1);
                    }else{
                    //没有，则添加，显示出来
                        //当只点击一级目录时，只显示该一级目录，其它全部移除。
                        if(item.id.toString().length == 1){
                            _that.$store.state.expandedKeys = [];
                        }
                        _that.$store.state.expandedKeys.push(item.id);
                    }
                }
                if(!item.children){

                    _that.$store.state.currentKey = item.id;
                }
            },
            isOpen:function (id) {
                let _that = this;
                return _that.$store.state.expandedKeys.includes(id);
            }
        },
        mounted() {
        }
    }
</script>

<style scoped lang="less">
.cascader-item{
    .l-item{


        .l-item-title{
            display: flex;
            justify-content: space-between;
            margin-left: 20PX;
            margin-right: 20PX;
            align-items: center;
            cursor: pointer;

            .item-text{
                font-weight: 500;
                color: #222429;
                font-size: 14PX;
                line-height: 48PX;
            }
            .item-icon{
                width: 14PX;
                height: 14PX;
                background-image: url("../assets/help/jt4.svg");
                background-repeat: no-repeat;
                background-size: 100% 100%;
            }
            .item-icon2{
                background-image: url("../assets/help/jt3.svg");

            }
        }


    }
    .l-item-active{
        background-color: #EFF7FF;
        .l-item-title {
            .item-text {
                color: #333333;
            }
        }
    }


}
</style>